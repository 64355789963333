import { apiCall } from '../../utils';

export const checkBusinessReferralCode = referral_code =>
  apiCall({
    url: '/users/validate-business-referral-code/',
    method: 'post',
    data: { referral_code },
  });

export const checkCustomerReferralCode = referral_code =>
  apiCall({
    url: '/users/validate-customer-referral-code/',
    method: 'post',
    data: { referral_code },
  });

export const generateReferralCode = () =>
  apiCall({
    url: '/users/generate-referral-code/',
    method: 'get',
  });
