import { apiCall } from '../../utils';
import { authApiCall } from '../auth/saga';

/**
 * @param {object} data - Format {old_password: @string, new_password: @string}
 */
export const changePassword = data =>
  authApiCall({
    url: '/users/change-password/',
    method: 'patch',
    data,
  });

export const recoverPassword = email =>
  apiCall({
    url: '/users/forgot-password/',
    method: 'post',
    data: { email },
  });

export const resetPassword = (uid, token, new_password) =>
  apiCall({
    url: '/users/reset-password/',
    method: 'post',
    data: { uid, token, new_password },
  });
