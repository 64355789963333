import React from 'react';

import { Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Block, FormCheckbox } from 'components';
import { required } from 'coupon-common/src/utils';

import messages from './messages';

const getCheckRequered = errorText => value => required(value) && errorText;

const PointsProgramField = ({ intl, fullWidth, earnPoints }) => {
  return (
    <Block>
      <div className="row justify-content-center">
        <div className={fullWidth ? 'col-md-12' : 'col-md-10'}>
          <h5>
            <FormattedMessage {...messages.blockHeading} />
          </h5>
          <FormattedMessage
            tagName="p"
            {...messages.pointsProgramText}
            values={{ earnPoints }}
          />
          <div className="mt-5">
            <Field
              name="join_program"
              label={intl.formatMessage(messages.joinPointsProgramLabel)}
              validate={getCheckRequered(
                intl.formatMessage(messages.requireJoinPointsProgramError),
              )}
              type="checkbox"
              component={FormCheckbox}
            />
            <Field
              name="agree_with_dealer_agreement"
              label={
                <FormattedMessage
                  {...messages.agreeWithDealerAgreementLabel}
                  values={{
                    dealerAgreementLink: msg => (
                      <a
                        href="/dealer-agreement"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {msg}
                      </a>
                    ),
                  }}
                />
              }
              validate={getCheckRequered(
                intl.formatMessage(
                  messages.requireAgreeWithDealerAgreementError,
                ),
              )}
              type="checkbox"
              component={FormCheckbox}
            />
          </div>
        </div>
      </div>
    </Block>
  );
};

export default injectIntl(PointsProgramField);
