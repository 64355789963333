import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const PAGE_BOTOM_DETECTION_SHIFT = 50;

export const useNextPageHandler = (
  onBottomScrollAction,
  nextPageExists,
  isFetching,
  additionalParams = {},
) => {
  const dispatch = useDispatch();
  const handleScroll = useCallback(() => {
    const { body, documentElement: html } = document;
    const windowHeight =
      'innerHeight' in window ? window.innerHeight : html.offsetHeight;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom =
      windowHeight + window.pageYOffset + PAGE_BOTOM_DETECTION_SHIFT;
    if (
      windowBottom >= docHeight &&
      nextPageExists &&
      !isFetching &&
      Object.values(additionalParams).every(Boolean)
    ) {
      dispatch(onBottomScrollAction());
    }
  }, [
    additionalParams,
    dispatch,
    isFetching,
    nextPageExists,
    onBottomScrollAction,
  ]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return [handleScroll];
};

export const useParamsConfig = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const noWrapper = searchParams.get('noWrapper');
  return {
    noWrapper,
  };
};
