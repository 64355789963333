import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Button,
  Coupon,
  CouponPriceDetails,
  Feedback,
  Modal,
  Spinner,
} from 'components';
import { USED_COUPON_STATES } from 'coupon-common';
import {
  actions as couponBuilderActions,
  duck as couponBuilderDuck,
} from 'coupon-common/src/modules/couponBuilder';
import { actions as couponsActivityActions } from 'coupon-common/src/modules/usedCoupons/couponsActivity';
import { actions as newUsedCouponsActions } from 'coupon-common/src/modules/usedCoupons/newUsedCoupons';
import { dispatchAsAsync } from 'coupon-common/src/utils';

import messages from './messages';
import styles from './ProcessCoupon.module.scss';
import { Form, FormSpy } from 'react-final-form';
import CouponPriceFiledGroup from 'containers/CouponPriceFiledGroup/CouponPriceFiledGroup';
import { useCallback } from 'react';

export const ProcessCoupon = ({
  intl,
  dispatch,
  usedCouponData,
  isRootModal,
  couponsActivityFetching,
  couponsActivityError,
  couponsActivityIsModalOpen,
  newUsedCouponsIsModalOpen,
  couponBuilderState,
  businessData,
  taxRatesState,
}) => {
  const isOpen = isRootModal
    ? newUsedCouponsIsModalOpen
    : couponsActivityIsModalOpen;

  useEffect(() => {
    if (isOpen) {
      dispatch(couponBuilderActions.getCoupon(usedCouponData.coupon));
    }
  }, [dispatch, isOpen, usedCouponData.coupon]);

  const { data: couponData, fetched: couponDataFetched } =
    couponBuilderState[usedCouponData.coupon] ||
    couponBuilderDuck.INITIAL_COUPON_ITEM_STATE;

  const handleProcessCouponClick = async (isAccepted = false, formData) => {
    const action = isAccepted
      ? couponsActivityActions.acceptCoupon
      : couponsActivityActions.rejectCoupon;

    const { error, errorNotification } = await dispatchAsAsync(
      action(usedCouponData.id, formData),
      dispatch,
    );

    if (!error) {
      handleCloseModalClick();

      const toastText = isAccepted
        ? messages.couponAcceptedNotificationText
        : messages.couponRejectedNotificationText;

      toast(
        <FormattedMessage
          {...toastText}
          values={{
            couponTitle: couponData.title,
            b: chunks => <b>{chunks}</b>,
          }}
        />,
      );
      return;
    }

    if (errorNotification) {
      handleCloseModalClick();
      toast.error(error);
    }
  };

  const handleCloseModalClick = () => {
    // TODO: Fast fix, ProcessCoupon and UsedCoupon components should be refactored
    // because of too deep integration and hard to understand structure.
    dispatch(newUsedCouponsActions.closeModal());
    dispatch(couponsActivityActions.closeModal());
    dispatch(couponBuilderActions.clearCouponState(usedCouponData.coupon));
  };

  const usedCouponInWaitingState =
    usedCouponData.state === USED_COUPON_STATES.WAITING;
  const usedCouponInRejectedState =
    usedCouponData.state === USED_COUPON_STATES.REJECTED;

  let customersRequestText;

  switch (true) {
    case usedCouponInWaitingState:
      customersRequestText = messages.customersWaitingRequestText;
      break;
    case usedCouponInRejectedState:
      customersRequestText = messages.customersRejectedRequestText;
      break;
    default:
      customersRequestText = messages.customersProcessedRequestText;
  }

  const validateForm = values => {
    if (couponData?.show_price_on_coupon) return null;

    if (couponData?.type === 'amount_off') {
      if (+(values?.price || 0) - +(couponData?.amount_off || 0) < 0) {
        return {
          price: intl.formatMessage(messages.priceLessThanDiscountText),
        };
      }
    }
  };

  const submitForm = values => {
    if (!couponData?.show_price_on_coupon) {
      if (!values?.price || values?.price <= 0) {
        return { price: intl.formatMessage(messages.priceCannotBeEmptyText) };
      }
      const { price, ...rest } = values; // Added only to support backend
      handleProcessCouponClick(true, { ...rest, coupon_total_price: price });
    } else {
      handleProcessCouponClick(true);
    }
  };

  const initialValues = {
    price:
      (usedCouponData?.actual_price || 0) > 0
        ? usedCouponData?.actual_price
        : null,
    gst: usedCouponData?.gst || null,
    hst: usedCouponData?.hst || null,
    rst: usedCouponData?.rst || null,
    pst: usedCouponData?.pst || null,
    qst: usedCouponData?.qst || null,
  };

  return (
    <Modal
      title={intl.formatMessage(messages.blockHeadingText)}
      isOpen={isOpen}
      onRequestClose={handleCloseModalClick}
    >
      <div styleName="process-coupon-modal-container">
        {couponData && couponDataFetched ? (
          <>
            <FormattedMessage
              tagName="p"
              {...customersRequestText}
              values={{
                customerName: usedCouponData.customer_name,
                paymentType: usedCouponData.pay_by_points
                  ? intl.formatMessage(messages.payByPointsText)
                  : intl.formatMessage(messages.payByDollarsText),
                b: chunks => <b>{chunks}</b>,
              }}
            />
            <Coupon data={couponData} businessData={businessData} />
            <div styleName="coupon-price-details" className="text-right mt-3">
              <Form
                onSubmit={submitForm}
                initialValues={initialValues}
                validate={validateForm}
                render={({ handleSubmit }) => {
                  return (
                    <>
                      <FormSpy
                        subscription={{
                          values: true,
                          form: true,
                          dirty: true,
                          submitting: true,
                        }}
                        render={({ values, form, dirty, submitting }) => (
                          <>
                            {couponData?.show_price_on_coupon ? (
                              <CouponPriceDetails data={couponData} />
                            ) : (
                              <CouponPriceFiledGroup
                                couponData={couponData}
                                couponActionComplete={!usedCouponInWaitingState}
                                usedCouponData={usedCouponData}
                                data={values}
                                form={form}
                              />
                            )}
                          </>
                        )}
                      />

                      {usedCouponInWaitingState && (
                        <>
                          <hr className="mb-0" />
                          {couponsActivityFetching ? (
                            <Spinner
                              containerClassName="coupon-processing-spinner-container"
                              show
                            />
                          ) : (
                            <>
                              <Button
                                onClick={() => handleProcessCouponClick()}
                                outline
                              >
                                <FormattedMessage
                                  {...messages.rejectButtonText}
                                />
                              </Button>
                              <Button
                                onClick={handleSubmit}
                                className="mr-0"
                                fetching={taxRatesState.fetching}
                              >
                                <FormattedMessage
                                  {...messages.acceptButtonText}
                                />
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </>
                  );
                }}
              />
            </div>
          </>
        ) : (
          <Spinner show />
        )}
        <Feedback
          className="mt-3"
          show={!!(couponsActivityError && !couponsActivityFetching)}
          content={couponsActivityError}
          invalid
        />
      </div>
    </Modal>
  );
};

ProcessCoupon.propTypes = {
  intl: PropTypes.object,
  dispatch: PropTypes.func,
  usedCouponData: PropTypes.object,
  isRootModal: PropTypes.bool,
  couponsActivityFetching: PropTypes.bool,
  couponsActivityError: PropTypes.string,
  couponsActivityIsModalOpen: PropTypes.bool,
  newUsedCouponsIsModalOpen: PropTypes.bool,
  couponBuilderState: PropTypes.object,
  businessData: PropTypes.object,
  taxRatesState: PropTypes.object,
};

const mapStateToProps = state => ({
  couponsActivityFetching: state.usedCoupons.couponsActivity.fetching,
  couponsActivityError: state.usedCoupons.couponsActivity.error,
  couponsActivityIsModalOpen: state.usedCoupons.couponsActivity.isModalOpen,
  couponBuilderState: state.couponBuilder,
  newUsedCouponsIsModalOpen: state.usedCoupons.newUsedCoupons.isModalOpen,
  businessData: state.businessInfo.data,
  taxRatesState: state.taxRates,
});

export default connect(mapStateToProps)(
  injectIntl(CSSModule(ProcessCoupon, styles)),
);
