import { apiCall } from '../../../utils';

/**
 * Send request to refresh access token by POST-ing refresh token.
 *
 * @param {string} refresh - refresh token
 */
export const refreshAccessToken = refresh =>
  apiCall({
    url: '/users/token/refresh/',
    method: 'post',
    data: { refresh },
  });
