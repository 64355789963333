import { createActions, createReducer, resettableReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  values: {},
  errors: null,
};

const { Types, Creators } = createActions(
  {
    reset: [],
    signUp: ['formValues'],
    signUpRequest: ['formValues'],
    signUpSuccess: [],
    signUpFailure: ['errors'],
  },
  { prefix: 'cb/signup/' },
);

export { Types as types, Creators as actions };

export const signUpRequest = (state = INITIAL_STATE, action) => {
  const { formValues } = action;
  return {
    ...state,
    fetching: true,
    fetched: false,
    values: formValues,
    errors: null,
  };
};

export const signUpSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    fetching: false,
    fetched: true,
    errors: null,
  };
};

export const signUpFailure = (state = INITIAL_STATE, action) => {
  const { errors } = action;
  return {
    ...state,
    fetching: false,
    fetched: true,
    errors,
  };
};

export const HANDLERS = {
  [Types.SIGN_UP_REQUEST]: signUpRequest,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,
  [Types.SIGN_UP_FAILURE]: signUpFailure,
};

export default resettableReducer(Types.RESET)(
  createReducer(INITIAL_STATE, HANDLERS),
);
