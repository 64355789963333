import { defineMessages } from 'react-intl';

export const scope = 'cb.components.PointsProgramField';

export default defineMessages({
  blockHeading: {
    id: `${scope}.blockHeading`,
    defaultMessage: 'Points Program',
  },
  joinPointsProgramLabel: {
    id: `${scope}.joinPointsProgramLabel`,
    defaultMessage: 'Join points program',
  },
  requireJoinPointsProgramError: {
    id: `${scope}.requiredJoinPointsProgramMessage`,
    defaultMessage: 'Please join points program to continue with registration.',
  },
  pointsProgramText: {
    id: `${scope}.pointsProgramText`,
    defaultMessage: `Customers are waiting to use their points at your store.
      Join the Points Program and you will be capable of receiving points from new and returning customers. Points can then either be used within the app or exchanged back to cash. You can also receive points when you refer your customers to our app. You will receive { earnPoints, plural, one {# point} other {# points}} for every customer that becomes a member every year they sign up.
      Click below to join the Points Program to start receiving points.`,
  },
  agreeWithDealerAgreementLabel: {
    id: `${scope}.agreeWithDealerAgreementLabel`,
    defaultMessage:
      'Agree to the <dealerAgreementLink>Dealer Agreement</dealerAgreementLink>',
  },
  requireAgreeWithDealerAgreementError: {
    id: `${scope}.requireAgreeWithDealerAgreementError`,
    defaultMessage:
      'Please agree with Dealer Agreement to continue with registration.',
  },
});
