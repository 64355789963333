import { normalize } from 'normalizr';

import { getFormattedLoadingError } from './validation';

/**
 * Dispatch action, return promise and add Promise.resolve to action payload.
 * Used to move async logic to saga, using its effects. For example it could
 * be used for Final-Forms async validation and submissions.
 *
 * @param {Object} action
 * @param {Function} dispatch
 *
 * @returns {Promise}
 */
export const dispatchAsAsync = (action, dispatch) =>
  new Promise(resolve => dispatch({ ...action, resolve }));

/* Normalized getters */

export const getListFromNormalized = (normalized, entity) => {
  const ids = normalized.result[entity].results;
  const entities = normalized.entities[entity];
  if (entities) {
    return ids.map(id => entities[id]);
  }
  return [];
};

export const getObjFromNormalized = (normalized, entity, key) => {
  const entities = normalized.entities[entity];
  if (entities && entities[key]) {
    return entities[key];
  }
  return null;
};

export const getPropFromNormalized = (normalized, entity, prop) => {
  return normalized.result[entity][prop];
};

// It's a mixed normalizer to convert redux entity fields to appropriate component's props
// 'fetching', 'error' and 'next' are used all around main apps by convention as entity keys
export const getNormalizedEntity = (entity, entityKey, intl, message) => {
  const entityList = getListFromNormalized(entity, entityKey);
  const isFetching = getPropFromNormalized(entity, entityKey, 'fetching');
  const error = getFormattedLoadingError(
    intl,
    getPropFromNormalized(entity, entityKey, 'error'),
    message,
  );
  const isNextPageExist = !!getPropFromNormalized(entity, entityKey, 'next');

  return { entityList, isFetching, error, isNextPageExist };
};

export const formSelectValueFormatter = (normalized, entity) => key => {
  return getObjFromNormalized(normalized, entity, key);
};

/* Normalizer Schema generator */

export const getResponseSchema = entity => ({
  [entity.key]: {
    results: [entity],
  },
});

/* HANDLERS for Normalized structured states */

export const entityFetching = (entity, options = { reset: false }) => state => {
  return {
    ...state,
    result: {
      ...state.result,
      [entity.key]: {
        ...(options.reset
          ? { error: null, results: [] }
          : state.result[entity.key]),
        fetching: true,
        fetched: false,
      },
    },
  };
};

export const entityFetchingSuccess = (entity, options = { attach: false }) => (
  state,
  action,
) => {
  const { data } = action;
  const normalized = normalize(
    { [entity.key]: data },
    getResponseSchema(entity),
  );
  return {
    ...state,
    entities: {
      ...state.entities,
      [entity.key]: {
        ...state.entities[entity.key],
        ...normalized.entities[entity.key],
      },
    },
    result: {
      ...state.result,
      [entity.key]: {
        ...state.result[entity.key],
        ...normalized.result[entity.key],
        ...(options.attach
          ? {
              results: [
                ...new Set(
                  state.result[entity.key].results.concat(
                    normalized.result[entity.key].results,
                  ),
                ),
              ],
            }
          : {}),
        fetching: false,
        fetched: true,
        error: null,
      },
    },
  };
};

export const entityFetchingFailure = entity => (state, action) => {
  const { error } = action;
  return {
    ...state,
    result: {
      ...state.result,
      [entity.key]: {
        ...state.result[entity.key],
        fetching: false,
        fetched: false,
        results: [],
        error,
      },
    },
  };
};
