import { call, put, takeLatest } from 'redux-saga/effects';
import { actions, types } from './duck';
import { getPointsBalances } from './api';
import { OK } from 'http-status-codes';

function* getPointsBalancesSaga() {
  try {
    yield put(actions.getPointsBalancesRequest());

    const response = yield call(getPointsBalances);
    if (response.status === OK) {
      yield put(actions.getPointsBalancesSuccess(response.data));
    } else {
      yield put(actions.getPointsBalancesFailure(response.statusText));
    }
  } catch (error) {
    yield put(actions.getPointsBalancesFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_POINTS_BALANCES, getPointsBalancesSaga);
}