import React from 'react';

import PropTypes from 'prop-types';

import { FormFieldWrapper, Input, TextArea } from 'components';

export const FormField = ({
  label,
  input,
  meta,
  showValid,
  readOnly,
  sideLabel,
  isTextArea,
  computeDisplayError,
  ...rest
}) => {
  const shouldDisplayError = computeDisplayError
    ? computeDisplayError(meta)
    : !!((meta.error || meta.submitError) && meta.touched);

  return (
    <FormFieldWrapper
      invalid={shouldDisplayError}
      label={label}
      error={meta.error || meta.submitError}
    >
      {isTextArea ? (
        <TextArea {...input} readOnly={readOnly} {...rest} />
      ) : (
        <Input
          {...input}
          {...rest}
          sideLabel={sideLabel}
          readOnly={readOnly}
          valid={showValid ? meta.valid && !meta.validating : false}
          invalid={shouldDisplayError}
        />
      )}
    </FormFieldWrapper>
  );
};

FormField.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  showValid: PropTypes.bool,
  readOnly: PropTypes.bool,
  isTextArea: PropTypes.bool,
};

export default FormField;
