import { all } from 'redux-saga/effects';

import * as pointsActivity from './pointsActivity';
import * as processPointsRequest from './processPointsRequest'
import * as updatePoints from './updatePoints'


export default function* saga() {
  yield all([pointsActivity.saga(), processPointsRequest.saga(), updatePoints.saga()]);
}
