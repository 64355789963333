import React from 'react'
import { FormField, FormFieldWrapper, FormPillSelector } from 'components';
import { Field, Form } from 'react-final-form';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';

const AdminBusinessUserForm = ({ styles, membership_plans, isEditing, allCategories, cities }) => {
  return (
    <div className={styles.fields}>
      <h3>Account Info</h3>
      <Field
        className={classNames(styles.formGroup)}
        name="id"
        label="Id"
        component={FormField}
        disabled
        type="number"
      />
      <Field
        className={classNames(styles.formGroup)}
        name="email"
        label="Email"
        component={FormField}
        disabled
        readOnly={!isEditing}
        type="email"
      />
      <Field
        className={classNames(styles.formGroup)}
        name="name"
        label="name"
        component={FormField}
        readOnly={!isEditing}
        type="text"
      />
      <Field
        className={classNames(styles.formGroup)}
        name="contact_name"
        label="Contact Name"
        component={FormField}
        readOnly={!isEditing}
        type="text"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="city"
        label="City"
        component={({ input, meta, label }) => {
          const citiesArray = Object.values(cities); // Convert object to array
          const currentCity = citiesArray.find((city) => city.id === meta.initial)
          return (
            <FormFieldWrapper
              invalid={meta.touched && meta.error}
              label={label}
              error={meta.error}
            >
              <select
                {...input}
                onChange={(e) => input.onChange(Number(e.target.value))} //convert city id to number onSubmit
                style={{ height: "70%" }}
                disabled={!isEditing}
                className="form-control"
              >
                <option value={currentCity.id}>{currentCity.name}</option>
                {citiesArray?.map((city) => (
                  city.id != currentCity.id && (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  )
                ))}
              </select>
            </FormFieldWrapper>
          );
        }}
      />
      <Field
        className={classNames(styles.formGroup)}
        name="phone"
        label="Phone"
        component={FormField}
        readOnly={!isEditing}
        type="text"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="cover_image"
        label="Cover Image URL"
        component={FormField}
        readOnly={!isEditing}
        disabled
        type="text"
      />
      <Field
        className={classNames(styles.formGroup)}
        name="street"
        label="Street"
        component={FormField}
        readOnly={!isEditing}
        type="text"
      />
      <Field
        className={classNames(styles.formGroup)}
        name="postal_code"
        label="Postal Code"
        component={FormField}
        readOnly={!isEditing}
        type="text"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="premium_slots"
        label="Available Premium Slots"
        component={FormField}
        readOnly={!isEditing}
        type="number"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="terms_and_conditions"
        label="Terms and Conditions"
        component={FormField}
        readOnly={!isEditing}
        type="text"
        defaultValue={""}
      />

      <Field
        className={classNames(styles.formGroup)}
        name="logo"
        label="Logo URL"
        component={FormField}
        readOnly={!isEditing}
        disabled
        type="text"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="business_web_url"
        label="Business Web URL"
        component={FormField}
        readOnly={!isEditing}
        disabled
        type="text"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="firebase_fcm"
        label="Firebase FCM"
        component={FormField}
        readOnly={!isEditing}
        type="text"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="firebase_uid"
        label="Firebase UID"
        component={FormField}
        readOnly={!isEditing}
        type="text"
      />

      <Field
        name="categories"
        label="Categories"
        component={({ input, meta, label }) => {
          return (
            <FormFieldWrapper
              invalid={meta.touched && meta.error}
              label={label}
              error={meta.error}
            >
              <div>
                <FormPillSelector
                  input={input}
                  meta={meta}
                  options={allCategories}
                  readOnly={!isEditing}
                  maxSelected={3}
                />
              </div>
            </FormFieldWrapper>
          );
        }}

      />

      <Field
        className={classNames(styles.formGroup)}
        name="signed_at"
        label="Signed At"
        component={FormField}
        readOnly={!isEditing}
        disabled
        type="datetime-local"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="location.coordinates[0]"
        label="Longitude"
        component={FormField}
        readOnly={!isEditing}
        type="number"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="location.coordinates[1]"
        label="Latitude"
        component={FormField}
        readOnly={!isEditing}
        type="number"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="groups"
        label="Groups"
        component={FormField}
        readOnly={!isEditing}
        disabled
        type="text" // Replace with a multi-select dropdown if appropriate
      />


      <div className='d-flex justify-content-between flex-wrap'>


        <Field
          className={classNames(styles.formGroup)}
          name="is_active"
          label="Is Active"
          component={FormField}
          type="checkbox"
          disabled={!isEditing}
        />
        <Field
          className={classNames(styles.formGroup)}
          name="is_blocked"
          label="Is Blocked"
          component={FormField}
          type="checkbox"
          disabled={!isEditing}
        />

        <Field
          className={classNames(styles.formGroup)}
          name="is_staff"
          label="Is Staff"
          component={FormField}
          type="checkbox"
          disabled
        />

        <Field
          className={classNames(styles.formGroup)}
          name="is_superuser"
          label="Is Superuser"
          component={FormField}
          type="checkbox"
          disabled
        />

        <Field
          className={classNames(styles.formGroup)}
          name="has_joined_points_program"
          label="Has Joined Points Program"
          component={FormField}
          type="checkbox"
          disabled={!isEditing}
        />

      </div>

      <h3>Membership Info</h3>

      <Field
        className={classNames(styles.formGroup)}
        name="membership.plan"
        label="Membership Plan"
        component={({ input, meta, label }) => {

          const currentPlan = membership_plans?.find((plan) => plan.id === meta.initial)

          return (
            <FormFieldWrapper
              invalid={meta.touched && meta.error}
              label={label}
              error={meta.error}
            >
              <select
                {...input}
                style={{ height: "70%" }}
                disabled={!isEditing}
                onChange={(e) => input.onChange(Number(e.target.value))}
                className="form-control"
              >
                <option value={currentPlan?.id}>{currentPlan?.name}</option>
                {membership_plans?.map((membership) => (
                  membership.id !== currentPlan?.id && (
                    <option key={membership.id} value={membership.id}>
                      {membership.name}
                    </option>
                  )
                ))}
              </select>
            </FormFieldWrapper>
          );
        }}
      />

      <Field
        className={classNames(styles.formGroup)}
        name="membership.date_start"
        label="Membership Start Date"
        component={FormField}
        disabled
        type="datetime-local"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="membership.expiration_date"
        label="Membership Expiration Date"
        component={FormField}
        disabled={!isEditing}
        type="datetime-local"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="membership.is_active"
        label="Membership is active"
        component={FormField}
        disabled
        type="checkbox"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="membership.membership_details.coupon_capacity"
        label="Coupon Capacity"
        component={FormField}
        readOnly={!isEditing}
        type="number"
      />
      <Field
        className={classNames(styles.formGroup)}
        name="membership.membership_details.available_coupons"
        label="Available Coupons"
        component={FormField}
        readOnly={!isEditing}
        type="number"
      />
      <Field
        className={classNames(styles.formGroup)}
        name="membership.membership_details.sponsorship_capacity"
        label="Sponsorship Capacity"
        component={FormField}
        readOnly={!isEditing}
        type="number"
      />
      <Field
        className={classNames(styles.formGroup)}
        name="membership.membership_details.available_sponsorships_slots"
        label="Available Sponsorhsip Slots"
        component={FormField}
        readOnly={!isEditing}
        type="number"
      />
      <Field
        className={classNames(styles.formGroup)}
        name="membership.membership_details.make_business_sponsored"
        label="Business is sponsored"
        component={FormField}
        disabled
        type="checkbox"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="membership.has_plan_upgrade_requests"
        label="Plan Upgrade Requested"
        component={FormField}
        disabled
        type="checkbox"
      />

      <h3>Wallet Info</h3>
      <div className='d-flex justify-content-end'>
        <NavLink to="/admin/points/manage">Manage Points</NavLink>
      </div>
      <Field
        className={classNames(styles.formGroup)}
        name="points_wallet"
        label="Points Wallet ID"
        component={FormField}
        readOnly={!isEditing}
        disabled
        type="number"
      />

      <Field
        className={classNames(styles.formGroup)}
        name="wallet.points_at_last_date"
        label="Points"
        component={FormField}
        readOnly={!isEditing}
        disabled
        type="number"
      />
      <Field
        className={classNames(styles.formGroup)}
        name="wallet.points_recalculation_date"
        label="Points Recalculation Date"
        component={FormField}
        readOnly={!isEditing}
        disabled
        type="datetime-local"
      />

      <h3>Referral Info</h3>

      <Field
        className={classNames(styles.formGroup)}
        name="referral_node"
        label="Referral Node ID"
        component={FormField}
        readOnly={!isEditing}
        disabled
        type="text"
      />

    </div>
  )
}

export default AdminBusinessUserForm
