import { AppWrapper, Block, Spinner } from 'components'
import Breadcrumbs from 'components/BreadCrumbs/BreadCrumbs'
import AuthProtected from 'containers/AuthProtected/AuthProtected'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import messages from './messages'
import styles from './PointsTransactionsTable.module.scss'
import CSSModules from 'react-css-modules'
import { connect } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { actions as pointsTransactionsActions } from 'coupon-common/src/modules/admin/pointsManager/pointsTransactions';
import { actions as couponPointsTransactionActions } from 'coupon-common/src/modules/admin/pointsManager/couponPointsTransactions';
import { actions as pointsBalancesActions } from 'coupon-common/src/modules/admin/pointsManager/pointsBalances';

const PointsTransactionsTable = ({ intl, dispatch, transactionsData, transactionsDataFetched, couponsData, couponsDataFetched, balancesData, balancesDataFetched, currentUserEmail }) => {

    const [searchQuery, setSearchQuery] = useState(null);
    const [data, setData] = useState(null)
    const [couponData, setCouponData] = useState(null);
    const [combinedData, setCombinedData] = useState([]);
    const [balances, setBalances] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        dispatch(pointsTransactionsActions.getPointsTransactions())
        dispatch(couponPointsTransactionActions.getCouponTransactions({}));
        dispatch(pointsBalancesActions.getPointsBalances())
    }, [dispatch])

     useEffect(() => {
        if(transactionsDataFetched){
            // const num = 5;
            // const paddedNum = String(num).padStart(4, '0');
            const transactions = transactionsData?.map((tx)=> (
                {...tx, id: `P${String(tx.id).padStart(4, '0')}`}))
            setData(transactions)
        }
        }, [transactionsData, transactionsDataFetched])

        useEffect(() => {
            if(balancesDataFetched){

                setBalances(balancesData)
            }
        }, [balancesData, balancesDataFetched])

        // console.log("current user", currentUserEmail);


        useEffect(() => {
            if(couponsDataFetched){
                // console.log("PW COUPONS ARE " + JSON.stringify(couponsData));
                // ✅ Transform `couponPointsTransactions` to match `pointsTransactions`
                const mappedCouponTransactions = couponsData?.results?.map(tx => ({
                    id: `C${String(tx.id).padStart(4, '0')}`,
                    time: tx.transaction_datetime,
                    amount: tx.points_delta, // Ensure it's a number
                    from_user:  tx.from_email && tx.from_email.trim() !== "" ? tx.from_email : "ADMIN", // Use "ADMIN" if no from email
                    to_user: tx.to_email,
                    status: "success", // Assume success
                    notes: tx.transaction_type,
                    previous_balance: null, // No equivalent field
                    new_balance: null, // No equivalent field
                    from_user_name: tx.from_email && tx.from_email.trim() !== "" ? tx.from_user_name : "ADMIN",
                    to_user_name: tx.to_user_name,
                }));
                setCouponData(mappedCouponTransactions);
            }
        }, [couponsData, couponsDataFetched])

        const fillMissingBalances = (transactions) => {
            // Sort transactions from newest to oldest (latest transactions first)
            transactions.sort((a, b) => new Date(b.time) - new Date(a.time));

            // Get the latest available balance for the current user, ensuring it's a number
            let currentBalance = Number(
                balancesData.find(item => item.email === currentUserEmail)?.points_at_last_date
            ) || 0;

            // Iterate from newest to oldest transaction
            for (let i = 0; i < transactions.length; i++) {
                let transaction = transactions[i];
                const { to_user, from_user } = transaction;

                // Ensure `amount` is a valid number
                let amount = Number(transaction.amount) || 0;

                if (to_user === currentUserEmail) {
                    // If the user is **receiving** points
                    transaction.new_balance = currentBalance;
                    transaction.previous_balance = Number((currentBalance - amount).toFixed(2));
                    currentBalance = transaction.previous_balance; // Update balance for next transaction

                } else if (from_user === currentUserEmail) {
                    // If the user is **sending** points
                    transaction.new_balance = currentBalance;
                    transaction.previous_balance = Number((currentBalance + amount).toFixed(2));
                    currentBalance = transaction.previous_balance; // Update balance for next transaction

                    // Ensure the amount being given away is displayed as negative
                    transaction.amount = -Math.abs(amount);
                }
            }

            return transactions; // Already sorted from newest to oldest
        };


        // This grabs the points transactions related to coupons that give back coupons
        // or points from referrals. They are pulled from the referral_program_pointstransactions
        useEffect(() => {
            if (data && couponData && balancesDataFetched) {
                const mergedData = [...data, ...couponData];
                mergedData.sort((a, b) => new Date(b.time) - new Date(a.time));
                console.log("merged data", mergedData);
                const filterByCurrentUser = mergedData.filter((data)=> data.from_user == currentUserEmail || data.to_user == currentUserEmail)
                console.log("Filter by current user", filterByCurrentUser);

                const updatedTransactions = fillMissingBalances(filterByCurrentUser);
                setCombinedData(updatedTransactions);
                setLoading(false);
            }
        }, [data, couponData, balancesDataFetched]);


    const columns = [
        {
            dataField: 'id',
            text: intl.formatMessage(messages.idColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'time',
            text: intl.formatMessage(messages.timeColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
            formatter: (cellContent) => {
                // Convert the string into a Date object and format it
                const formattedDate = new Date(cellContent).toLocaleString();
                return formattedDate || cellContent;
            },
        },
        {
            dataField: 'from_user',
            text: intl.formatMessage(messages.fromUserColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
            formatter: (cell, row) => {
                return (row.from_user === currentUserEmail || row.from_user === "info@mycouponbook.ca") ? row.from_user : row.from_user_name;
            },
        },
        {
            dataField: 'to_user_name',
            text: intl.formatMessage(messages.toUserColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
            formatter: (cell, row) => {
                return row.to_user === currentUserEmail ? row.to_user : row.to_user_name;
            },
        },
        {
            dataField: 'previous_balance',
            text: intl.formatMessage(messages.previousBalanceColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'amount',
            text: intl.formatMessage(messages.amountColumnText),
            sort: true,
            classes: (cell, row, rowIndex, columnIndex, extendedValue) => {
                return (row?.to_user === currentUserEmail && row?.amount > 0) ? styles.positive : styles.negative;
            },
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'new_balance',
            text: intl.formatMessage(messages.newBalanceColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },

        {
            dataField: 'status',
            text: intl.formatMessage(messages.statusColumnText),
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'notes',
            text: intl.formatMessage(messages.notesColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
    ];

    const paginationOptions = {
        sizePerPage: 25,
        totalSize: combinedData?.length,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        firstPageText: 'First',
        lastPageText: 'Last',
        nextPageText: 'Next',
        prePageText: 'Previous',
        withFirstAndLast: true,
    };



    return (
        <AppWrapper
            showHeader
            showSideNav
            showHeadingRow
            headingText={intl.formatMessage(messages.pageHeadingText)}
        >
            <AuthProtected />

            <div className="col-md-12">
                {/* <Breadcrumbs locationArray={["Admin", "Points Manager", "Points Transactions"]} /> */}
                <Block>
                    {(loading) ? (
                        <Spinner show />
                    ) : (
                        <>
                            <div className='container'>
                                <div className="row justify-content-end align-items-center mb-2 p-1">
                                    {/* <div styleName='search'>
                                    <input type="text" placeholder='Search by email..' onChange={(e) => setSearchQuery(e.target.value)} />
                                </div> */}
                                </div>
                            </div>
                            <div styleName='table-container' className="table-container overflow-auto">
                                <BootstrapTable
                                    classes="table-borderless mb-0 overflow-auto custom-react-bootstrap-table"
                                    striped
                                    bordered={false}
                                    bootstrap4
                                    keyField="id"
                                    data={combinedData}
                                    columns={columns}
                                    pagination={paginationFactory(paginationOptions)}
                                />

                            </div>
                        </>
                    )}
                </Block>
            </div>



        </AppWrapper>
    )
}

const mapStateToProps = (state) => {
    return {
        authorized: state.auth.authorized,
        transactionsData: state.admin.pointsManager.pointsTransactions.data,
        transactionsDataFetched: state.admin.pointsManager.pointsTransactions.fetched,
        couponsData: state.admin.pointsManager.couponPointsTransactions.data,
        couponsDataFetched: state.admin.pointsManager.couponPointsTransactions.fetched,
        balancesData: state.admin.pointsManager.pointsBalances.data,
        balancesDataFetched: state.admin.pointsManager.pointsBalances.fetched,
        currentUserEmail: state.businessInfo.data.email,
    };
};

export default connect(mapStateToProps)(injectIntl(CSSModules(PointsTransactionsTable, styles)));
