import { schema } from 'normalizr';
import { createActions, createReducer } from 'reduxsauce';

import {
  entityFetching,
  entityFetchingFailure,
  entityFetchingSuccess,
} from '../../utils/redux';

export const provincesEntity = new schema.Entity('provinces');
export const citiesEntity = new schema.Entity('cities');

export const INITIAL_STATE = {
  entities: {
    [provincesEntity.key]: {},
    [citiesEntity.key]: {},
  },
  result: {
    [provincesEntity.key]: {
      fetching: false,
      fetched: false,
      results: [],
      error: null,
    },
    [citiesEntity.key]: {
      fetching: false,
      fetched: false,
      results: [],
      error: null,
    },
  },
};

const { Types, Creators } = createActions(
  {
    getProvinces: [],
    getProvincesRequest: [],
    getProvincesSuccess: ['data'],
    getProvincesFailure: ['error'],

    getProvincesNextPage: [],
    getProvincesNextPageRequest: [],
    getProvincesNextPageSuccess: ['data'],
    getProvincesNextPageFailure: ['error'],

    getCities: ['provinceName', 'name'],
    getCitiesRequest: [],
    getCitiesSuccess: ['data'],
    getCitiesFailure: ['error'],

    // Same as getCities but with bebounced watcher
    getFilteredCities: ['provinceName', 'name'],

    getCitiesNextPage: [],
    getCitiesNextPageRequest: [],
    getCitiesNextPageSuccess: ['data'],
    getCitiesNextPageFailure: ['error'],
  },
  { prefix: 'cb/locations/' },
);

export { Types as types, Creators as actions };

export const HANDLERS = {
  [Types.GET_PROVINCES_REQUEST]: entityFetching(provincesEntity),
  [Types.GET_PROVINCES_SUCCESS]: entityFetchingSuccess(provincesEntity),
  [Types.GET_PROVINCES_FAILURE]: entityFetchingFailure(provincesEntity),
  [Types.GET_PROVINCES_NEXT_PAGE_REQUEST]: entityFetching(provincesEntity),
  [Types.GET_PROVINCES_NEXT_PAGE_SUCCESS]: entityFetchingSuccess(
    provincesEntity,
    { attach: true },
  ),
  [Types.GET_PROVINCES_NEXT_PAGE_FAILURE]: entityFetchingFailure(
    provincesEntity,
  ),
  [Types.GET_CITIES_REQUEST]: entityFetching(citiesEntity),
  [Types.GET_CITIES_SUCCESS]: entityFetchingSuccess(citiesEntity),
  [Types.GET_CITIES_FAILURE]: entityFetchingFailure(citiesEntity),
  [Types.GET_CITIES_NEXT_PAGE_REQUEST]: entityFetching(citiesEntity),
  [Types.GET_CITIES_NEXT_PAGE_SUCCESS]: entityFetchingSuccess(citiesEntity, {
    attach: true,
  }),
  [Types.GET_CITIES_NEXT_PAGE_FAILURE]: entityFetchingFailure(citiesEntity),
};

export default createReducer(INITIAL_STATE, HANDLERS);
