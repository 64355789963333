import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field } from 'react-final-form';
import { Block, FormSwitch } from 'components';
import messages from './messages';

const JoinLoyaltyPointsField = ({ intl, readOnly, fullWidth }) => {
  // Match the width logic of BusinessInfoFieldGroup
  const colContainerClassName = fullWidth ? 'col-md-12' : 'col-md-7';

  return (
    <Block>
      <div className="row justify-content-center">
        <div className={colContainerClassName}>
          <h5>
            <FormattedMessage {...messages.heading} />
          </h5>
          <p>
            <FormattedMessage {...messages.description} />
          </p>
          
          <h6>
            <FormattedMessage {...messages.howItWorks} />
          </h6>
          <p>
            <FormattedMessage {...messages.howItWorksDescription} />
          </p>

          <ul>
            <li><FormattedMessage {...messages.points10} /></li>
            <li><FormattedMessage {...messages.points25} /></li>
            <li><FormattedMessage {...messages.points50} /></li>
            <li><FormattedMessage {...messages.points100} /></li>
          </ul>

          <p>
            <FormattedMessage {...messages.agreement} />
          </p>

          <Field
            name="has_joined_points_program"
            label={<FormattedMessage {...messages.enableSwitch} />}
            type="checkbox"
            readOnly={readOnly}
            component={FormSwitch}
          />
        </div>
      </div>
    </Block>
  );
};

export default injectIntl(JoinLoyaltyPointsField);
