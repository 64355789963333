import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_COUPON_ITEM_STATE = {
  fetching: false,
  fetched: false,
  data: {},
  error: null,
};

export const INITIAL_STATE = {};

const { Types, Creators } = createActions(
  {
    createCoupon: ['id', 'data', 'resolve'],
    createCouponRequest: ['id'],
    createCouponSuccess: ['id', 'data'],
    createCouponFailure: ['id', 'error'],

    getCoupon: ['id'],
    getCouponRequest: ['id'],
    getCouponSuccess: ['id', 'data'],
    getCouponFailure: ['id', 'error'],

    editCoupon: ['id', 'data', 'resolve'],
    editCouponRequest: ['id'],
    editCouponSuccess: ['id', 'data'],
    editCouponFailure: ['id', 'error'],

    clearCouponState: ['id'],
  },
  { prefix: 'cb/couponBuilder/' },
);

export { Types as types, Creators as actions };

export const request = (state, { id }) => ({
  ...state,
  [id]: state[id]
    ? {
        ...INITIAL_COUPON_ITEM_STATE,
        data: state[id].data,
        fetching: true,
      }
    : { ...INITIAL_COUPON_ITEM_STATE, fetching: true },
});

export const success = (state, { id, data }) => ({
  ...state,
  [id]: {
    ...INITIAL_COUPON_ITEM_STATE,
    fetched: true,
    data,
  },
});

export const failure = (state, { id, error }) => ({
  ...state,
  [id]: {
    ...INITIAL_COUPON_ITEM_STATE,
    data: state[id].data,
    fetched: true,
    error,
  },
});

export const clearCouponState = (state, { id }) => ({
  ...state,
  [id]: INITIAL_COUPON_ITEM_STATE,
});

export const HANDLERS = {
  [Types.CREATE_COUPON_REQUEST]: request,
  [Types.CREATE_COUPON_SUCCESS]: success,
  [Types.CREATE_COUPON_FAILURE]: failure,

  [Types.GET_COUPON_REQUEST]: request,
  [Types.GET_COUPON_SUCCESS]: success,
  [Types.GET_COUPON_FAILURE]: failure,

  [Types.EDIT_COUPON_REQUEST]: request,
  [Types.EDIT_COUPON_SUCCESS]: success,
  [Types.EDIT_COUPON_FAILURE]: failure,

  [Types.CLEAR_COUPON_STATE]: clearCouponState,
};

export default createReducer(INITIAL_STATE, HANDLERS);
