import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.CouponImagesFieldGroup';

export default defineMessages({
  CouponImageblockHeading: {
    id: `${scope}.CouponImageblockHeading`,
    defaultMessage: 'Coupon Images',
  },
});
