import React, { useCallback, useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage, injectIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import {
  AppWrapper,
  Block,
  Coupon,
  CouponActionButton,
  Feedback,
  Spinner,
} from 'components';
import {
  AuthProtected,
  CouponDelete,
  CouponRepublish,
  CouponSponsor,
} from 'containers';
import { COUPON_STATES } from 'coupon-common';
import { actions, duck } from 'coupon-common/src/modules/coupons';
import { dispatchAsAsync, getNormalizedEntity } from 'coupon-common/src/utils';
import { useNextPageHandler } from 'hooks';

import {
  DeleteButtonIcon,
  EditButtonIcon,
  PublishButtonIcon,
  RepublishButtonIcon,
  SponsorButtonIcon,
} from './icons';
import messages from './messages';
import styles from './MyCoupons.module.scss';

const DEFAULT_TRANSITION_TIMEOUT = 300;

const DEFAULT_NEXT_PAGE_LOADING_SKELETON_HEIGHT = 50;
const DEFAULT_NEXT_PAGE_LOADING_SKELETON_DURATION = 0.9;

const couponsEntityKey = duck.couponsEntity.key;

export const MyCoupons = ({
  intl,
  dispatch,
  history,
  coupons,
  businessInfo,
  viewCounts,
}) => {

  dispatch= useDispatch();

  useEffect(() => {
    dispatch(actions.getBusinessCoupons());
  }, [dispatch]);

  const [couponActionModalOpen, setCouponActionModalOpen] = useState(false);

  const [republishCouponId, setRepublishCouponId] = useState(null);
  const [deleteCouponData, setDeleteCouponData] = useState(null);
  const [sponsorCouponData, setSponsorCouponData] = useState(null);

  const openCouponActionModal = () => setCouponActionModalOpen(true);
  const closeCouponActionModal = () => {
    setCouponActionModalOpen(false);
    setRepublishCouponId(null);
    setDeleteCouponData(null);
    setSponsorCouponData(null);
  };

  const {
    entityList: couponsList,
    isFetching: isCouponsFetching,
    error: couponsFetchingError,
    isNextPageExist: couponsNextPageExist,
  } = React.useMemo(() => getNormalizedEntity(
    coupons,
    couponsEntityKey,
    intl,
    messages.couponsEntity,
  ), [coupons]);
  const isCouponsActionsFetching = coupons.couponsActions.fetching;
  const couponsActionsError = coupons.couponsActions.error;
  const lastCouponsListItem = couponsList.slice(-1)[0];
  const verifiedBusiness = businessInfo?.verified;

  const memoizedCouponsList = React.useMemo(() => couponsList, [couponsList]);

  const hasFetchedViewCounts = useRef(false);
  const stableDispatch = useCallback(dispatch, []); // Ensure dispatch remains stable

  useEffect(() => {
    if (couponsList.length > 0 && !hasFetchedViewCounts.current) {
      const couponIds = couponsList.map(coupon => coupon.id);
      stableDispatch(actions.getViewCounts({ coupon_ids: couponIds }));
      hasFetchedViewCounts.current = true;
    }
  }, [couponsList]);

  useEffect(() => {
    console.log("Updated View Counts:", viewCounts);
  }, [viewCounts]);


  useNextPageHandler(
    actions.getBusinessCouponsNextPage,
    couponsNextPageExist,
    isCouponsFetching,
    { isCouponsActionsNotFetching: !isCouponsActionsFetching },
  );

  const handleCouponActionBtnClick = async (
    couponId,
    couponTitle,
    action,
    notificationMessageId,
  ) => {
    const { success } = await dispatchAsAsync(action(couponId), dispatch);
    if (success) {
      toast(
        <FormattedMessage
          {...notificationMessageId}
          values={{
            couponTitle: <b>{couponTitle}</b>,
          }}
        />,
      );
    }
  };

  const renderPublishCouponButton = (itemId, couponTitle) => {
    const couponActionButtonLabel = intl.formatMessage(
      messages.publishCouponButtonLabel,
    );
    return (
      <CouponActionButton
        onClick={() =>
          handleCouponActionBtnClick(
            itemId,
            couponTitle,
            actions.publishCoupon,
            messages.publishCouponSuccessText,
          )
        }
        imgSrc={PublishButtonIcon}
        labeltext={couponActionButtonLabel}
        inactive={isCouponsActionsFetching || !verifiedBusiness}
        key={`${itemId}/${couponActionButtonLabel}`}
      />
    );
  };

  const renderEditCouponButton = itemId => {
    const couponActionButtonLabel = intl.formatMessage(
      messages.editCouponButtonLabel,
    );
    return (
      <CouponActionButton
        onClick={() => {
          history.push(`/coupons/${itemId}`);
        }}
        imgSrc={EditButtonIcon}
        labeltext={couponActionButtonLabel}
        inactive={isCouponsActionsFetching}
        key={`${itemId}/${couponActionButtonLabel}`}
      />
    );
  };

  const renderDeleteCouponButton = (itemId, couponTitle) => {
    const couponActionButtonLabel = intl.formatMessage(
      messages.deleteCouponButtonLabel,
    );
    return (
      <CouponActionButton
        onClick={() => {
          openCouponActionModal();
          setDeleteCouponData({ id: itemId, title: couponTitle });
        }}
        imgSrc={DeleteButtonIcon}
        labeltext={couponActionButtonLabel}
        inactive={isCouponsActionsFetching}
        key={`${itemId}/${couponActionButtonLabel}`}
      />
    );
  };

  const renderAnalyticsData = useCallback((data, message_id) => {
    return (
      <div
        className="d-flex align-items-center"
        styleName="coupon-action-section"
      >
        <span styleName="usage-info-section-value"> {data || 0} </span>
        <span styleName="usage-info-section-text">
          {' '}
          <FormattedMessage {...messages[message_id]} />{' '}
        </span>
      </div>
    );
  }, []);

  const renderSponsorCouponButton = (itemId, couponTitle) => {
    const couponActionButtonLabel = intl.formatMessage(
      messages.sponsorCouponButtonLabel,
    );
    return (
      <CouponActionButton
        onClick={() => {
          openCouponActionModal();
          setSponsorCouponData({ id: itemId, title: couponTitle });
        }}
        imgSrc={SponsorButtonIcon}
        labeltext={couponActionButtonLabel}
        inactive={isCouponsActionsFetching}
        key={`${itemId}/${couponActionButtonLabel}`}
      />
    );
  };

  const renderRepublishCouponButton = itemId => {
    const couponActionButtonLabel = intl.formatMessage(
      messages.republishCouponButtonLabel,
    );
    return (
      <CouponActionButton
        onClick={() => {
          setRepublishCouponId(itemId);
          openCouponActionModal();
        }}
        imgSrc={RepublishButtonIcon}
        labeltext={couponActionButtonLabel}
        inactive={isCouponsActionsFetching}
        key={`${itemId}/${couponActionButtonLabel}`}
      />
    );
  };

  const couponStateToActionsMap = {
    sponsored: [renderDeleteCouponButton],
    published: [renderSponsorCouponButton, renderEditCouponButton, renderDeleteCouponButton],
    draft: [
      renderPublishCouponButton,
      renderEditCouponButton,
      renderDeleteCouponButton,
    ],
    expired: [renderRepublishCouponButton, renderDeleteCouponButton],
    blocked: [renderDeleteCouponButton],
  };

  const getCouponActionButtons = item => {
    switch (true) {
      case item.is_blocked:
        return couponStateToActionsMap.blocked;
      case item.is_sponsored:
        return couponStateToActionsMap.sponsored;
      case item.state === COUPON_STATES.PUBLISHED:
        return couponStateToActionsMap.published;
      case item.state === COUPON_STATES.DRAFT:
        return couponStateToActionsMap.draft;
      case item.state === COUPON_STATES.EXPIRED:
        return couponStateToActionsMap.expired;
      default:
        return null;
    }
  };

  const [filterStatus, setFilterStatus] = useState(COUPON_STATES.PUBLISHED);
  const filteredCoupons = couponsList.filter(coupon => 
    filterStatus === "all" || coupon.state === filterStatus
  );

  return (
    <AppWrapper
      showHeader
      showSideNav
      showHeadingRow
      headingText={intl.formatMessage(messages.pageHeadingText)}
    >
      <AuthProtected />
      <div className="col-md-10">
        <Block>
          <FormattedMessage tagName="h5" {...messages.couponPreviewLabel} />

        
        {!isCouponsFetching && (
          <div className="mb-3">
                      <label className="mr-2">Filter by Status:</label>
                      <select
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value="all">All</option>
                        <option value={COUPON_STATES.PUBLISHED}>Published</option>
                        <option value={COUPON_STATES.DRAFT}>Draft</option>
                        <option value={COUPON_STATES.EXPIRED}>Expired</option>
                      </select>
                    </div>
                    )}

          <TransitionGroup>
            {filteredCoupons.map(item => {
              const isLastCouponItem = lastCouponsListItem.id === item.id;
              const isActionExecutedOnCurrentItem =
                item.id === coupons.couponsActions.couponId;
              return (
                <CSSTransition
                  key={item.id}
                  timeout={DEFAULT_TRANSITION_TIMEOUT}
                  classNames="coupon-list-animation"
                >
                  <div>
                    <div className="mb-3">
                      <span className="text-muted">
                        <FormattedMessage {...messages.categoryLabel} />
                      </span>
                      <span className="ml-1">{item.category.name}</span>
                    </div>
                    <div className="d-flex flex-wrap">
                      <div styleName="coupon-container">
                        <Coupon data={item} businessData={businessInfo} />
                      </div>
                      <div styleName="coupon-action-buttons-container">
                        {getCouponActionButtons(item).map(buttonRenderMethod =>
                          buttonRenderMethod(item.id, item.title),
                        )}
                        {renderAnalyticsData((viewCounts[item.id] || 0), 'viewsText')}
                        {renderAnalyticsData(
                          item.save_coupon_count,
                          'likesText',
                        )}
                        <Spinner
                          containerClassName="ml-2"
                          justify="left"
                          size="sm"
                          show={
                            isCouponsActionsFetching &&
                            isActionExecutedOnCurrentItem
                          }
                        />
                        <Feedback
                          invalid
                          show={
                            !!(
                              couponsActionsError &&
                              !isCouponsActionsFetching &&
                              isActionExecutedOnCurrentItem
                            )
                          }
                          content={couponsActionsError}
                        />
                      </div>
                    </div>
                    {!isLastCouponItem && (
                      <hr className="coupons-list-item-separator" />
                    )}
                  </div>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          {isCouponsFetching && (
            <Skeleton
              className="mt-5"
              height={DEFAULT_NEXT_PAGE_LOADING_SKELETON_HEIGHT}
              duration={DEFAULT_NEXT_PAGE_LOADING_SKELETON_DURATION}
            />
          )}
          <Feedback
            show={couponsFetchingError}
            invalid
            content={couponsFetchingError}
          />
        </Block>
        {republishCouponId && couponActionModalOpen && (
          <CouponRepublish
            isOpen={couponActionModalOpen}
            republishCouponId={republishCouponId}
            closeRepublishCouponHandler={closeCouponActionModal}
          />
        )}
        {deleteCouponData && couponActionModalOpen && (
          <CouponDelete
            isOpen={couponActionModalOpen}
            deleteCouponData={deleteCouponData}
            closeModalHandler={closeCouponActionModal}
          />
        )}
        {sponsorCouponData && couponActionModalOpen && (
          <CouponSponsor
            isOpen={couponActionModalOpen}
            sponsorCouponData={sponsorCouponData}
            closeModalHandler={closeCouponActionModal}
          />
        )}
      </div>
    </AppWrapper>
  );
};

MyCoupons.propTypes = {
  intl: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  coupons: PropTypes.object,
  businessInfo: PropTypes.object,
};

const mapStateToProps = state => {
  console.log("mapStateToProps: Current viewCounts state:", state.coupons.viewCounts);
  return {
    coupons: state.coupons,
    businessInfo: state.businessInfo.data,
    viewCounts: state.coupons.viewCounts || {},
  };
};


export default connect(mapStateToProps)(
  injectIntl(CSSModule(MyCoupons, styles, { allowMultiple: true })),
);
