import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  error: null,
  data: [],
  isModalOpen: false,
};


const { Types, Creators } = createActions(
    {
      getPointsRequests: ['params'],
      getPointsRequestsRequest: [],
      getPointsRequestsSuccess: ['data'],
      getPointsRequestsFailure: ['error'],
      openModal: [],
      closeModal: [],
    },
    { prefix: 'cb/pointsRequests/pointsActivity/' },
);

export { Types as types, Creators as actions };

// Reducers
const request = state => ({
  ...state,
  fetching: true,
  error: null,
});

const success = (state, action) => ({
  ...state,
  fetching: false,
  fetched: true,
  data: action.data,
});

const failure = (state, action) => ({
  ...state,
  fetching: false,
  fetched: false,
  error: action.error,
});

export const processModalState = isModalOpen => () => {
    return {
      ...INITIAL_STATE,
      isModalOpen,
    };
};


export const HANDLERS = {
    [Types.GET_POINTS_REQUESTS_REQUEST]: request,
    [Types.GET_POINTS_REQUESTS_SUCCESS]: success,
    [Types.GET_POINTS_REQUESTS_FAILURE]: failure,
    [Types.OPEN_MODAL]: processModalState(true),
    [Types.CLOSE_MODAL]: processModalState(false),
};

  export default createReducer(INITIAL_STATE, HANDLERS);