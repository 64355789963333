import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  error: null,
  data: {},
};


const { Types, Creators } = createActions(
    {
      updatePointsRequests: ['id', 'status', 'amount'],
      updatePointsRequestsRequest: [],
      updatePointsRequestsSuccess: ['data'],
      updatePointsRequestsFailure: ['error'],
    },
    { prefix: 'cb/pointsRequests/processPoints/' },
);

export { Types as types, Creators as actions };

// Reducers
const request = state => ({
  ...state,
  fetching: true,
  error: null,
});

const success = (state, action) => ({
  ...state,
  fetching: false,
  fetched: true,
  data: action.data,
});

const failure = (state, action) => ({
  ...state,
  fetching: false,
  fetched: false,
  error: action.error,
});



export const HANDLERS = {
    [Types.UPDATE_POINTS_REQUESTS_REQUEST]: request,
    [Types.UPDATE_POINTS_REQUESTS_SUCCESS]: success,
    [Types.UPDATE_POINTS_REQUESTS_FAILURE]: failure,
};

  export default createReducer(INITIAL_STATE, HANDLERS);