import { defineMessages } from 'react-intl';

export const scope = 'cb.components.ReferralCodeGenerator';

export default defineMessages({
  codeGeneratorModalTitle: {
    id: `${scope}.codeGeneratorModalTitle`,
    defaultMessage: 'Your referral code',
  },
  yourReferralCodeText: {
    id: `${scope}.yourReferralCodeText`,
    defaultMessage:
      'Your referral code is {code}. Would you like to change it?',
  },
  codeGenerationErrorText: {
    id: `${scope}.codeGenerationErrorText`,
    defaultMessage: 'Could not generate referral code: {error}',
  },
  confirmButtonText: {
    id: `${scope}.confirmButtonText`,
    defaultMessage: 'Continue Sign Up',
  },
  regenerateButtonText: {
    id: `${scope}.refenerateButtonText`,
    defaultMessage: 'Regenerate code',
  },
});
