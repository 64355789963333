import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import { CloseButton, Spinner } from 'components';
import { EMPTY_INPUT_VAlUE } from 'coupon-common';

import styles from './Input.module.scss';

const Input = ({
  valid,
  invalid,
  loading,
  clearable,
  closedSelect,
  hideCaret,
  containerClassName,
  readOnly,
  sideLabel,
  value,
  ...props
}) => {
  const styleNames = cx('main-input', {
    'hide-caret': hideCaret,
    'is-valid': valid,
    'is-closed-select': !readOnly && closedSelect,
    'is-clearable': !readOnly && clearable,
    'is-invalid': invalid,
    'read-only': readOnly,
    'checkbox-input': props.type === 'checkbox',
  });

  const clearValue = () => {
    const { onChange } = props;
    onChange({ target: { value: EMPTY_INPUT_VAlUE } });
  };

  return (
    <div
      className={cx('d-flex align-items-center', containerClassName)}
      styleName="main-input-container"
    >
      <input
        styleName={styleNames}
        readOnly={readOnly}
        value={value}
        {...props}
      />
      {!readOnly && !loading && clearable && (
        <div styleName="close-button-container">
          <CloseButton onClick={clearValue} />
        </div>
      )}
      {sideLabel && <span className="text-nowrap ml-2">{sideLabel}</span>}
      <Spinner show={loading} size="sm" containerClassName="ml-3" />
    </div>
  );
};

// default "" value because without this hack warning appears in console.
// it related to react-final-form formatOnBlur parameter bug: https://github.com/final-form/react-final-form/issues/529
Input.defaultProps = {
  value: EMPTY_INPUT_VAlUE,
};

Input.propTypes = {
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  loading: PropTypes.bool,
  closedSelect: PropTypes.bool,
  hideCaret: PropTypes.bool,
  containerClassName: PropTypes.string,
};

export default CSSModule(Input, styles, { allowMultiple: true });
