import { schema } from 'normalizr';
import { createActions, createReducer } from 'reduxsauce';

import {
  entityFetching,
  entityFetchingFailure,
  entityFetchingSuccess,
} from '../../utils/redux';

export const businessCategoriesEntity = new schema.Entity('businessCategories');

const INITIAL_STATE = {
  entities: {
    [businessCategoriesEntity.key]: {},
  },
  result: {
    [businessCategoriesEntity.key]: {
      fetching: false,
      fetched: false,
      results: [],
      error: null,
    },
  },
};

const { Types, Creators } = createActions(
  {
    getBusinessCategories: [],
    getBusinessCategoriesRequest: [],
    getBusinessCategoriesSuccess: ['data'],
    getBusinessCategoriesFailure: ['error'],

    getBusinessCategoriesNextPage: [],
    getBusinessCategoriesNextPageRequest: [],
    getBusinessCategoriesNextPageSuccess: ['data'],
    getBusinessCategoriesNextPageFailure: ['error'],
  },
  { prefix: 'cb/businessCategories/' },
);

export { Types as types, Creators as actions };

export const HANDLERS = {
  [Types.GET_BUSINESS_CATEGORIES_REQUEST]: entityFetching(
    businessCategoriesEntity,
  ),
  [Types.GET_BUSINESS_CATEGORIES_SUCCESS]: entityFetchingSuccess(
    businessCategoriesEntity,
  ),
  [Types.GET_BUSINESS_CATEGORIES_FAILURE]: entityFetchingFailure(
    businessCategoriesEntity,
  ),
  [Types.GET_BUSINESS_CATEGORIES_NEXT_PAGE_REQUEST]: entityFetching(
    businessCategoriesEntity,
  ),
  [Types.GET_BUSINESS_CATEGORIES_NEXT_PAGE_SUCCESS]: entityFetchingSuccess(
    businessCategoriesEntity,
    { attach: true },
  ),
  [Types.GET_BUSINESS_CATEGORIES_NEXT_PAGE_FAILURE]: entityFetchingFailure(
    businessCategoriesEntity,
  ),
};

export default createReducer(INITIAL_STATE, HANDLERS);
