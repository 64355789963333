import { combineReducers } from 'redux';
import { createReducer } from 'reduxsauce';

import * as login from './login';
import * as persist from './persist';
import * as refresh from './refresh';
import * as tokens from './tokens';

// For compatibility with old api.
export const actions = login.actions;

const authorized = createReducer(false, {
  [tokens.types.CLEAR_TOKENS]: () => false,
  [tokens.types.SET_TOKENS]: (_, { tokens }) => {
    if (tokens && tokens.access) {
      return true;
    }
    return false;
  },
});

export default combineReducers({
  login: login.reducer,
  persist: persist.reducer,
  refresh: refresh.reducer,
  tokens: tokens.reducer,
  authorized,
});
