import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Button, Feedback, PointsProgramField } from 'components';
import {
  BusinessCategoriesField,
  BusinessInfoFieldGroup,
  BusinessLogosFieldGroup,
  LocationFieldGroup,
  ReferralCodeGenerator,
  JoinLoyaltyPointsField,
} from 'containers';
import { actions as referralCodesActions } from 'coupon-common/src/modules/referralCodes';
import { actions as signupActions } from 'coupon-common/src/modules/signup';
import { dispatchAsAsync } from 'coupon-common/src/utils';

import messages from './messages';

const SignUpForm = ({
  onSubmitSuccess,
  referralFormRedirect,
  businessCode,
  code,
  referralInfo,
  dispatch,
}) => {
  useEffect(() => {
    dispatch(referralCodesActions.resetCodeGenerator());
  }, [dispatch]);

  if (!businessCode) {
    referralFormRedirect();
  }

  const onSubmit = async values => {
    if (!code) {
      dispatch(referralCodesActions.generateCode());
      dispatch(referralCodesActions.openCodeGenerator());
      return;
    }

    // if (values?.location?.type === 'Point') {
    //   const coordinates = values.location.coordinates;
    //   //Swapping lat and lon to save on db
    //   values.location.coordinates = [coordinates[1], coordinates[0]];
    // }
    const submitErrors = await dispatchAsAsync(
      signupActions.signUp(values),
      dispatch,
    );

    if (!submitErrors && onSubmitSuccess) {
      onSubmitSuccess();
    }

    return submitErrors;
  };

  const earnPoints =
    referralInfo.data && referralInfo.data.first_level_referral_points;

  return (
    <Form
      onSubmit={onSubmit}
      render={({ form, submitting, submitError, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <BusinessInfoFieldGroup />
          <JoinLoyaltyPointsField />
          <LocationFieldGroup />
          <BusinessLogosFieldGroup />
          <BusinessCategoriesField />
          <ReferralCodeGenerator onConfirm={() => form.submit()} />
          {/* <PointsProgramField earnPoints={earnPoints} /> */}
          <Feedback show={!!submitError} content={submitError} invalid />
          <div className="row justify-content-between mt-4 mb-4">
            <div className="col-xl-9 col-md-7 align-items-center">
              <FormattedMessage
                tagName="p"
                {...messages.agreementText}
                values={{
                  linkToBusinessTerms: msg => (
                    <a
                      href="/terms-of-service/business"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {msg}
                    </a>
                  ),
                  linkToPrivacyPolicy: msg => (
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {msg}
                    </a>
                  ),
                }}
              />
            </div>
            <div className="col-xl-3 col-md-5">
              <div className="d-flex justify-content-end">
                <Button fetching={submitting} type="submit">
                  <FormattedMessage {...messages.signUpButtonText} />
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

SignUpForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  referralFormRedirect: PropTypes.func,
  businessCode: PropTypes.string,
  code: PropTypes.string,
  referralInfo: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => ({
  businessCode: state.referralCodes.businessCode,
  code: state.referralCodes.codeGenerator.code,
  referralInfo: state.memberships.referralInfo,
});

export default connect(mapStateToProps)(SignUpForm);
