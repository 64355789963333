import React, { useState } from 'react';
import { AppWrapper, AppWrapperHeading } from 'components';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux'

import formatString from 'format-string-by-pattern';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Block, Button, FormField } from 'components';
import { AuthProtected, ChangePassword } from 'containers';
import { DEFAULT_PHONE_FIELD_MASK } from 'coupon-common';
import {
  composeSyncValidators,
  maxLength,
  required,
  validEmail,
  validPassword,
  validPhone,
} from 'coupon-common/src/utils';
import messages from './messages';


const AccountSettings = ({ intl }) => {

    const businessInfo = useSelector((state)=> state.businessInfo.data)


    const [changePassOpen, setChangePassOpen] = useState(false);

    const openChangePassModal = () => setChangePassOpen(true);
    const closeChangePassModal = () => setChangePassOpen(false);
    const onPasswordChange = () => closeChangePassModal();

  return (
        <Form
          onSubmit={()=>{}}
          initialValues={businessInfo}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <AppWrapper
            showHeader
            showSideNav
            showHeadingRow
          headingRowRender={() => (
            <>
            <div className='col-12 d-flex flex-column'>
              <AppWrapperHeading
                headingText={intl.formatMessage(messages.headingText)}
              />
              </div>
            </>
          )}
            >
            <AuthProtected/>
            <form onSubmit={handleSubmit} className='col-md-6'>
              <Block>
              <Field
              name="email"
              label={intl.formatMessage(messages.emailLabelText)}
              type="email"
              autoComplete="off"
              validate={composeSyncValidators(required, validEmail())}
              readOnly
              component={FormField}
            />
            <FormField
              name="password"
              label={intl.formatMessage(messages.passwordLabelText)}
              type="password"
              autoComplete="new-password"
              input={{ name: 'password', value: '************' }}
              readOnly
              meta={{}}
            />
            <div className="row justify-content-center mb-2">
              <Button className='w-100' outline onClick={openChangePassModal}>
                <FormattedMessage {...messages.changePasswordButtonText} />
              </Button>
            </div>
            <ChangePassword
              isOpen={changePassOpen}
              onRequestClose={closeChangePassModal}
              onChangeSuccess={onPasswordChange}
            />
              </Block>
            </form>
            </AppWrapper>
          )}
        />
  )
}

AccountSettings.propTypes = {
    intl: PropTypes.object.isRequired,
  };

export default injectIntl(AccountSettings)
