import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: {},
  error: null,
};

const { Types, Creators } = createActions(
  {
    changePassword: ['oldPass', 'newPass', 'resolve'],
    changePasswordRequest: [],
    changePasswordSuccess: ['data'],
    changePasswordFailure: ['error'],
    recoverPassword: ['email', 'resolve'],
    recoverPasswordRequest: [],
    recoverPasswordSuccess: ['data'],
    recoverPasswordFailure: ['error'],
    resetPassword: ['uid', 'token', 'newPassword', 'resolve'],
    resetPasswordRequest: [],
    resetPasswordSuccess: ['data'],
    resetPasswordFailure: ['error'],
  },
  { prefix: 'cb/password/' },
);

export { Types as types, Creators as actions };

export const request = () => {
  return {
    ...INITIAL_STATE,
    fetching: true,
  };
};

export const success = (_, action) => {
  const { data } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    data,
  };
};

export const failure = (_, action) => {
  const { error } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    error,
  };
};

export const HANDLERS = {
  [Types.CHANGE_PASSWORD_REQUEST]: request,
  [Types.CHANGE_PASSWORD_SUCCESS]: success,
  [Types.CHANGE_PASSWORD_FAILURE]: failure,
  [Types.RECOVER_PASSWORD_REQUEST]: request,
  [Types.RECOVER_PASSWORD_SUCCESS]: success,
  [Types.RECOVER_PASSWORD_FAILURE]: failure,
  [Types.RESET_PASSWORD_REQUEST]: request,
  [Types.RESET_PASSWORD_SUCCESS]: success,
  [Types.RESET_PASSWORD_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
