import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  BYTES_IN_MB,
  DECIMAL_FIELD_REGEX,
  DEFAULT_CHECK_EMAIL_REGEX,
  DEFAULT_CHECK_PASSWORD_REGEX,
  DEFAULT_CVC_LENGTH,
  DEFAULT_MAX_PASSWORD_LENGTH,
  DEFAULT_MIN_PASSWORD_LENGTH,
  DEFAULT_PHONE_FIELD_MASK,
  DEFAULT_TEXT_FIELD_LENGTH,
  NUMBER_OF_MONTH_IN_YEAR,
  SPACE_REGEX,
  VALID_CARD_REGEX,
} from '../../constants';
import { parseCardMonthYear } from '../formatters';
import messages from './messages';

/* Validators Localization */
const {
  requiredMessage,
  minLengthMessage,
  maxLengthMessage,
  invalidEmailMessage,
  invalidPhoneMessage,
  invalidPassowrdMessage,
  invalidImageMinSizeMessage,
  invalidImageMaxSizeMessage,
  invalidImageFormatMessage,
  loadingErrorText,
  numericValidationText,
  positiveValidationText,
  nonNegativeValidationText,
  maxNumericValueValidationText,
} = messages;

export const composeSyncValidators =
  (...validators) =>
  value =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );

export const required = value =>
  value
    ? undefined
    : React.createElement(FormattedMessage, { ...requiredMessage });

export const isPositive = value => {
  if (!value) {
    return undefined;
  }
  const convertedValue = Number(value);
  if (convertedValue <= 0) {
    return React.createElement(FormattedMessage, {
      ...positiveValidationText,
    });
  }
  return undefined;
};

export const isNonNegative = value => {
  if (!value) {
    return undefined;
  }
  if (Number(value) < 0) {
    return React.createElement(FormattedMessage, {
      ...nonNegativeValidationText,
    });
  }
  return undefined;
};

export const isInteger = value => {
  if (!value) {
    return undefined;
  }
  const convertedValue = Number(value);
  if (Number.isInteger(convertedValue)) {
    return undefined;
  }
  return React.createElement(FormattedMessage, { ...numericValidationText });
};

export const isDecimal = value => {
  if (!value) {
    return undefined;
  }
  if (DECIMAL_FIELD_REGEX.test(value)) {
    return undefined;
  }
  return React.createElement(FormattedMessage, { ...numericValidationText });
};

export const requiredAsArray = value => required(value && value.length > 0);

export const minLength = minLength => value =>
  value.length >= minLength
    ? undefined
    : React.createElement(FormattedMessage, {
        ...minLengthMessage,
        values: minLength,
      });

export const maxLength =
  (maxLength = DEFAULT_TEXT_FIELD_LENGTH) =>
  value => {
    const length = value ? value.length : 0;
    return length <= maxLength
      ? undefined
      : React.createElement(FormattedMessage, {
          ...maxLengthMessage,
          values: { maxLength },
        });
  };

export const validEmail =
  (re = DEFAULT_CHECK_EMAIL_REGEX) =>
  value =>
    re.test(value.toLowerCase())
      ? undefined
      : React.createElement(FormattedMessage, { ...invalidEmailMessage });

export const validPhone =
  (mask = DEFAULT_PHONE_FIELD_MASK) =>
  value =>
    value.length === DEFAULT_PHONE_FIELD_MASK.length
      ? undefined
      : React.createElement(FormattedMessage, {
          ...invalidPhoneMessage,
          values: { mask },
        });

export const validPassword =
  (
    minLengthVal = DEFAULT_MIN_PASSWORD_LENGTH,
    maxLengthVal = DEFAULT_MAX_PASSWORD_LENGTH,
    customErrorMsgEl,
  ) =>
  value => {
    const minLengthValidator = minLength(minLengthVal);
    const maxLengthValidator = maxLength(maxLengthVal);
    const re = DEFAULT_CHECK_PASSWORD_REGEX;
    if (
      !minLengthValidator(value) &&
      !maxLengthValidator(value) &&
      re.test(value)
    ) {
      return undefined;
    }
    return (
      customErrorMsgEl ||
      React.createElement(FormattedMessage, {
        ...invalidPassowrdMessage,
        values: { minLengthVal, maxLengthVal },
      })
    );
  };

export const validImage = (minSize, maxSize, acceptedFormatsArray) => value => {
  /**
   * Value that received from backend is string and its valid.
   * I don't like this behaviour, but it will work for now.
   */
  if (typeof value === 'string') {
    return undefined;
  }
  if (minSize && minSize > value.size) {
    return React.createElement(FormattedMessage, {
      ...invalidImageMinSizeMessage,
      values: { minSizeMB: minSize / BYTES_IN_MB },
    });
  }
  if (maxSize && maxSize < value.size) {
    return React.createElement(FormattedMessage, {
      ...invalidImageMaxSizeMessage,
      values: { maxSizeMB: maxSize / BYTES_IN_MB },
    });
  }
  if (acceptedFormatsArray && !acceptedFormatsArray.includes(value.type)) {
    return React.createElement(FormattedMessage, {
      ...invalidImageFormatMessage,
      values: {
        acceptedFormatsLength: acceptedFormatsArray.length,
        acceptedFormatsString: acceptedFormatsArray.join(', '),
      },
    });
  }
  return undefined;
};

export const stringMatchWithValue =
  (valueForComparision, errorMessage) => currValue => {
    if (valueForComparision !== currValue) {
      return React.createElement(FormattedMessage, { ...errorMessage });
    }
    return undefined;
  };

/* Load error message constructor */
export const getFormattedLoadingError = (intl, error, entityMessage) => {
  if (error) {
    return intl.formatMessage(loadingErrorText, {
      entity: intl.formatMessage(entityMessage),
      error,
    });
  }
};

export const atLeastOneSelected = errorMessage => values =>
  values && values.length > 0
    ? undefined
    : React.createElement(FormattedMessage, { ...errorMessage });

export const validateCardNumber =
  (
    errorMessage,
    cardNumberRegex = VALID_CARD_REGEX,
    replacerRegex = SPACE_REGEX,
  ) =>
  value =>
    value && cardNumberRegex.test(value.replace(replacerRegex, ''))
      ? undefined
      : React.createElement(FormattedMessage, { ...errorMessage });

export const validateCardCVC =
  (errorMessage, cvcLength = DEFAULT_CVC_LENGTH) =>
  value => {
    return !Number(value).isNaN && String(value).length === cvcLength
      ? undefined
      : React.createElement(FormattedMessage, { ...errorMessage });
  };

export const validateCardMonthYear = errorMessage => value => {
  const error = React.createElement(FormattedMessage, { ...errorMessage });

  if (!value) return error;

  const [expMonth, expYear] = parseCardMonthYear(value);

  if (expMonth > NUMBER_OF_MONTH_IN_YEAR) return error;

  const today = new Date();
  const someday = new Date().setFullYear(expYear, expMonth, 1);

  return someday < today ? error : undefined;
};

export const maxValue =
  (limit = 100) =>
  value => {
    if (!value) return undefined;
    if (value > limit)
      return React.createElement(FormattedMessage, {
        ...maxNumericValueValidationText,
        values: { limit },
      });
    return undefined;
  };
