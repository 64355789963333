import { OK } from 'http-status-codes';
import { put, select, takeLatest } from 'redux-saga/effects';

import * as api from './api';
import { actions, types } from './duck';

export function* getNewUsedCoupons() {
  try {
    yield put(actions.getNewUsedCouponsRequest());

    const createdAfter = yield select(
      state => state.usedCoupons.newUsedCoupons.createdAfter,
    );

    const response = yield api.getNewUsedCoupons(createdAfter);

    if (response.status === OK) {
      yield put(actions.getNewUsedCouponsSuccess(response.data));
      if (response.data.new_used_coupon_to_show) {
        yield put(actions.openModal());
      }
    } else {
      yield put(actions.getNewUsedCouponsFailure(response.data.detail));
    }
  } catch (error) {
    yield put(actions.getNewUsedCouponsFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_NEW_USED_COUPONS, getNewUsedCoupons);
}
