import { authApiCall } from "../../../auth";

export const getAllUsers = () => {
  return authApiCall({
    url: `/users/admin/all-users/`,
    method: 'get',
  });
};

export const updatePoints = (to_user_id, amount, notes) => {
  return authApiCall({
    url: `/users/admin/update-points/${to_user_id}/`,
    method: 'put',
    data: {amount, notes}
  });
};
