import { combineReducers } from 'redux';

import { reducer as pointsActivityReducer } from './pointsActivity';
import {reducer as processPointsRequestReducer} from './processPointsRequest'
import {reducer as updatePointsReducer} from './updatePoints'

export default combineReducers({
  pointsActivity: pointsActivityReducer,
  processPointsRequest: processPointsRequestReducer,
  updatePoints: updatePointsReducer
});
