import { CREATED } from 'http-status-codes';
import { put, takeLatest } from 'redux-saga/effects';

import { safeResolveAsync } from '../../../utils';

import * as api from './api';
import { actions, types } from './duck';

export function* upgradeMembershipPlan({ planId, resolve }) {
  try {
    yield put(actions.upgradeMembershipPlanRequest());

    const response = yield api.upgradeMembershipPlan(planId);

    if (response.status === CREATED) {
      yield put(actions.upgradeMembershipPlanSuccess(response.data));
      yield safeResolveAsync(resolve, { success: true });
    } else {
      throw Error(response.data.detail);
    }
  } catch (error) {
    yield put(actions.upgradeMembershipPlanFailure(error.message));
    yield safeResolveAsync(resolve, { success: false });
  }
}

export default function* saga() {
  yield takeLatest(types.UPGRADE_MEMBERSHIP_PLAN, upgradeMembershipPlan);
}
