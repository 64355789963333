import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { FormCustomSelect } from 'components';
import { actions, duck } from 'coupon-common/src/modules/businessCategories';
import { getNormalizedEntity, required } from 'coupon-common/src/utils';

import messages from './messages';

export const CouponCategoryField = ({ intl, dispatch, businessCategories }) => {
  useEffect(() => {
    dispatch(actions.getBusinessCategories());
  }, [dispatch]);

  const businessCategoriesKey = duck.businessCategoriesEntity.key;

  const {
    entityList: businessCategoriesOptions,
    isFetching: businessCategoriesFetching,
    error: businessCategoriesLoadingError,
    /**
     * Pagination waiting for large categories list:
     * isNextPageExist: businessCategoriesNextPageExist,
     */
  } = getNormalizedEntity(
    businessCategories,
    businessCategoriesKey,
    intl,
    messages.entityCouponCategories,
  );

  return (
    <Field
      name="category"
      label={intl.formatMessage(messages.categoryFieldLabel)}
      placeholder={intl.formatMessage(messages.categoryFieldPlaceholder)}
      validate={required}
      component={FormCustomSelect}
      fetching={businessCategoriesFetching}
      options={businessCategoriesOptions}
      externalError={businessCategoriesLoadingError}
    />
  );
};

CouponCategoryField.propTypes = {
  intl: PropTypes.object,
  dispatch: PropTypes.func,
  businessCategories: PropTypes.object,
};

const mapStateToProps = state => ({
  businessCategories: state.businessCategories,
});

export default connect(mapStateToProps)(injectIntl(CouponCategoryField));
