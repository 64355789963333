import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Higher Order Component acting as a middleware to check for admin authorization to specific routes
const withAdminAuth = (WrappedComponent) => {
  const AdminCheck = ({business, ...props }) => {

    // info@mycouponbook.ca
    // Check if the user is an admin
    if (business?.email !== "info@mycouponbook.ca") {
      // If user is not an admin, redirect to an Unauthorized page
      return <Redirect to="/login" />;
    }

    // If the user is an admin, render the wrapped component
    return <WrappedComponent {...props} />;
  };

  // Map the user's role from Redux or state
  const mapStateToProps = (state) => ({
    business: state.businessInfo.data,
  });

  return connect(mapStateToProps)(AdminCheck);
};

export default withAdminAuth;
