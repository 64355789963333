import 'theme/global-styles.scss';

import React from 'react';
import ReactDOM from 'react-dom';

import { ConnectedRouter } from 'connected-react-router';
import FontFaceObserver from 'fontfaceobserver';
import { Provider } from 'react-redux';

import { App } from 'containers';
import { createStoreWeb } from 'coupon-common';

import history from './history';
import { translationMessages } from './i18n';

const dest = document.getElementById('root');
const store = createStoreWeb({}, history);
const notoSansObserver = new FontFaceObserver('Noto Sans', {});

// When Nato Sans is loaded, add a font-family using Nato Sans to the body
notoSansObserver.load().then(() => {
  document.body.classList.add('fonts--loaded');
});

function render(messages) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App messages={messages} />
      </ConnectedRouter>
    </Provider>,
    dest,
  );
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', './containers/App/App'], () => {
    ReactDOM.unmountComponentAtNode(dest);
    render(translationMessages);
  });
}

// IntersectionObserver polyfill
// https://github.com/w3c/IntersectionObserver
if (!window.IntersectionObserver) {
  require('intersection-observer');
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/de.js'),
      ]),
    )
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}
