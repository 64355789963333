import { COUPON_STATES } from '../../constants';
import { authApiCall } from '../auth/saga';

export const getBusinessCoupons = params =>
  authApiCall({
    url: '/coupons/',
    method: 'get',
    params,
  });

export const publishCoupon = id =>
  authApiCall({
    url: `/coupons/${id}/publish/`,
    method: 'post',
  });

export const deleteCoupon = id =>
  authApiCall({
    url: `/coupons/${id}/`,
    method: 'delete',
  });

export const sponsorCoupon = id =>
  authApiCall({
    url: `/coupons/${id}/sponsor/`,
    method: 'post',
  });

  export const getViewCounts = coupon_ids =>
    authApiCall({
      url: `/coupons/get-view-counts/`,
      method: 'post',
      data:  coupon_ids,
    });
  

export const republishCoupon = data => {
  const coupon_images = Array.isArray(data.image_url)
    ? data.image_url.reduce((a, c) => {
        a.push(c.id);
        return a;
      }, [])
    : [];

  const formattedData = {
    ...data,
    ...(data.category ? { category: data.category.id } : {}),
    state: COUPON_STATES.PUBLISHED,
    coupon_images,
  };
  return authApiCall({
    url: `/coupons/`,
    method: 'post',
    data: formattedData,
  });
};
