import 'react-datepicker/dist/react-datepicker.css';
import './FormDatePicker.module.scss';

import React from 'react';

import DatePicker from 'react-datepicker';

import { FormFieldWrapper } from 'components';

export const FormDatePicker = ({ label, locale, input, meta, readOnly }) => {
  const shouldDisplayError = !!(
    (meta.error || meta.submitError) &&
    meta.touched
  );

  return (
    <FormFieldWrapper
      invalid={shouldDisplayError}
      label={label}
      error={meta.error || meta.submitError}
    >
      <DatePicker
        selected={input.value}
        onChange={date => input.onChange(date)}
        minDate={new Date()}
        className="custom-react-datepicker"
        locale={locale}
        onCalendarOpen={input.onFocus}
        onCalendarClose={input.onBlur}
        readOnly={readOnly}
      />
    </FormFieldWrapper>
  );
};

export default FormDatePicker;
