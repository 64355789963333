import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.SignUpForm';

export default defineMessages({
  formHeading: {
    id: `${scope}.formHeading`,
    defaultMessage: 'Sign Up',
  },
  agreementText: {
    id: `${scope}.agreementText`,
    defaultMessage:
      'By signing up, you agree to our <linkToBusinessTerms>Terms of Service</linkToBusinessTerms> and <linkToPrivacyPolicy>Privacy Policy</linkToPrivacyPolicy>.',
  },
  signUpButtonText: {
    id: `${scope}.signUpButtonText`,
    defaultMessage: 'Sign Up',
  },
});
