import { AppWrapper, Block, Spinner } from 'components'
import AuthProtected from 'containers/AuthProtected/AuthProtected'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import styles from './PointsTransactions.module.scss'
import CSSModule from 'react-css-modules'
import BootstrapTable from 'react-bootstrap-table-next'
import messages from './messages'
import paginationFactory from 'react-bootstrap-table2-paginator';
import Breadcrumbs from 'components/BreadCrumbs/BreadCrumbs'
import { actions as pointsTransactionsActions } from 'coupon-common/src/modules/admin/pointsManager/pointsTransactions';
import { actions as couponPointsTransactionActions } from 'coupon-common/src/modules/admin/pointsManager/couponPointsTransactions';
import { actions as pointsBalancesActions } from 'coupon-common/src/modules/admin/pointsManager/pointsBalances';
import { connect,useSelector   } from 'react-redux'


const PointsTransactions = ({ intl, dispatch, transactionsData, transactionsDataFetched, couponsData, couponsDataFetched, balancesData, balancesDataFetched }) => {

    const [searchQuery, setSearchQuery] = useState(null);
    const [data, setData] = useState(null)
    const [couponData, setCouponData] = useState(null);
    const [combinedData, setCombinedData] = useState([]);
    const [balances, setBalances] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(pointsTransactionsActions.getPointsTransactions())
        dispatch(couponPointsTransactionActions.getCouponTransactions({}));
        dispatch(pointsBalancesActions.getPointsBalances())
    }, [dispatch])



    useEffect(() => {
        if(transactionsDataFetched){
            const transactions = transactionsData?.map((tx)=> ({...tx, id: `P${String(tx.id).padStart(4, '0')}`}))
            setData(transactions)
        }
    }, [transactionsData, transactionsDataFetched])

    useEffect(() => {
        if(balancesDataFetched){
            // console.log("PW BALANCES ARE " + JSON.stringify(balancesData));
            // console.log("PW TRANSACTIONS ARE " + JSON.stringify(transactionsData));
            setBalances(balancesData)
        }
    }, [balancesData, balancesDataFetched])



    useEffect(() => {
        if(couponsDataFetched){
            // console.log("PW COUPONS ARE " + JSON.stringify(couponsData));
            // ✅ Transform `couponPointsTransactions` to match `pointsTransactions`
            const mappedCouponTransactions = couponsData?.results?.map(tx => ({
                id: `C${String(tx.id).padStart(4, '0')}`,
                time: tx.transaction_datetime,
                amount: tx.points_delta, // Ensure it's a number
                from_user:  tx.from_email && tx.from_email.trim() !== "" ? tx.from_email : "ADMIN", // Use "ADMIN" if no from email
                to_user: tx.to_email,
                status: "success", // Assume success
                notes: tx.transaction_type,
                previous_balance: null, // No equivalent field
                new_balance: null // No equivalent field
            }));
            setCouponData(mappedCouponTransactions);
        }
    }, [couponsData, couponsDataFetched])


    const fillMissingBalances = (transactions) => {
        // Sort transactions from oldest to newest (to work backwards)
        transactions.sort((a, b) => new Date(a.time) - new Date(b.time));

        // Iterate **backwards** (from most recent to oldest)
        for (let i = transactions.length - 1; i >= 0; i--) {
            let transaction = transactions[i];
            const { to_user, amount, previous_balance, new_balance } = transaction;

            // If both balances are missing, we need to fill them
            if (previous_balance === null || new_balance === null) {
                // 🔹 Step 1: Get the newest known balance (from the next transaction in the list)
                let newerTransaction = transactions[i + 1]; // This is the next newer transaction

                if (newerTransaction && newerTransaction.previous_balance !== null && newerTransaction.to_user === to_user) {
                    // Use the newer transaction's previous balance
                    transaction.new_balance = newerTransaction.previous_balance;
                    transaction.previous_balance = Number((transaction.new_balance - amount).toFixed(2));
                } else {
                    // 🔹 Step 2: If no newer transaction exists, use last balance from balancesData
                    const lastBalance = balancesData.find(item => item.email === to_user)?.points_at_last_date || 0;

                    // console.log("Using last recorded balance:", lastBalance);
                    transaction.new_balance = lastBalance;
                    transaction.previous_balance = Number((lastBalance - amount).toFixed(2));
                }
            }
        }

        // Finally, reverse it back to **newest to oldest** order for correct display
        return transactions.sort((a, b) => new Date(b.time) - new Date(a.time));
    };




    // This grabs the points transactions related to coupons that give back coupons
    // or points from referrals. They are pulled from the referral_program_pointstransactions
    useEffect(() => {
        if (data && couponData && balancesDataFetched) {
            const mergedData = [...data, ...couponData];
            mergedData.sort((a, b) => new Date(b.time) - new Date(a.time));
            const updatedTransactions = fillMissingBalances(mergedData);
            setCombinedData(updatedTransactions);
            setLoading(false)
        }
    }, [data, couponData, balancesDataFetched]);


    const columns = [
        {
            dataField: 'id',
            text: intl.formatMessage(messages.idColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'time',
            text: intl.formatMessage(messages.timeColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
            formatter: (cellContent) => {
                // Convert the string into a Date object and format it
                const formattedDate = new Date(cellContent).toLocaleString();
                return formattedDate || cellContent;
            },
        },
        {
            dataField: 'from_user',
            text: intl.formatMessage(messages.fromUserColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'to_user',
            text: intl.formatMessage(messages.toUserColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'previous_balance',
            text: intl.formatMessage(messages.previousBalanceColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'amount',
            text: intl.formatMessage(messages.amountColumnText),
            sort: true,
            classes: (cell, row, rowIndex, columnIndex, extendedValue) => {
                return row?.amount > 0 ? styles.positive : styles.negative;
            },
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'new_balance',
            text: intl.formatMessage(messages.newBalanceColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },

        {
            dataField: 'status',
            text: intl.formatMessage(messages.statusColumnText),
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'notes',
            text: intl.formatMessage(messages.notesColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
    ];

    const paginationOptions = {
        sizePerPage: 25,
        totalSize: combinedData?.length,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        firstPageText: 'First',
        lastPageText: 'Last',
        nextPageText: 'Next',
        prePageText: 'Previous',
        withFirstAndLast: true,
    };

    const handleSearch = () => {
        const result = data.filter((user) => user.from_user.includes(searchQuery) || user.to_user.includes(searchQuery));
        setData(result);
    }

    useEffect(() => {
        if (searchQuery) {
            handleSearch()
        }
        else if(searchQuery==""){
            setData(transactionsData)
        }
    }, [searchQuery])


    return (
        <AppWrapper
            showHeader
            showSideNav
            showHeadingRow
            headingText={intl.formatMessage(messages.pageHeadingText)}
        >
            <AuthProtected />

            <div className="col-md-12">
                <Breadcrumbs locationArray={["Admin", "Points Manager", "Points Transactions"]} />
                <Block>
                    {(loading == true) ? (
                        <Spinner show />
                    ) : (
                        <>
                        <div className='container'>
                            <div className="row justify-content-end align-items-center mb-2 p-1">
                                <div styleName='search'>
                                    <input type="text" placeholder='Search by email..' onChange={(e) => setSearchQuery(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div styleName='table-container' className="table-container overflow-auto">
                            <BootstrapTable
                                classes="table-borderless mb-0 overflow-auto custom-react-bootstrap-table"
                                striped
                                bordered={false}
                                bootstrap4
                                keyField="id"
                                data={combinedData}
                                columns={columns}
                                pagination={paginationFactory(paginationOptions)}
                            />

                        </div>
                    </>
                    )}
                </Block>
            </div>



        </AppWrapper>
    )
}

const mapStateToProps = (state) => {
    return {
        authorized: state.auth.authorized,
        transactionsData: state.admin.pointsManager.pointsTransactions.data,
        transactionsDataFetched: state.admin.pointsManager.pointsTransactions.fetched,
        couponsData: state.admin.pointsManager.couponPointsTransactions.data,
        couponsDataFetched: state.admin.pointsManager.couponPointsTransactions.fetched,
        balancesData: state.admin.pointsManager.pointsBalances.data,
        balancesDataFetched: state.admin.pointsManager.pointsBalances.fetched,
    };
};

export default connect(mapStateToProps)(injectIntl(CSSModule(PointsTransactions, styles)));
