import { call, put, takeLatest } from 'redux-saga/effects';
import { actions, types } from './duck';
import { getPointsTransactions } from './api';
import { OK } from 'http-status-codes';

function* getPointsTransactionsSaga() {
  try {
    yield put(actions.getPointsTransactionsRequest());

    const response = yield call(getPointsTransactions);
    if (response.status === OK) {
      yield put(actions.getPointsTransactionsSuccess(response.data));
    } else {
      yield put(actions.getPointsTransactionssFailure(response.statusText));
    }
  } catch (error) {
    yield put(actions.getPointsTransactionsFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_POINTS_TRANSACTIONS, getPointsTransactionsSaga);
}
