import { AppWrapper, Block, Button} from 'components';
import AuthProtected from 'containers/AuthProtected/AuthProtected';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './EditUser.module.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import CSSModules from 'react-css-modules';
import Breadcrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { Form } from 'react-final-form';
import { actions as membershipActions } from 'coupon-common/src/modules/memberships/plans'
import { actions as userManagerActions } from 'coupon-common/src/modules/admin/userManager'
import AdminBusinessUserForm from 'components/AdminBusinessUserForm/AdminBusinessUserForm';
import AdminCustomerUserForm from 'components/AdminCustomerUserForm/AdminCustomerUserForm';


const EditUser = ({ dispatch, usersData, businessCategories, membership_plans, cities, updateUserData, updateUserError }) => {

  const { id } = useParams();
  const user = usersData?.find(user => user?.id === Number(id));

  const [isEditing, setIsEditing] = useState(false);
  const [allCategories, setAllCategories] = useState([])
  const [updatedUser, setUpdatedUser] = useState(user)
  const [apiCall, setApiCall] = useState(false)
  const [submittedUserData, setSubmittedUserData] = useState(null)


  useEffect(() => {
    dispatch(membershipActions.getMembershipPlans());
    transformUserData();
  }, [])

  useEffect(()=>{

      if(apiCall){
        if(updateUserData?.success){
          setUpdatedUser(submittedUserData)
          alert("User information has been updated successfully.")
          setApiCall(false)
        }
        else if(updateUserError){
          alert("There was an error updating the user information.")
          setApiCall(false)
        }
      }

  }, [updateUserData, updateUserError])

  const transformUserData = () => {

    const parseDate = (dateString) => {
      if (!dateString) return null;
      const parsedDate = new Date(dateString).toISOString().slice(0, 16);
      return parsedDate;
    };

    setUpdatedUser(prevUser => ({
      ...prevUser,
      signed_at: parseDate(user?.signed_at),
      wallet: { ...prevUser.wallet, points_recalculation_date: parseDate(user?.wallet?.points_recalculation_date) },
      membership: {
        ...prevUser.membership,
        ...(prevUser.membership?.created_at !== undefined ? { created_at: parseDate(user?.membership?.created_at) } : {}),
        date_start: parseDate(user?.membership?.date_start),
        expiration_date: parseDate(user?.membership?.expiration_date)
      }
    }));

    filterCategories();

  }

  const filterCategories = () => {
    const userCategoryIds = new Set(user?.categories);
    const updatedUserCategories = [];
    const updatedAllCategories = [];

    for (const key in businessCategories?.entities?.businessCategories) {
      const category = businessCategories.entities.businessCategories[key];

      updatedAllCategories.push(category);

      if (userCategoryIds.has(Number(key))) {
        updatedUserCategories.push(category);
      }
    }

    setAllCategories(updatedAllCategories);
    setUpdatedUser((prevUser) => ({
      ...prevUser,
      categories: updatedUserCategories
    }))

  }


  const toggleEdit = () => {
    setIsEditing(true);
  };

  const cancelEdit = (form) => {
    form.reset();
    setIsEditing(false);
  };

  const handleSave = (event, submitCallback) => {
    event.preventDefault()
    const confirmSave = window.confirm("Are you sure you want to save the changes ?")
    if (confirmSave) {
      setIsEditing(false);
      submitCallback()
    }
  }


  if (!user) {
    return <p>User not found</p>;
  }

  return (
    <AppWrapper showHeader showSideNav showHeadingRow headingText="Edit User">
      <AuthProtected />
      <Block className={"w-100"}>
        <Breadcrumbs locationArray={["Admin", "User Manager", "Edit"]} />
        <Form
          initialValues={updatedUser}
          onSubmit={(values, form) => {
            setSubmittedUserData(values)
            const initialValues = updatedUser; // The original user data
            const changedFields = {};

            const findChanges = (newData, oldData) => {
              if (typeof newData === 'object' && newData !== null) {
                Object.keys(newData).forEach((key) => {
                  if (typeof newData[key] === 'object' && newData[key] !== null) {

                    if (key === "categories") {
                      if(newData[key] !== oldData[key]){
                        changedFields[key] = newData[key]?.map((category)=> category.id)
                        return
                      }
                    }
                    // Nested objects
                    Object.keys(newData[key]).forEach((innerKey) => {
                      if (newData[key][innerKey] !== oldData[key]?.[innerKey]) {
                        changedFields[key] = { ...changedFields[key], [innerKey]: newData[key][innerKey] };
                      }
                    });
                  }
                  else {
                    if (newData[key] !== oldData[key]) {
                      changedFields[key] = newData[key]
                    }

                  }
                });
              }
            };

            findChanges(values, initialValues);


            if (Object.keys(changedFields).length > 0) {
              if (updatedUser?.is_business) {
                dispatch(userManagerActions.updateBusinessUser(updatedUser.id, changedFields));
              } else {
                dispatch(userManagerActions.updateCustomerUser(updatedUser.id, changedFields));
              }
              setApiCall(true)
            }
          }}
          render={({ handleSubmit, form }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div styleName="buttonGroup">
                  {isEditing && (
                    <Button
                      small
                      outline
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        cancelEdit(form); // Pass the form instance
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {isEditing ? (
                    <Button small primary type="button" onClick={(e) => handleSave(e, handleSubmit)}>
                      Save
                    </Button>
                  ) : (
                    <Button small primary type="button" onClick={toggleEdit}>
                      Edit
                    </Button>
                  )}

                </div>
                {user?.is_business ? (
                  <AdminBusinessUserForm styles={styles} membership_plans={membership_plans} isEditing={isEditing} allCategories={allCategories} cities={cities} />
                ) : (
                  <AdminCustomerUserForm styles={styles} isEditing={isEditing} allCategories={allCategories} cities={cities} />
                )}
              </form>
            )
          }}
        />
      </Block>
    </AppWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    usersData: state.admin.pointsManager.managePoints.getUsers.data,
    updateUserData: state.admin.userManager.data,
    updateUserError: state.admin.userManager.error,
    businessCategories: state.businessCategories,
    membership_plans: state.memberships.plans.data,
    cities: state.locations.entities.cities
  };
};

export default connect(mapStateToProps)(injectIntl(CSSModules(EditUser, styles)));