import { defineMessages } from 'react-intl';

const scope = 'cb.containers.AdminPanel.PointsManager';

export default defineMessages({
    pageHeadingText: {
        id: `${scope}.pageHeadingText`,
        defaultMessage: 'Points Manager',
    },
    managePointsColumnText: {
        id: `${scope}.managePointsColumnText`,
        defaultMessage: 'Manage Points',
    },
    pointsTransactionsColumnText: {
        id: `${scope}.pointsTransactionsColumnText`,
        defaultMessage: 'Points Transactions',
    },
});
