import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage, injectIntl } from 'react-intl';

import { DEFAULT_INTL_FORMAT_NUMBER_CONFIG } from 'coupon-common';
import { roundNumber, sumValues } from 'coupon-common/src/utils';

import styles from './CouponPriceDetails.module.scss';
import messages from './messages';

const TAX_TO_LABEL_TRANSLATION_MAP = {
  gst: messages.gstText,
  pst: messages.pstText,
  qst: messages.qstText,
  rst: messages.rstText,
  hst: messages.hstText,
};

export const CouponPriceDetails = ({ intl, data }) => {
  const amount_off = useMemo(() => {
    let amount_off = 0;
    if (data.price && +data.price > 0) {
      if (data.type === 'amount_off' && data.amount_off && +data.amount_off > 0)
        amount_off = +data.amount_off;

      if (
        data.type === 'percentage_off' &&
        data.percentage_off &&
        +data.percentage_off > 0
      )
        amount_off = (+data.price * +data.percentage_off) / 100;
    }
    return roundNumber(amount_off);
  }, [data.price, data.type, data.amount_off, data.percentage_off]);

  const totalCost = sumValues([
    data.price,
    data.gst,
    data.pst,
    data.qst,
    data.rst,
    data.hst,
    -amount_off,
  ]);

  const renderTaxes = () =>
    Object.keys(TAX_TO_LABEL_TRANSLATION_MAP).map(
      key =>
        data[key] && (
          <p
            key={key}
            className="mr-3 text-uppercase"
            styleName="price-related-value"
          >
            <FormattedMessage
              {...TAX_TO_LABEL_TRANSLATION_MAP[key]}
              values={{
                taxValue: intl.formatNumber(
                  data[key],
                  DEFAULT_INTL_FORMAT_NUMBER_CONFIG,
                ),
              }}
            />
          </p>
        ),
    );

  return (
    <div className="d-inline-block">
      <p className="mb-1 mr-3" styleName="price">
        {data.price &&
          intl.formatNumber(data.price, DEFAULT_INTL_FORMAT_NUMBER_CONFIG)}
      </p>
      {amount_off > 0 && (
        <p className="mr-3 text-uppercase" styleName="price-related-value">
          {/* <FormattedMessage {...messages.amountOffText} values={{amountOff: intl.formatNumber(amount_off,DEFAULT_INTL_FORMAT_NUMBER_CONFIG,),}} /> */}
          <FormattedMessage
            {...messages.discountText}
            values={{
              discount: intl.formatNumber(
                amount_off,
                DEFAULT_INTL_FORMAT_NUMBER_CONFIG,
              ),
            }}
          />
        </p>
      )}
      {renderTaxes()}
      <hr className="mt-2" />
      <p className="mb-1 mr-3" styleName="price">
        {intl.formatNumber(totalCost, DEFAULT_INTL_FORMAT_NUMBER_CONFIG)}
      </p>
      {data.payment_by_points && (
        <p className="mr-3" styleName="price-related-value">
          <FormattedMessage
            {...messages.pointsText}
            values={{ points: totalCost * process.env.REACT_APP_POINTS_EXCHANGE_RATE}}
          />
        </p>
      )}
    </div>
  );
};

CouponPriceDetails.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
};

export default injectIntl(CSSModule(CouponPriceDetails, styles));
