import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.CouponSponsor';

export default defineMessages({
  pageHeadingText: {
    id: `${scope}.pageHeadingText`,
    defaultMessage: 'Coupon sponsorship',
  },
  sponsorshipDescriptionText: {
    id: `${scope}.sponsorshipDescriptionText`,
    defaultMessage:
      "Once you click Continue, you will not be able to suspend or cancel the sponsorship of this coupon. The amount of available sponsorship slots will decrease by 1. A sponsored coupon gets in the top of customer's recommended list until the coupon expires.",
  },
  continueBtnText: {
    id: `${scope}.continueBtnText`,
    defaultMessage: 'Continue',
  },
  cancelBtnText: {
    id: `${scope}.cancelBtnText`,
    defaultMessage: 'Cancel',
  },
  sponsorCouponSuccessText: {
    id: `${scope}.sponsorCouponSuccessText`,
    defaultMessage: 'Coupon {couponTitle} sponsored',
  },
});
