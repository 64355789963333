import branch from 'branch-sdk';

export const initializeBranch = () => {
  branch.init(process.env.REACT_APP_BRANCH_KEY, (err, data) => {
    if (err) {
      console.error('Branch initialization error:', err);
    } else {
      console.log('Branch initialized successfully:', data);
    }
  });
};


export const generateBranchLink = async (referralCode, businessName = null) => {
  const body = {
    branch_key: process.env.REACT_APP_BRANCH_KEY,
    tags: ['referral'],
    channel: 'web',
    feature: 'referral',
    campaign: 'referral-campaign',
    data: {
      referralCode: referralCode,
      title: businessName
        ? `Join ${businessName} on our platform!`
        : 'Invite your friends',
      contentDescription: businessName
        ? `Sign up using this referral code and join ${businessName}!`
        : 'Sign up using this referral code to get started!',
      businessName: businessName,
    },
  };

  try {
    const response = await fetch('https://api2.branch.io/v1/url', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });

    const result = await response.json();
    console.log('Generated Branch Link Response:', result);
    return result.url; // Return the generated URL
  } catch (error) {
    console.error('Error generating Branch link manually:', error);
    return null;
  }
};



export const subscribeToBranchEvents = () => {
  branch.subscribe(({ error, params }) => {
    if (error) {
      console.error('Branch subscription error:', error);
    } else if (params['+clicked_branch_link']) {
      console.log('Branch link data:', params);
      if (params.referralCode) {
        console.log('Referral Code:', params.referralCode);
      }
    }
  });
};
