import { Button, Modal } from 'components'
import React, { useCallback, useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';
import styles from './PointsRequestsForm.module.scss'
import { actions as pointsActivityActions } from 'coupon-common/src/modules/pointsRequests/pointsActivity';
import { actions as processPointsActions } from 'coupon-common/src/modules/pointsRequests/processPointsRequest';
import { actions as updatePointsActions } from 'coupon-common/src/modules/pointsRequests/updatePoints';
import { actions as pointsBalanceActions } from 'coupon-common/src/modules/pointsBalance';
import { connect } from 'react-redux';
import CSSModule from 'react-css-modules';
import PropTypes from 'prop-types';

const PointsRequestsForm = ({
  intl,
  req,
  noOfRequests,
  processPointsRequestData,
  processPointsRequestError,
  dispatch,
  pointsActivityIsModalOpen,
  handleNextRequest,
  processPointsRequestFetching,
  updatePointsdata,
  updatePointsError,
  updatePointsFetching,
}) => {

  const [apiCall, setApiCall] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState(null);

  const APPROVED = 'approved';
  const REJECTED = 'rejected';
  const IGNORED = 'ignored';
  const PENDING = 'pending';

  const calculatePoints = useCallback(() => {
    if(selectedAmount == 10){
      return selectedAmount*1;
    }
    else if(selectedAmount == 25) {
      return selectedAmount*2;
    }
    else if (selectedAmount == 50) {
      return selectedAmount*2.5;
    }
    else if(selectedAmount == 100){
      return selectedAmount*3;
    }
    // return req?.points_rate * selectedAmount;
  }, [selectedAmount])

  useEffect(() => {
    if (req?.id) {
      dispatch(pointsActivityActions.openModal());
    }
  }, [req])


  useEffect(() => {
    if (apiCall) {
      if (processPointsRequestError) {
        alert("There was a problem processing the request.")
        onRequestClose();
      }
      else if(processPointsRequestData?.status == IGNORED){
        alert("You have 48 hours to address an ignored request. After that it will be automatically rejected. \n Click on Points Request to find and address ignored requests.");
        onRequestClose();
      }
      else if(processPointsRequestData?.status == REJECTED){
        onRequestClose();
      }
    }
  }, [processPointsRequestData, processPointsRequestError])

  // Handling of updating the wallet if successfull then it will update the points requests
  useEffect(() => {
    if (apiCall) {
      if (updatePointsdata?.success) {
        // To update the new wallet balance for business in top right navbar
        dispatch(pointsBalanceActions.getPointsBalance())
        onRequestClose();
      }
      else if(updatePointsError){
        alert(updatePointsError)
        onRequestClose();
      }
    }

  }, [updatePointsdata, updatePointsError])

  const onSubmit = (e) => {
    e.preventDefault();
    setLoadingBtn(APPROVED)
    dispatch(updatePointsActions.updatePoints(req?.user, calculatePoints(), req?.id, APPROVED))
    setApiCall(true);
  }

  const handleRejectOrIgnore = (value) => {
    setLoadingBtn(value)
    if (value === REJECTED) {
      dispatch(processPointsActions.updatePointsRequests(req?.id, REJECTED));
      // dispatch(processPointsActions.updatePointsRequests(req?.id, PENDING));
    }
    else if (value === IGNORED) {
      dispatch(processPointsActions.updatePointsRequests(req?.id, IGNORED));
    }
    setApiCall(true);
  }

  const onRequestClose = () => {
    dispatch(pointsActivityActions.closeModal());
    handleNextRequest();
    setSelectedAmount(null);
    setLoadingBtn(null);
    setApiCall(false);
  }

  return (
    req && (
      <Modal
        title={intl.formatMessage(messages.pointsSystemModalTitle)}
        isOpen={pointsActivityIsModalOpen}
        onRequestClose={onRequestClose}
      >
        <div styleName="points-system-modal-container">

          <form onSubmit={onSubmit}>
            {noOfRequests && (
              <div styleName='remaining'>
                <span>Request(s): {`1/${noOfRequests}`}</span>
              </div>
            )}
            <p><span className='font-weight-bold text-capitalize'>{req?.customer?.first_name || req?.first_name}</span> has requested points</p>
            <p className='small'>How much money did this customer spend before tax ?</p>
            <div className='d-flex flex-column gap-2'>
              <Button checkable checked={selectedAmount == 10} type="button" onClick={() => setSelectedAmount(10)}>
                <FormattedMessage {...messages.option_1ButtonText} />
              </Button>
              <Button checkable checked={selectedAmount == 25} type="button" onClick={() => setSelectedAmount(25)}>
                <FormattedMessage {...messages.option_2ButtonText} />
              </Button>
              <Button checkable checked={selectedAmount == 50} type="button" onClick={() => setSelectedAmount(50)}>
                <FormattedMessage {...messages.option_3ButtonText} />
              </Button>
              <Button checkable checked={selectedAmount == 100} type="button" onClick={() => setSelectedAmount(100)}>
                <FormattedMessage {...messages.option_4ButtonText} />
              </Button>
            </div>
            <hr />

            <p className='mt-4 small'>The customer will receive <span className='font-weight-bold'>{selectedAmount ? calculatePoints().toLocaleString() : "___"}</span> points from you.</p>
            <div className='mt-2 text-center'>
              <Button
                fetching={processPointsRequestFetching && loadingBtn == IGNORED}
                style={{ minWidth: '100px' }}
                outline
                onClick={() => handleRejectOrIgnore(IGNORED)}
                disabled={processPointsRequestFetching}>
                <FormattedMessage {...messages.ignoreButtonText} />
              </Button>
              <Button
                fetching={processPointsRequestFetching && loadingBtn == REJECTED}
                style={{ minWidth: '100px' }}
                outline
                onClick={() => handleRejectOrIgnore(REJECTED)}
                disabled={processPointsRequestFetching}>
                <FormattedMessage {...messages.cancelButtonText} />
              </Button>
              <Button
                fetching={updatePointsFetching && loadingBtn == APPROVED}
                style={{ minWidth: '100px' }}
                type="submit"
                disabled={!selectedAmount || processPointsRequestFetching}>
                <FormattedMessage {...messages.acceptButtonText} />
              </Button>
            </div>

          </form>
        </div>
      </Modal>
    )

  )

}

PointsRequestsForm.propTypes = {
  intl: PropTypes.object,
  req: PropTypes.object,
  noOfRequests: PropTypes.number,
  processPointsRequestData: PropTypes.object,
  processPointsRequestError: PropTypes.string,
  dispatch: PropTypes.func,
  pointsActivityIsModalOpen: PropTypes.bool,
  handleNextRequest: PropTypes.func,
  processPointsRequestFetching: PropTypes.bool,
  updatePointsdata: PropTypes.object,
  updatePointsError: PropTypes.string,
  updatePointsFetching: PropTypes.bool,
  // business: PropTypes.object
};


const mapStateToProps = (state) => {
  return {
    authorized: state.auth.authorized,
    processPointsRequestData: state.pointsRequests.processPointsRequest.data,
    processPointsRequestError: state.pointsRequests.processPointsRequest.error,
    processPointsRequestFetching: state.pointsRequests.processPointsRequest.fetching,
    pointsActivityIsModalOpen: state.pointsRequests.pointsActivity.isModalOpen,
    updatePointsdata: state.pointsRequests.updatePoints.data,
    updatePointsError: state.pointsRequests.updatePoints.error,
    updatePointsFetching: state.pointsRequests.updatePoints.fetching,
    // businessId: state.businessInfo.data.id,
  };
};

export default connect(mapStateToProps)(injectIntl(CSSModule(PointsRequestsForm, styles)));
