import React from 'react';

import CSSModule from 'react-css-modules';

import styles from './Chart.module.scss';

export const ChartBar = ({
  x,
  y,
  width,
  height,
  className,
  barDataKey,
  ...props
}) => {
  const yAxisShift = -4;
  const xAxisShift = 3.5;
  return (
    <g>
      <text
        styleName={className}
        x={x}
        y={y}
        dy={yAxisShift}
        dx={xAxisShift}
        textAnchor="middle"
      >
        {props[barDataKey]}
      </text>
      <rect styleName={className} x={x} y={y} width={width} height={height} />
    </g>
  );
};

export const ChartTick = ({ x, y, payload: { value }, xAxis }) => {
  const xAxisShift = xAxis ? 0 : -10;
  const yAxisShift = xAxis ? 10 : 3;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        styleName="tic-label"
        dx={xAxisShift}
        dy={yAxisShift}
        textAnchor="middle"
      >
        {value}
      </text>
    </g>
  );
};

export const ChartLegend = ({ barsConfig }) =>
  barsConfig.map(item => {
    return (
      <div
        key={item.dataKey}
        className="d-inline-flex justify-content-center mr-4"
      >
        <svg styleName="legend-icon" className="mr-2">
          <rect styleName={item.className} />
        </svg>
        <span className="m-0" styleName="legend-label">
          {item.label}
        </span>
      </div>
    );
  });

export const CustomChartTick = CSSModule(ChartTick, styles);
export const CusomChartLegend = CSSModule(ChartLegend, styles);
export const CustomChartBar = CSSModule(ChartBar, styles);
