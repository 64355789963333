/**
 * @param {HTMLImageElement} image      - Image File Object
 * @param {Object}           crop       - Crop Object
 * @param {String}           fileName   - Name of the returned file in Promise,
 *                                        don't needed if not blobReturn
 * @param {Boolean}          blobReturn - If true function will return promise
 *                                        that resolves with blob, by default returns base64
 */
export function getCroppedImg(image, crop, fileName, blobReturn) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = Math.ceil(crop.width);
  canvas.height = Math.ceil(crop.height);
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  // As a blob
  if (blobReturn) {
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        blob => {
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1,
      );
    });
  }

  // As Base64 string
  return canvas.toDataURL('image/jpeg');
}
