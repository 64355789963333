import { OK } from 'http-status-codes';
import { put, select, takeLatest } from 'redux-saga/effects';

import { isTokenExpired } from '../../../utils';
import * as tokensModule from '../tokens';
import { refreshAccessToken } from './api';
import { actions, types } from './duck';

export function* clearRefreshAccessToken() {
  // 'exp' is a JWT token expiration timestamp in microsecconds.
  const refreshTokenExp = yield select(
    state => state.auth.tokens.refreshDecoded.exp,
  );
  const refreshTokenExpired = isTokenExpired(refreshTokenExp);

  if (refreshTokenExpired) {
    throw Error('Refresh token was expired.');
  }

  const refreshToken = yield select(state => state.auth.tokens.refresh);

  const response = yield refreshAccessToken(refreshToken);

  if (response.status !== OK) {
    throw Error(response.data.detail);
  }

  const newAccessToken = response.data.access;
  const updatedTokens = { refresh: refreshToken, access: newAccessToken };

  return updatedTokens;
}

export function* refreshAuthSaga() {
  try {
    yield put(actions.refreshAuthRequest());
    const refreshedTokens = yield clearRefreshAccessToken();
    yield put(tokensModule.actions.setTokens(refreshedTokens));
    yield put(actions.refreshAuthSuccess(refreshedTokens));
  } catch (error) {
    yield put(actions.refreshAuthFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.REFRESH_AUTH, refreshAuthSaga);
}
