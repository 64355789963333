import { defineMessages } from 'react-intl';

const scope = 'cb.containers.PointsRequestsTable';

export default defineMessages({
    pageHeadingText: {
        id: `${scope}.pageHeadingText`,
        defaultMessage: 'Points Requests',
    },
    firstNameColumnText: {
        id: `${scope}.firstNameColumnText`,
        defaultMessage: 'First Name',
    },
    pointsColumnText: {
        id: `${scope}.pointsColumnText`,
        defaultMessage: 'Points',
    },
    dateAndTimeColumnText: {
        id: `${scope}.dateAndTimeColumnText`,
        defaultMessage: 'Date and Time',
    },
    statusColumnText: {
        id: `${scope}.statusColumnText`,
        defaultMessage: 'Status',
    },
    welcomeText:{
        id: `${scope}.welcomeText`,
        defaultMessage: "MyCouponBook offers a robust and helpful loyaly points program, allowing you to distribute points to loyal customers, which can be used to purchase coupons. Head over to My Account to opt in!"
    }
});
