import { DEFAULT_DECIMAL_ROUNDING_PRECISION } from '../constants';

export const sumValues = valuesArr => {
  const arrayOfNumbers = valuesArr.map(item => Number(item) || 0);
  const sum = arrayOfNumbers.reduce((total, value) => total + value);
  return roundNumber(sum);
};

export const roundNumber = value =>
  +value.toFixed(DEFAULT_DECIMAL_ROUNDING_PRECISION);
