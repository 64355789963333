import { fork, put, takeLatest } from 'redux-saga/effects';

import { TOKENS_LOCAL_STORAGE_KEY } from '../../../constants';
import * as tokensModule from '../tokens';
import { actions, types } from './duck';

export function* saveAuthWebSaga({ tokens }) {
  const tokensJson = JSON.stringify(tokens);
  localStorage.setItem(TOKENS_LOCAL_STORAGE_KEY, tokensJson);
  yield put(actions.saveAuthSuccess(tokens));
}

export function* restoreAuthWebSaga() {
  const tokensJson = localStorage.getItem(TOKENS_LOCAL_STORAGE_KEY);
  if (tokensJson) {
    const tokens = JSON.parse(tokensJson);
    yield put(tokensModule.actions.setTokens(tokens));
    yield put(actions.restoreAuthSuccess(tokens));
  } else {
    yield put(actions.restoreAuthFailure('Storage is empty.'));
  }
}

export function* forgotAuthWebSaga() {
  localStorage.removeItem(TOKENS_LOCAL_STORAGE_KEY);
  yield put(actions.forgotAuthSuccess());
}

export function* runRestore() {
  yield put(actions.restoreAuth());
}

export default function* saga() {
  yield takeLatest(types.SAVE_AUTH, saveAuthWebSaga);
  yield takeLatest(types.RESTORE_AUTH, restoreAuthWebSaga);
  yield takeLatest(types.FORGOT_AUTH, forgotAuthWebSaga);

  // Integration listeners
  yield takeLatest(tokensModule.types.SET_TOKENS, saveAuthWebSaga);
  yield takeLatest(tokensModule.types.CLEAR_TOKENS, forgotAuthWebSaga);

  // Restore auth on initialize.
  yield fork(runRestore);
}
