import { apiCall } from '../../utils';
import { formatToAccountFormData } from '../businessInfo/api';

export const signUp = values => {
  const data = formatToAccountFormData(values);

  return apiCall({
    url: '/users/business-sign-up/',
    method: 'post',
    data,
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      Accept: 'application/json',
    },
  });
};
