import { FORM_ERROR } from 'final-form';
import { CREATED, OK } from 'http-status-codes';
import { put, takeEvery } from 'redux-saga/effects';

import { safeResolveAsync } from '../../utils';

import * as api from './api';
import { actions, types } from './duck';

export function* uploadCouponImage({ id, coupon_id, sequence, data, resolve }) {
  try {
    yield put(actions.uploadCouponImageRequest(id));

    const response = yield api.uploadCouponImage(
      coupon_id,
      sequence || id,
      data,
    );

    if (response.status === CREATED) {
      yield put(actions.uploadCouponImageSuccess(id, response.data));
      yield safeResolveAsync(resolve, response.data);
    } else {
      const error = Array.isArray(response.data)
        ? response.data[0]
        : response.data.detail;
      yield put(actions.uploadCouponImageFailure(id, error));
      yield safeResolveAsync(resolve, { error });
    }
  } catch (error) {
    yield put(actions.uploadCouponImageFailure(id, error.message));
    yield safeResolveAsync(resolve, { [FORM_ERROR]: error.message });
  }
}

export function* updateCouponImage({
  id,
  coupon_image_id,
  sequence,
  data,
  resolve,
}) {
  try {
    yield put(actions.updateCouponImageRequest(id));

    const response = yield api.updateCouponImage(
      coupon_image_id,
      sequence,
      data,
    );

    if (response.status === OK) {
      yield put(actions.updateCouponImageSuccess(id, response.data));
      yield safeResolveAsync(resolve, response.data);
    } else {
      const error = Array.isArray(response.data)
        ? response.data[0]
        : response.data.detail;
      yield put(actions.updateCouponImageFailure(id, error));
      yield safeResolveAsync(resolve, { error });
    }
  } catch (error) {
    yield put(actions.updateCouponImageFailure(id, error.message));
    yield safeResolveAsync(resolve, { [FORM_ERROR]: error.message });
  }
}

export function* deleteCouponImage({ id, coupon_image_id, resolve }) {
  try {
    yield put(actions.deleteCouponImageRequest(id));

    const response = yield api.deleteCouponImage(coupon_image_id);

    if (response.status === OK) {
      yield put(actions.deleteCouponImageSuccess(id, response.data));
      yield safeResolveAsync(resolve, response.data);
    } else {
      const error = Array.isArray(response.data)
        ? response.data[0]
        : response.data.detail;
      yield put(actions.deleteCouponImageFailure(id, error));
      yield safeResolveAsync(resolve, { error });
    }
  } catch (error) {
    yield put(actions.deleteCouponImageFailure(id, error.message));
    yield safeResolveAsync(resolve, { [FORM_ERROR]: error.message });
  }
}

export default function* saga() {
  yield takeEvery(types.UPLOAD_COUPON_IMAGE, uploadCouponImage);
  yield takeEvery(types.UPDATE_COUPON_IMAGE, updateCouponImage);
  yield takeEvery(types.DELETE_COUPON_IMAGE, deleteCouponImage);
}
