import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import styles from './FieldLabel.module.scss';

const FieldLabel = ({ label, invalid }) => {
  if (label) {
    return (
      <label styleName={cx('form-field-label', { 'is-invalid': invalid })}>
        {label}
      </label>
    );
  }
  return null;
};

FieldLabel.propTypes = {
  label: PropTypes.string,
  invalid: PropTypes.bool,
};

export default CSSModule(FieldLabel, styles, { allowMultiple: true });
