import { defineMessages } from 'react-intl';

const scope = 'cb.containers.AdminPanel.UserManager';

export default defineMessages({
  pageHeadingText: {
    id: `${scope}.pageHeadingText`,
    defaultMessage: 'User Manager',
  },
  firstNameColumnText: {
    id: `${scope}.firstNameColumnText`,
    defaultMessage: 'First Name',
  },
  businessColumnText: {
    id: `${scope}.businessColumnText`,
    defaultMessage: 'Business',
  },
  emailColumnText: {
    id: `${scope}.emailColumnText`,
    defaultMessage: 'Email',
  },
  pointsColumnText: {
    id: `${scope}.pointsColumnText`,
    defaultMessage: 'Points',
  },
  editColumnText: {
    id: `${scope}.editColumnText`,
    defaultMessage: 'Edit',
  },
  customersColumnText: {
    id: `${scope}.customersColumnText`,
    defaultMessage: 'Customers',
  },
  businessesColumnText: {
    id: `${scope}.businessesColumnText`,
    defaultMessage: 'Businesses',
  },
  viewTransactionsColumnText: {
    id: `${scope}.viewTransactionsColumnText`,
    defaultMessage: 'View Points Transactions',
  },
  cancelColumnText: {
    id: `${scope}.cancelColumnText`,
    defaultMessage: 'Cancel',
  },
  saveColumnText: {
    id: `${scope}.saveColumnText`,
    defaultMessage: 'Save',
  },
});