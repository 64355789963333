import { defineMessages } from 'react-intl';

const scope = 'cb.containers.AdminPanel.PointsTransactions';

export default defineMessages({
    pageHeadingText: {
        id: `${scope}.pageHeadingText`,
        defaultMessage: 'Points Transactions',
    },
    idColumnText: {
        id: `${scope}.idColumnText`,
        defaultMessage: 'ID',
    },
    fromUserColumnText: {
        id: `${scope}.fromUserColumnText`,
        defaultMessage: 'From user',
    },
    toUserColumnText: {
        id: `${scope}.toUserColumnText`,
        defaultMessage: 'To user',
    },
    amountColumnText: {
        id: `${scope}.amountColumnText`,
        defaultMessage: 'Amount',
    },
    previousBalanceColumnText: {
        id: `${scope}.previousBalanceColumnText`,
        defaultMessage: 'Previous Balance',
    },
    newBalanceColumnText: {
        id: `${scope}.newBalanceColumnText`,
        defaultMessage: 'New Balance',
    },
    timeColumnText: {
        id: `${scope}.timeColumnText`,
        defaultMessage: 'Time',
    },
    statusColumnText: {
        id: `${scope}.statusColumnText`,
        defaultMessage: 'Status',
    },
    notesColumnText: {
        id: `${scope}.notesColumnText`,
        defaultMessage: 'Notes',
    },
});
