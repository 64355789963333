import { defineMessages } from 'react-intl';

export const scope = 'cb.components.Logo';

export default defineMessages({
  logoAlt: {
    id: `${scope}.logoAlt`,
    defaultMessage: 'Coupon Business',
  },
});
