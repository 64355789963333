import React from 'react'
import { FormField, FormFieldWrapper, FormPillSelector } from 'components';
import { Field, Form } from 'react-final-form';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

const AdminCustomerUserForm = ({ styles, isEditing, allCategories, cities }) => {
    return (
        <div className={styles.fields}>
            <h3>Account Info</h3>
            <Field
                className={classNames(styles.formGroup)}
                name="id" // Set the name of the field correctly
                label="Id"
                component={FormField}
                readOnly={!isEditing}
                disabled
                type="text"
            />


            <Field
                className={classNames(styles.formGroup)}
                name="first_name"
                label="First Name"
                component={FormField}
                readOnly={!isEditing}
                type="text"
            />

            <Field
                className={classNames(styles.formGroup)}
                name="last_name"
                label="Last Name"
                component={FormField}
                readOnly={!isEditing}
                type="text"
            />

            <Field
                className={classNames(styles.formGroup)}
                name="email"
                label="Email"
                component={FormField}
                readOnly={!isEditing}
                disabled
                type="email"
            />

            <Field
                className={classNames(styles.formGroup)}
                name="coupon_search_range"
                label="Coupon Search Range"
                component={({ input, meta, label }) => {
                    // Hardcoded coupon search range based on backend
                    // https://prnt.sc/X7b48U5LpTMq
                    const couponSearchRanges = [
                        {
                            name: "200 m",
                            range: 200
                        },
                        {
                            name: "1 km",
                            range: 1000
                        },
                        {
                            name: "5 km",
                            range: 5000
                        },
                        {
                            name: "10 km",
                            range: 10000
                        },
                        {
                            name: "20 km",
                            range: 20000
                        },
                        {
                            name: "50 km",
                            range: 50000
                        },
                        {
                            name: "200 km",
                            range: 200000
                        },
                        {
                            name: "5 km",
                            range: 500000
                        },
                    ]

                    const currentRange = couponSearchRanges?.find((searchRange)=> searchRange?.range === meta?.initial)

                    return (
                        <FormFieldWrapper
                            invalid={meta.touched && meta.error}
                            label={label}
                            error={meta.error}
                        >
                            <select
                                {...input}
                                onChange={(e) => input.onChange(Number(e.target.value))} //convert city id to number onSubmit
                                style={{ height: "70%" }}
                                disabled={!isEditing}
                                className="form-control"
                            >
                                <option value={currentRange?.range}>{currentRange?.name}</option>
                                {couponSearchRanges?.map((searchRange) => (
                                    searchRange?.range != currentRange?.range && (
                                        <option key={searchRange?.range} value={searchRange?.range}>
                                            {searchRange?.name}
                                        </option>
                                    )
                                ))}
                            </select>
                        </FormFieldWrapper>
                    );
                }}
            />

            <Field
                className={classNames(styles.formGroup)}
                name="city"
                label="City"
                component={({ input, meta, label }) => {
                    const citiesArray = Object.values(cities); // Convert object to array
                    const currentCity = citiesArray?.find((city) => city?.id === meta?.initial)
                    return (
                        <FormFieldWrapper
                            invalid={meta.touched && meta.error}
                            label={label}
                            error={meta.error}
                        >
                            <select
                                {...input}
                                onChange={(e) => input.onChange(Number(e.target.value))} //convert city id to number onSubmit
                                style={{ height: "70%" }}
                                disabled={!isEditing}
                                className="form-control"
                            >
                                <option value={currentCity?.id}>{currentCity?.name}</option>
                                {citiesArray?.map((city) => (
                                    city?.id != currentCity?.id && (
                                        <option key={city?.id} value={city?.id}>
                                            {city?.name}
                                        </option>
                                    )
                                ))}
                            </select>
                        </FormFieldWrapper>
                    );
                }}
            />

            <Field
                name="categories"
                label="Categories"
                component={({ input, meta, label }) => {
                    return (
                        <FormFieldWrapper
                            invalid={meta.touched && meta.error}
                            label={label}
                            error={meta.error}
                        >
                            <div>
                                <FormPillSelector
                                    input={input}
                                    meta={meta}
                                    options={allCategories}
                                    readOnly={!isEditing}
                                    maxSelected={3}
                                />
                            </div>
                        </FormFieldWrapper>
                    );
                }}

            />

            <Field
                className={classNames(styles.formGroup)}
                name="firebase_fcm"
                label="Firebase FCM"
                component={FormField}
                readOnly={!isEditing}
                type="text"
            />

            <Field
                className={classNames(styles.formGroup)}
                name="firebase_uid"
                label="Firebase UID"
                component={FormField}
                readOnly={!isEditing}
                type="text"
            />

            <Field
                className={classNames(styles.formGroup)}
                name="groups"
                label="Groups"
                component={FormField}
                disabled
                type="text" // Replace with a multi-select dropdown if necessary
            />

            <Field
                className={classNames(styles.formGroup)}
                name="last_login"
                label="Last Login"
                component={FormField}
                disabled
                type="text"
            />


            <Field
                className={classNames(styles.formGroup)}
                name="signed_at"
                label="Signed At"
                component={FormField}
                disabled
                type="datetime-local"
            />

            <Field
                className={classNames(styles.formGroup)}
                name="user_permissions"
                label="User Permissions"
                component={FormField}
                disabled
                type="text" // Replace with a multi-select dropdown if necessary
            />

            <div  className='d-flex justify-content-between flex-wrap'>
                <Field
                    className={classNames(styles.formGroup)}
                    name="is_active"
                    label="Is Account Active"
                    component={FormField}
                    disabled={!isEditing}
                    type="checkbox"
                />

                <Field
                    className={classNames(styles.formGroup)}
                    name="is_blocked"
                    label="Is Account Blocked"
                    component={FormField}
                    disabled={!isEditing}
                    type="checkbox"
                />

                <Field
                    className={classNames(styles.formGroup)}
                    name="is_business_marketer"
                    label="Is Account Business Marketer"
                    component={FormField}
                    disabled
                    type="checkbox"
                />

                <Field
                    className={classNames(styles.formGroup)}
                    name="is_staff"
                    label="Is Account Staff"
                    component={FormField}
                    disabled
                    type="checkbox"
                />

                <Field
                    className={classNames(styles.formGroup)}
                    name="is_superuser"
                    label="Is Account Superuser"
                    component={FormField}
                    disabled
                    type="checkbox"
                />
            </div>

            <h3>Membership Info</h3>
            <Field
                className={classNames(styles.formGroup)}
                name="membership.id"
                label="Membership ID"
                component={FormField}
                disabled
                type="number"
            />

            <Field
                className={classNames(styles.formGroup)}
                name="membership.created_at"
                label="Membership Created At"
                component={FormField}
                disabled
                type="datetime-local"
            />

            <Field
                className={classNames(styles.formGroup)}
                name="membership.is_paid"
                label="Membership Is Paid"
                component={FormField}
                disabled={!isEditing}
                type="checkbox"
            />

            <Field
                className={classNames(styles.formGroup)}
                name="membership.date_start"
                label="Membership Start Date"
                component={FormField}
                disabled={!isEditing}
                type="datetime-local"
            />
            <Field
                className={classNames(styles.formGroup)}
                name="membership.expiration_date"
                label="Membership Expiry Date"
                component={FormField}
                disabled
                type="datetime-local"
            />


            <Field
                className={classNames(styles.formGroup)}
                name="membership.payment_id"
                label="Membership Payment ID"
                component={FormField}
                disabled
                type="text"
            />

            <h3>Wallet Info</h3>

            <div className='d-flex justify-content-end'>
                <NavLink to="/admin/points/manage">Manage Points</NavLink>
            </div>

            <Field
                className={classNames(styles.formGroup)}
                name="wallet.id"
                label="Wallet ID"
                component={FormField}
                disabled
                type="number"
            />

            <Field
                className={classNames(styles.formGroup)}
                name="points_wallet"
                label="Points Wallet"
                component={FormField}
                disabled
                type="number"
            />


            <Field
                className={classNames(styles.formGroup)}
                name="wallet.points_at_last_date"
                label="Points at Last Date"
                component={FormField}
                disabled
                type="number"
            />

            <Field
                className={classNames(styles.formGroup)}
                name="wallet.points_recalculation_date"
                label="Points Recalculation Date"
                component={FormField}
                disabled
                type="datetime-local"
            />

            <h3>Referral Info</h3>
            <Field
                className={classNames(styles.formGroup)}
                name="referral_node"
                label="Referral Node"
                component={FormField}
                disabled
                type="number"
            />

        </div>
    )
}

export default AdminCustomerUserForm
