import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import styles from './Footer.module.scss';
import messages from './messages';

const Footer = ({ mtAuto, textCentered }) => {
  return (
    <footer styleName="footer-main" className={cx({ 'mt-auto': mtAuto })}>
      <div className={cx('container', { 'text-center': textCentered })}>
        <Link to="/privacy-policy">
          <FormattedMessage {...messages.privacyPolicyLinkText} />
        </Link>
        <FormattedMessage tagName="span" {...messages.footerText} />
      </div>
    </footer>
  );
};

Footer.propTypes = {
  mtAuto: PropTypes.bool,
  textCentered: PropTypes.bool,
};

Footer.defaultProps = {
  textCentered: true,
};

export default CSSModule(Footer, styles);
