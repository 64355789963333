import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage, injectIntl } from 'react-intl';
import { generateBranchLink } from 'service/branchService';

import { Block, Button, Feedback } from 'components';

import messages from './messages';
import ShareReferralCodeImg from './ShareReferralCodeImg.svg';

export const CODE_PLACEHOLDER = '_ _ _ - _ _ _';

export const ShareReferralCode = ({ intl, code, businessName }) => {
  const [copied, setCopied] = useState(false);
  const [branchLink, setBranchLink] = useState('');

  useEffect(() => {
    const createBranchLink = async () => {
      try {
        const link = await generateBranchLink(code, businessName);
        if (link) {
          console.log('Resolved Branch Link:', link);
          setBranchLink(link);
        } else {
          console.error('Branch link is undefined or null.');
        }
      } catch (error) {
        console.error('Error in createBranchLink:', error);
      }
    };

    if (code) {
      createBranchLink();
    }
  }, [code, businessName]);

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: businessName
            ? `Join ${businessName} on our platform!`
            : 'Share Your Referral Code',
          text: `Use my referral code: ${code} to sign up and get rewards!`,
          url: branchLink,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Sharing is not supported on this browser. Copy the link instead.');
    }
  };


  return (
    <Block>
      <div className="row">
        <div className="col-md-5">
          <FormattedMessage
            tagName="h5"
            {...messages.shareThisCodeBlockHeading}
          />
          <FormattedMessage tagName="p" {...messages.shareCodeHelpText} />
          <FormattedMessage tagName="p" {...messages.shareLinkHelpText} />

          {/* Add QR Code Section */}
          <div className="text-center">
            <h3>Scan the QR Code to Login</h3>
            {branchLink ? (
              <QRCodeSVG value={branchLink} size={200} />
            ) : (
              <p>Generating QR Code...</p>
            )}
          </div>

          <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
            <Button outline={!code} className="mt-4" fullWidth>
              {code || CODE_PLACEHOLDER}
            </Button>
          </CopyToClipboard>

          <Button
            className="mt-4"
            fullWidth
            onClick={handleShare}
            disabled={!branchLink}
          >
            Share via Email or Social Media
          </Button>

          <Feedback
            show={copied}
            content={intl.formatMessage(messages.codeWasCopiedFeedbackText)}
            className="mt-4"
            textCenter
          />
        </div>

        <div className="col-md-7 mt-3">
          <img
            src={ShareReferralCodeImg}
            className="img-fluid"
            alt={intl.formatMessage(messages.shareReferralCodeAlt)}
          />
        </div>
      </div>
    </Block>
  );
};

ShareReferralCode.propTypes = {
  intl: PropTypes.object,
  code: PropTypes.string,
  businessName: PropTypes.string,
};

export default injectIntl(ShareReferralCode);
