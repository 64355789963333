import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Block, FormImageField } from 'components';
import { DEFALUT_ACCEPTING_IMG_FORMATS } from 'coupon-common';
import {
  composeSyncValidators,
  required,
  validImage,
} from 'coupon-common/src/utils';

import messages from './messages';

/*
 * Business Logos requirements
 * Max/min sizes in bytes
 */
export const logoReq = {
  maxSize: 5000000,
  rounded: true,
  width: 85,
  height: 85,
  aspect: 1,
  accept: DEFALUT_ACCEPTING_IMG_FORMATS,
};

export const coverReq = {
  maxSize: 10000000,
  width: 425,
  height: 160,
  aspect: 425 / 160,
  accept: DEFALUT_ACCEPTING_IMG_FORMATS,
};

const BusinessLogosFieldGroup = ({
  intl,
  readOnly,
  forDetailView,
  fullWidth,
}) => {
  const fullWidthClassName = 'col-md-12';
  const logoClassName = forDetailView ? fullWidthClassName : 'col-md-3';
  const coverClassName = forDetailView
    ? cx(fullWidthClassName, 'mb-3')
    : 'col-md-9';
  const containerClassName = fullWidth ? fullWidthClassName : 'col-md-10';

  const coverImageFormattedMessage = forDetailView
    ? intl.formatMessage(messages.businessHedingImageFieldDescriptionText)
    : intl.formatMessage(messages.businessHedingImageFieldText);

  return (
    <Block>
      <div className="row justify-content-center">
        <div className={containerClassName}>
          <h5>
            <FormattedMessage {...messages.blockHeading} />
          </h5>
          <div className="row flex-wrap-reverse align-items-center">
            <div className={logoClassName}>
              <Field
                {...logoReq}
                name="logo"
                validate={composeSyncValidators(
                  required,
                  validImage(
                    logoReq.minSize,
                    logoReq.maxSize,
                    logoReq.accept.split(', '),
                  ),
                )}
                readOnly={readOnly}
                label={intl.formatMessage(messages.businessLogoImageFieldText)}
                component={FormImageField}
                textDark={!!forDetailView}
                row={!!forDetailView}
              />
            </div>
            <div className={coverClassName}>
              <Field
                {...coverReq}
                name="cover_image"
                validate={composeSyncValidators(
                  required,
                  validImage(
                    coverReq.minSize,
                    coverReq.maxSize,
                    coverReq.accept.split(', '),
                  ),
                )}
                readOnly={readOnly}
                label={coverImageFormattedMessage}
                component={FormImageField}
                textDark={!!forDetailView}
              />
            </div>
          </div>
        </div>
      </div>
    </Block>
  );
};

BusinessLogosFieldGroup.propTypes = {
  intl: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  forDetailView: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default injectIntl(BusinessLogosFieldGroup);
