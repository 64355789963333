import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { CouponActionModalContainer } from 'components';
import { actions } from 'coupon-common/src/modules/coupons';
import { dispatchAsAsync } from 'coupon-common/src/utils';

import messages from './messages';

export const CouponSponsor = ({
  intl,
  dispatch,
  isOpen,
  sponsorCouponData,
  closeModalHandler,
}) => {
  const handleSponsorCoupon = async () => {
    closeModalHandler();
    const { success } = await dispatchAsAsync(
      actions.sponsorCoupon(sponsorCouponData.id),
      dispatch,
    );
    if (success) {
      toast(
        <FormattedMessage
          {...messages.sponsorCouponSuccessText}
          values={{
            couponTitle: <b>{sponsorCouponData.title}</b>,
          }}
        />,
      );
    }
  };

  const formattedPageHeadingText = intl.formatMessage(messages.pageHeadingText);
  const formattedSponsorshipDescriptionText = intl.formatMessage(
    messages.sponsorshipDescriptionText,
  );
  const formattedCancelBtnText = intl.formatMessage(messages.cancelBtnText);
  const formattedContinueBtnText = intl.formatMessage(messages.continueBtnText);

  return (
    <CouponActionModalContainer
      isOpen={isOpen}
      modalHeadingText={formattedPageHeadingText}
      actionDescriptionText={formattedSponsorshipDescriptionText}
      cancelBtnText={formattedCancelBtnText}
      confirmBtnText={formattedContinueBtnText}
      onModalClose={closeModalHandler}
      onModalConfirm={handleSponsorCoupon}
    />
  );
};

export default connect()(injectIntl(CouponSponsor));
