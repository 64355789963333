import { defineMessages } from 'react-intl';

export const scope = 'cb.components.OnlyFormPageWrapper';

export default defineMessages({
  imageAlt: {
    id: `${scope}.imageAlt`,
    defaultMessage: 'Intro',
  },
});
