import React from 'react';

import PropTypes from 'prop-types';

const AccountMenuItem = ({ children, onClick }) => {
  return (
    <button className="dropdown-item" onClick={onClick}>
      {children}
    </button>
  );
};

AccountMenuItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    ),
    PropTypes.element,
  ]),
  onClick: PropTypes.func.isRequired,
};

export default AccountMenuItem;
