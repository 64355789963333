import { authApiCall } from "../../auth";

export const updateBusinessUserAPI = (id, updatedData) => {
  return authApiCall({
    url: `/users/admin/update-business/${id}/`,
    method: 'patch',
    data: {updatedData}
  });
};

export const updateCustomerUserAPI = (id, updatedData) => {
  return authApiCall({
    url: `/users/admin/update-customer/${id}/`,
    method: 'patch',
    data: {updatedData}
  });
};
