import React from 'react';

import cx from 'classnames';
import CSSModule from 'react-css-modules';
import Switch from 'react-switch';

import styles from './FormSwitch.module.scss';

export const FormSwitch = ({
  input: { onChange, checked },
  label,
  readOnly,
  ...props
}) => {
  return (
    <div
      styleName="form-switch-container"
      className="d-flex align-items-center"
    >
      <label className="mb-0 mr-5">{label}</label>
      <Switch
        className={cx({ 'checked-switch-bg': !!checked })}
        onChange={checked => onChange(checked)}
        checked={!!checked}
        uncheckedIcon={false}
        checkedIcon={false}
        readOnly={readOnly}
        {...props}
      />
    </div>
  );
};

export default CSSModule(FormSwitch, styles);
