import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  locale: 'en',
};

const { Types, Creators } = createActions(
  {
    changeLocale: ['locale'],
  },
  { prefix: 'cb/language/' },
);

export { Types as types, Creators as actions };

export const setLocale = (state = INITIAL_STATE, action) => {
  const { locale } = action;
  return { ...state, locale };
};

export const HANDLERS = {
  [Types.CHANGE_LOCALE]: setLocale,
};

export default createReducer(INITIAL_STATE, HANDLERS);
