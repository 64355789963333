import React, { useState } from 'react';

import cx from 'classnames';
import CSSModule from 'react-css-modules';

import styles from './FormRadio.module.scss';

export const FormRadio = ({ label, input, readOnly }) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    input.onFocus();
    setFocused(true);
  };

  const handleBlur = () => {
    input.onBlur();
    setFocused(false);
  };

  return (
    <div
      styleName={cx('radio', { focused, checked: input.checked })}
      className="d-flex align-items-center"
    >
      <input
        {...input}
        onFocus={handleFocus}
        onBlur={handleBlur}
        readOnly={readOnly}
      />
      <label>{label}</label>
    </div>
  );
};

export default CSSModule(FormRadio, styles, { allowMultiple: true });
