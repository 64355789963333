import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import sharedMessages from 'sharedMessages';

import {
  AppWrapper,
  AppWrapperHeading,
  Block,
  Button,
  Feedback,
  Spinner,
} from 'components';
import {
  AuthProtected,
  BusinessCategoriesField,
  BusinessInfoFieldGroup,
  BusinessLogosFieldGroup,
  LocationFieldGroup,
  MembershipInfo,
} from 'containers';
import { actions } from 'coupon-common/src/modules/businessInfo';
import { dispatchAsAsync } from 'coupon-common/src/utils';

import messages from './messages';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import cautionImg from '../../assets/images/caution.png'

const MyBusinessAccount = ({ intl, dispatch, fetching, data }) => {
  useEffect(() => {
    dispatch(actions.getInfo());
  }, [dispatch]);

  const [editMode, setEditMode] = useState(false);

  const editModeOn = () => setEditMode(true);
  const editModeOff = () => setEditMode(false);


  const readOnly = !editMode;

  const cancelEditing = form => {
    editModeOff();

    if (form) {
      form.reset();
    }
  };

  const onSubmit = async values => {
    const submitErrors = await dispatchAsAsync(
      actions.updateInfo(values),
      dispatch,
    );

    if (!submitErrors) {
      editModeOff();
    }

    return submitErrors;
  };

  const renderControlButtons = (form, handleSubmit) => {
    if (fetching) {
      return null;
    }
    if (editMode) {
      return (
        <>
          <Button outline onClick={() => cancelEditing(form)}>
            <FormattedMessage {...messages.formCancelButtonText} />
          </Button>
          <Button onClick={handleSubmit}>
            <FormattedMessage {...messages.formSaveChangesButtonText} />
          </Button>
        </>
      );
    }
    return (
      <Button onClick={editModeOn}>
        <FormattedMessage {...messages.formEditButtonText} />
      </Button>
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      render={({ form, submitError, handleSubmit }) => (
        <AppWrapper
          showHeader
          showSideNav
          showHeadingRow
          headingRowRender={() => (
            <>
              <div className='col-12 p-0 d-flex flex-column'>
                <AppWrapperHeading
                  headingText={intl.formatMessage(messages.pageHeading)}
                />
                {/*
                    For future:
                    possibly we could extract it to separate component,
                    something like AppWrapperPageFeedback
                */}

                <div className="col">
                  <div className="app-wrapper-heading-row row h-100 align-items-center">
                    <NavLink to='/account/settings'><Button outline>Account Settings</Button></NavLink>
                  </div>
                </div>
                <div className="col">
                  <div className="app-wrapper-heading-row row  m-0 h-100 align-items-center ml-2 mt-2">
                    <Spinner containerClassName='ml-5' show={fetching} justify="end" />
                    <Feedback
                      invalid
                      className="ms-5"
                      fullWidth={false}
                      textCenter
                      show={!fetching && submitError}
                      content={submitError}
                    />
                    {renderControlButtons(form, handleSubmit)}
                  </div>
                </div>
              </div>
            </>
          )}
        >
          <AuthProtected />
          {data.verified === false && (
          <div className="col-md-12">
            <Block>
              <div  className="d-flex align-items-center">
              <img src={cautionImg} alt="" style={{ width: "40px" }} />
              <h6 className="mb-0 ml-1">Your Account is Under Review</h6>
              </div>
              <p className='ml-5'>We're reviewing your account, which typically takes up to 2 business days. In the meantime, feel free to explore your business page and save coupons as drafts. You'll be able to publish them once verification is complete.</p>
            </Block>
          </div>
          )}
          <Prompt
            when={editMode}
            message={intl.formatMessage(sharedMessages.leavePageHintText)}
          />
          <div className="col-md-6">
            <BusinessInfoFieldGroup
              readOnly={readOnly}
              forDetailView
              fullWidth
            />
            <LocationFieldGroup readOnly={readOnly} fullWidth />
          </div>
          <div className="col-md-6">
            <MembershipInfo />
            <BusinessLogosFieldGroup
              readOnly={readOnly}
              forDetailView
              fullWidth
            />
            <BusinessCategoriesField readOnly={readOnly} fullWidth />
          </div>
        </AppWrapper>
      )}
    />
  );
};

MyBusinessAccount.propTypes = {
  intl: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  data: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => ({
  fetching: state.businessInfo.fetching,
  data: state.businessInfo.data,
});

export default connect(mapStateToProps)(injectIntl(MyBusinessAccount));
