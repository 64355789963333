import { CREATED, OK } from 'http-status-codes';
import { put, takeLatest } from 'redux-saga/effects';

import { DEFAULT_DEBOUNCE_TIME_MS } from '../../constants';
import { debounceWithResolve, safeResolveAsync } from '../../utils';

import * as api from './api';
import { actions, types } from './duck';

export function* validateRefCodeSaga({ type, code, resolve }) {
  try {
    const apiMethod =
      type === types.VALIDATE_CUSTOMER_CODE
        ? api.checkCustomerReferralCode
        : api.checkBusinessReferralCode;

    const response = yield apiMethod(code);

    if (response.status === OK) {
      yield safeResolveAsync(resolve);
      return;
    }

    const {
      data: { referral_code },
    } = response;

    if (referral_code) {
      throw Error(referral_code.join('. '));
    }
    throw Error(response.statusText);
  } catch (error) {
    yield safeResolveAsync(resolve, error.message);
  }
}

export function* generateReferralCode() {
  try {
    yield put(actions.generateCodeRequest());

    const response = yield api.generateReferralCode();

    if (response.status === CREATED) {
      yield put(actions.generateCodeSuccess(response.data.referral_code));
      return;
    }

    throw Error(response.statusText);
  } catch (error) {
    yield put(actions.generateCodeFailure(error.message));
  }
}

export default function* saga() {
  yield debounceWithResolve(
    DEFAULT_DEBOUNCE_TIME_MS,
    types.VALIDATE_BUSINESS_CODE,
    validateRefCodeSaga,
  );
  yield debounceWithResolve(
    DEFAULT_DEBOUNCE_TIME_MS,
    types.VALIDATE_CUSTOMER_CODE,
    validateRefCodeSaga,
  );
  yield takeLatest(types.GENERATE_CODE, generateReferralCode);
}
