import React from 'react';

import { FormattedMessage } from 'react-intl';

import { DocumentWrapper } from 'components';
import { formatterContextValues } from 'utils/documentFormatting';

import messages from './messages';

const DealerAgreement = () => {
  return (
    <DocumentWrapper>
      <h5 className="text-center mb-1">
        <FormattedMessage {...messages.heading} />
      </h5>
      <div className="d-flex mb-3">
        <div className="d-flex flex-column mr-4">
          <b className="mb-3">
            <FormattedMessage {...messages.betweenLabel} />
          </b>
          <FormattedMessage tagName="b" {...messages.andLabel} />
        </div>
        <div className="d-flex flex-column">
          <div className="mb-5">
            <FormattedMessage tagName="span" {...messages.dealerText} />
          </div>
          <div className="d-flex flex-column">
            <FormattedMessage
              {...messages.couponBookCompanyData}
              values={{
                bold: formatterContextValues.bold,
                text: msg => <span>{msg}</span>,
                spacedText: msg => (
                  <div className="mt-4">
                    <span>{msg}</span>
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <FormattedMessage
        {...messages.main}
        values={{
          ...formatterContextValues,
          list: formatterContextValues.listAlt,
          heading: msg => (
            <p className="mt-3 mb-3">
              <b>{msg}</b>
            </p>
          ),
          spaced: msg => <span className="spaced">{msg}</span>,
          underlined: msg => <u>{msg}</u>,
        }}
      />
    </DocumentWrapper>
  );
};

export default DealerAgreement;
