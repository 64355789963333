import { AppWrapper, Block, Feedback, Spinner } from 'components';
import AuthProtected from 'containers/AuthProtected/AuthProtected';
import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import { injectIntl } from 'react-intl';
import messages from './messages';
import { connect } from 'react-redux';
import './PointsRequestsTable.module.scss'
import PointsRequestsForm from 'containers/PointsRequestsForm/PointsRequestsForm';
import styles from './PointsRequestsTable.module.scss'
import CSSModules from 'react-css-modules';
const PointsRequestsTable = ({ intl, data}) => {

    const [request, setRequest] = useState();
    const [requestsList, setRequestsList] = useState(null)


    const handlePointsClick = (row) => {
        setRequest(row);
    }

    useEffect(() => {
        if (data?.results) {
            const dataArray = data?.results?.map((req) => ({
                id: req.id,
                user: req.user,
                business: req.business,
                points_rate: req.points_rate,
                first_name: req.customer?.first_name,
                date_and_time: new Date(req.date_time).toLocaleString(),
                points: req.amount,
                status: req.status,
            }));
            setRequestsList(dataArray); // Update state with processed data
        }
    }, [data]);

    const handleNextRequest = () => {
        setRequest();
    }

    const columns = [
        {
            dataField: 'first_name',
            text: intl.formatMessage(messages.firstNameColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'date_and_time',
            text: intl.formatMessage(messages.dateAndTimeColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'points',
            text: intl.formatMessage(messages.pointsColumnText),
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
        {
            dataField: 'status',
            text: intl.formatMessage(messages.statusColumnText),
            classes: (cell, row, rowIndex, columnIndex, extendedValue) => {
                return row.status === 'ignored' ? `${styles.status} ${styles["ignored-request-status"]}` : styles['status']; // Apply class if 'ignored'
            },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    return row.status === 'ignored' ? handlePointsClick(row) : null
                },
            },
            sort: true,
            headerClasses: styles['sticky-header'], // Sticky header
        },
    ];


    return (
        <AppWrapper
            showHeader
            showSideNav
            showHeadingRow
            headingText={intl.formatMessage(messages.pageHeadingText)}
        >
            <div className='ml-4'>
                <p>
                {intl.formatMessage(messages.welcomeText)}
                </p>
                <p/>
            </div>


            <AuthProtected />
            <div className="col-md-12">
                <Block>
                    {requestsList == null || requestsList == undefined ?
                        (
                            <div className='ml-4 mtb-4'>
                                <Spinner show />
                            </div>
                        )
                        : (
                            <div styleName='table-container' className="table-container overflow-auto">
                                <BootstrapTable
                                    classes="table-borderless mb-0 overflow-auto custom-react-bootstrap-table"
                                    striped
                                    bordered={false}
                                    bootstrap4
                                    keyField="id"
                                    data={requestsList || []}
                                    columns={columns}
                                />
                            </div>
                        )}
                </Block>
            </div>

            {request && <PointsRequestsForm req={request} handleNextRequest={handleNextRequest} />}


        </AppWrapper>
    )
}

const mapStateToProps = (state) => {
    return {
        data: state.pointsRequests.pointsActivity.data,
        pointsActivityIsModalOpen: state.pointsRequests.pointsActivity.isModalOpen,
    };
};


export default connect(mapStateToProps)(injectIntl(CSSModules(PointsRequestsTable, styles)));