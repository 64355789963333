import { defineMessages } from 'react-intl';

export const scope = 'cb.components.ReferralsInfo';

export default defineMessages({
  yourReferralsBlockHeading: {
    id: `${scope}.yourReferralsBlockHeading`,
    defaultMessage: 'Your Referrals',
  },
  youHaveNoReferralsText: {
    id: `${scope}.youHaveNoReferralsText`,
    defaultMessage: 'You have no referrals',
  },
  nameTableColumnHeading: {
    id: `${scope}.nameTableColumnHeading`,
    defaultMessage: 'Name',
  },
  signedDateTableColumnHeading: {
    id: `${scope}.signedDateTableColumnHeading`,
    defaultMessage: 'Signed Date',
  },
  numbersOfSignupsTableColumnHeading: {
    id: `${scope}.numbersOfSignupsTableColumnHeading`,
    defaultMessage: 'Numbers of Signups',
  },
  collectedPointsTableColumnHeading: {
    id: `${scope}.collectedPointsTableColumnHeading`,
    defaultMessage: 'Collected Points',
  },
  poinText: {
    id: `${scope}.poinText`,
    defaultMessage: '{ points, plural, one {# point} other {# points}}',
  },
});
