import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import { AppWrapper, LoginForm, OnlyFormPageWrapper } from 'components';
import { AuthorizedRedirect } from 'containers';
import { actions } from 'coupon-common/src/modules/auth';
import { dispatchAsAsync } from 'coupon-common/src/utils';

export const Login = ({ dispatch }) => {
  const onSubmit = async values => {
    const { email, password } = values;

    return dispatchAsAsync(actions.login(email, password), dispatch);
  };

  return (
    <AppWrapper showHeader={false} bgLight contentCentered>
      <OnlyFormPageWrapper forLogin>
        <AuthorizedRedirect />
        <Form onSubmit={onSubmit} component={LoginForm} />
      </OnlyFormPageWrapper>
    </AppWrapper>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(Login);
