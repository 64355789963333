import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import { Button, Modal } from 'components';

import styles from './CouponActionModalContainer.module.scss';

export const CouponActionModalContainer = ({
  isOpen,
  modalHeadingText,
  actionDescriptionText,
  cancelBtnText,
  confirmBtnText,
  onModalClose,
  onModalConfirm,
}) => {
  return (
    <Modal
      title={modalHeadingText}
      isOpen={isOpen}
      onRequestClose={onModalClose}
    >
      <div styleName="coupon-action-modal-container">
        <p>{actionDescriptionText}</p>
        <div className="d-flex justify-content-end">
          <Button outline onClick={onModalClose}>
            {cancelBtnText}
          </Button>
          <Button onClick={onModalConfirm}>{confirmBtnText}</Button>
        </div>
      </div>
    </Modal>
  );
};

CouponActionModalContainer.propTypes = {
  isOpen: PropTypes.bool,
  modalHeadingText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  actionDescriptionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  cancelBtnText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  confirmBtnText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onModalClose: PropTypes.func,
  onModalConfirm: PropTypes.func,
};

export default CSSModule(CouponActionModalContainer, styles);
