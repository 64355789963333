import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: {},
  error: null,
};

const { Types, Creators } = createActions(
  {
    getBusinessReferralProgram: [],
    getBusinessReferralProgramRequest: [],
    getBusinessReferralProgramSuccess: ['data'],
    getBusinessReferralProgramFailure: ['error'],
  },
  { prefix: 'cb/businessReferralProgram/' },
);

export { Types as types, Creators as actions };

export const getBusinessReferralProgramRequest = state => {
  return {
    ...state,
    fetching: true,
  };
};

export const getBusinessReferralProgramSuccess = (_, action) => {
  const { data } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    data,
  };
};

export const getBusinessReferralProgramFailure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    error,
  };
};

export const HANDLERS = {
  [Types.GET_BUSINESS_REFERRAL_PROGRAM_REQUEST]: getBusinessReferralProgramRequest,
  [Types.GET_BUSINESS_REFERRAL_PROGRAM_SUCCESS]: getBusinessReferralProgramSuccess,
  [Types.GET_BUSINESS_REFERRAL_PROGRAM_FAILURE]: getBusinessReferralProgramFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
