import { combineReducers } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions(
  {
    login: ['email', 'password', 'resolve'],
    logout: [],

    loginRequest: ['credentails'],
    loginSuccess: ['tokens'],
    loginFailure: ['error'],
  },
  { prefix: 'cb/auth/login/' },
);

const fetching = createReducer(false, {
  [types.LOGIN_REQUEST]: () => true,
  [types.LOGIN_SUCCESS]: () => false,
  [types.LOGIN_FAILURE]: () => false,
});

const credentails = createReducer(null, {
  [types.LOGIN_REQUEST]: (_, { credentails }) => credentails,
});

const error = createReducer(null, {
  [types.LOGIN_REQUEST]: () => null,
  [types.LOGIN_SUCCESS]: () => null,
  [types.LOGIN_FAILURE]: (_, { error }) => error,
});

export default combineReducers({
  fetching,
  credentails,
  error,
});
