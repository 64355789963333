import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  error: null,
  data: [],
};


const { Types, Creators } = createActions(
    {
      getPointsTransactions: [],
      getPointsTransactionsRequest: [],
      getPointsTransactionsSuccess: ['data'],
      getPointsTransactionsFailure: ['error'],
    },
    { prefix: 'cb/admin/pointsManager/pointsTransactions' },
);

export { Types as types, Creators as actions };

// Reducers
const request = state => ({
  ...state,
  fetching: true,
  fetched: false,
  error: null,
});

const success = (state, action) => ({
  ...state,
  fetching: false,
  fetched: true,
  data: action.data,
});

const failure = (state, action) => ({
  ...state,
  fetching: false,
  fetched: false,
  error: action.error,
});



export const HANDLERS = {
    [Types.GET_POINTS_TRANSACTIONS_REQUEST]: request,
    [Types.GET_POINTS_TRANSACTIONS_SUCCESS]: success,
    [Types.GET_POINTS_TRANSACTIONS_FAILURE]: failure,
};

  export default createReducer(INITIAL_STATE, HANDLERS);