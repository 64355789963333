import { authApiCall } from '../auth/saga';

export const createCoupon = data => {
  const formattedData = {
    ...data,
    ...(data.category ? { category: data.category.id } : {}),
  };
  return authApiCall({
    url: '/coupons/',
    method: 'post',
    data: formattedData,
  });
};

export const getCoupon = id =>
  authApiCall({
    url: `/coupons/${id}/`,
    method: 'get',
  });

export const editCoupon = (data, couponId) => {
  const formattedData = {
    ...data,
    ...(data.category ? { category: data.category.id } : {}),
  };
  return authApiCall({
    url: `/coupons/${couponId}/`,
    method: 'patch',
    data: formattedData,
  });
};
