import { authApiCall } from '../../auth/saga';

export const acceptCoupon = (couponId, data) => {
  return authApiCall({
    url: `/coupons/accept-reject-coupon/${couponId}/accept/`,
    method: 'post',
    data,
  });
};

export const rejectCoupon = couponId =>
  authApiCall({
    url: `/coupons/accept-reject-coupon/${couponId}/reject/`,
    method: 'post',
  });
