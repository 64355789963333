import { call, put, takeLatest } from 'redux-saga/effects';
import { actions, types } from './duck';
import { getPointsRequests } from './api';
import { OK } from 'http-status-codes';

function* getPointsRequestsSaga({ params }) {
  try {
    yield put(actions.getPointsRequestsRequest());

    const response = yield call(getPointsRequests, params.business);
    if (response.status === OK) {
      yield put(actions.getPointsRequestsSuccess(response.data));
    } else {
      yield put(actions.getPointsRequestsFailure(response.statusText));
    }
  } catch (error) {
    yield put(actions.getPointsRequestsFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_POINTS_REQUESTS, getPointsRequestsSaga);
}
