import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = ({locationArray}) => {
    const location = useLocation();

    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
            <div style={{ display: 'flex', gap: '5px', listStyle: 'none', textDecoration: 'none'}}>
                {pathnames.map((value, index) => {
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    return (
                        <div key={to} style={{ marginBottom: '10px', textTransform: 'capitalize', color: 'gray', fontWeight: 'bold', textDecoration: 'none', fontSize: '12px'}}>
                            <Link style={{padding: '2px 5px', textDecoration: 'none', color: 'crimson'}} to={to}>{locationArray ? locationArray[index] : value}</Link>
                            {index != pathnames.length-1 && (
                                <span> {'>'} </span>
                            )}
                        </div>
                    );
                })}
            </div>
    );
};

export default Breadcrumbs;
