import { CREATED, NO_CONTENT, NOT_FOUND, OK } from 'http-status-codes';
import { put, select, takeLatest } from 'redux-saga/effects';

import { safeResolveAsync } from '../../utils';
import { getPropFromNormalized } from '../../utils/redux';
import { extractParamsFromUrl } from '../../utils/saga';

import * as api from './api';
import * as duck from './duck';
import { actions, types } from './duck';

export function* getBusinessCoupons() {
  try {
    yield put(actions.getBusinessCouponsRequest());

    const response = yield api.getBusinessCoupons();

    if (response.status === OK) {
      yield put(actions.getBusinessCouponsSuccess(response.data));
    } else {
      yield put(actions.getBusinessCouponsFailure(response.data.detail));
    }
  } catch (error) {
    yield put(actions.getBusinessCouponsFailure(error.message));
  }
}

export function* getBusinessCouponsNextPage() {
  try {
    const nextPageUrl = yield select(state =>
      getPropFromNormalized(state.coupons, duck.couponsEntity.key, 'next'),
    );

    if (!nextPageUrl) {
      return;
    }

    const params = extractParamsFromUrl(nextPageUrl);

    yield put(actions.getBusinessCouponsNextPageRequest());

    const response = yield api.getBusinessCoupons(params);

    if (response.status === OK) {
      yield put(actions.getBusinessCouponsNextPageSuccess(response.data));
    } else if (response.status === NOT_FOUND) {
      // handle page not found for not to show errors and prevent further attempts to load next page
      yield put(actions.getBusinessCouponsNextPageNotFound());
    } else {
      yield put(actions.getBusinessCouponsNextPageFailure(response.data));
    }
  } catch (error) {
    yield put(actions.getBusinessCouponsNextPageFailure(error.message));
  }
}

export function* publishCoupon({ id, resolve }) {
  try {
    yield put(actions.publishCouponRequest(id));

    const response = yield api.publishCoupon(id);

    if (response.status === OK) {
      yield put(actions.publishCouponSuccess(response.data));
      yield safeResolveAsync(resolve, { success: true });
    } else {
      if (Array.isArray(response.data)) {
        throw Error(response.data[0]);
      } else {
        throw Error(response.data.detail);
      }
    }
  } catch (error) {
    yield put(actions.publishCouponFailure(error.message));
    yield safeResolveAsync(resolve, { success: false });
  }
}

export function* deleteCoupon({ id, resolve }) {
  try {
    yield put(actions.deleteCouponRequest(id));

    const response = yield api.deleteCoupon(id);

    if (response.status === NO_CONTENT) {
      yield put(actions.deleteCouponSuccess(id));
      yield safeResolveAsync(resolve, { success: true });
    } else {
      if (Array.isArray(response.data)) {
        throw Error(response.data[0]);
      } else {
        throw Error(response.data.detail);
      }
    }
  } catch (error) {
    yield put(actions.deleteCouponFailure(error.message));
    yield safeResolveAsync(resolve, { success: false });
  }
}

export function* sponsorCoupon({ id, resolve }) {
  try {
    yield put(actions.sponsorCouponRequest(id));

    const response = yield api.sponsorCoupon(id);

    if (response.status === OK) {
      yield put(actions.sponsorCouponSuccess(response.data));
      yield safeResolveAsync(resolve, { success: true });
    } else {
      if (Array.isArray(response.data)) {
        throw Error(response.data[0]);
      } else {
        throw Error(response.data.detail);
      }
    }
  } catch (error) {
    yield put(actions.sponsorCouponFailure(error.message));
    yield safeResolveAsync(resolve, { success: false });
  }
}

export function* republishCoupon({ data, resolve }) {
  try {
    yield put(actions.republishCouponRequest(data.id));

    const response = yield api.republishCoupon(data);
    console.log("PW Reponse is ", response);

    if (response.status === CREATED) {
      yield put(actions.republishCouponSuccess(response.data));
      yield safeResolveAsync(resolve, { success: true });
    } else {
      if (Array.isArray(response.data)) {
        throw Error(response.data[0]);
      } else {
        throw Error(response.data.detail);
      }
    }
  } catch (error) {
    yield put(actions.republishCouponFailure(error.message));
    yield safeResolveAsync(resolve, { success: false });
  }
}


export function* getViewCounts({ couponIds }) {
  try {
    console.log("Saga: Received couponIds:", couponIds);
    yield put(actions.getViewCountsRequest());

    const response = yield api.getViewCounts(couponIds);
    console.log("Saga: API response:", response);

    if (response.status === OK) {
      console.log("Saga: Dispatching success with data:", response.data);
      yield put(actions.getViewCountsSuccess(response.data));
    } else {
      console.error("Saga: API failed with:", response.data.detail);
      yield put(actions.getViewCountsFailure(response.data.detail));
    }
  } catch (error) {
    console.error("Saga: Error in getViewCounts:", error.message);
    yield put(actions.getViewCountsFailure(error.message));
  }
}



export default function* saga() {
  yield takeLatest(types.GET_BUSINESS_COUPONS, getBusinessCoupons);
  yield takeLatest(
    types.GET_BUSINESS_COUPONS_NEXT_PAGE,
    getBusinessCouponsNextPage,
  );
  yield takeLatest(types.PUBLISH_COUPON, publishCoupon);
  yield takeLatest(types.DELETE_COUPON, deleteCoupon);
  yield takeLatest(types.SPONSOR_COUPON, sponsorCoupon);
  yield takeLatest(types.REPUBLISH_COUPON, republishCoupon);
  yield takeLatest(types.GET_VIEW_COUNTS, getViewCounts);
}


