import { FORM_ERROR } from 'final-form';
import { CREATED } from 'http-status-codes';
import { put, select, takeLatest } from 'redux-saga/effects';

import { formatApiErrors, safeResolveAsync } from '../../utils/saga';
import { loginSaga } from '../auth/login/saga';

import * as api from './api';
import { actions, types } from './duck';

export function* signUp({ formValues, resolve }) {
  try {
    const marketer_referral_code = yield select(
      state => state.referralCodes.businessCode,
    );

    const referral_code = yield select(
      state => state.referralCodes.codeGenerator.code,
    );

    const patchedData = {
      ...formValues,
      marketer_referral_code,
      referral_code,
    };

    yield put(actions.signUpRequest(patchedData));

    const response = yield api.signUp(patchedData);

    if (response.status === CREATED) {
      const { email, password } = patchedData;

      yield loginSaga({ email, password });

      yield put(actions.signUpSuccess());
      yield safeResolveAsync(resolve);
    } else {
      yield put(actions.signUpFailure(response.data));
      yield safeResolveAsync(resolve, formatApiErrors(response.data));
    }
  } catch (error) {
    yield put(actions.signUpFailure(error.message));
    yield safeResolveAsync(resolve, { [FORM_ERROR]: error.message });
  }
}

export default function* saga() {
  yield takeLatest(types.SIGN_UP, signUp);
}
