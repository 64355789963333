import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const AuthProtected = ({ authorized }) => {
  if (!authorized) {
    return <Redirect to="/login" />;
  }
  return null;
};

AuthProtected.propTypes = {
  authorised: PropTypes.bool,
};

const mapStateToProps = state => ({
  authorized: state.auth.authorized,
});

export default connect(mapStateToProps)(AuthProtected);
