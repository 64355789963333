import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { injectIntl } from 'react-intl';

import styles from './AccountMenu.module.scss';
import messages from './messages';
import { ReactComponent as PersonIcon } from './PersonIcon.svg';

export const AccountMenu = ({ intl, children, userName, logo }) => {
  const [opened, setOpened] = useState(false);

  const closeMenu = useCallback(() => {
    setOpened(false);
  }, [setOpened]);

  useEffect(() => {
    if (opened) {
      document.addEventListener('click', closeMenu);
    } else {
      document.removeEventListener('click', closeMenu);
    }
    return () => document.removeEventListener('click', closeMenu);
  }, [opened, closeMenu]);

  const toggleOpened = () => {
    setOpened(!opened);
  };

  const renderLogo = () => {
    if (logo) {
      return (
        <img
          className="account-logo"
          src={logo}
          alt={intl.formatMessage(messages.logoImageAlt)}
        />
      );
    }
    return <PersonIcon />;
  };

  return (
    <div className="account-menu dropdown">
      <button
        className={cx('btn btn-link', { hidden: opened })}
        onClick={toggleOpened}
      >
        {renderLogo()}
      </button>
      <div
        className={cx('dropdown-menu dropdown-menu-right', { show: opened })}
      >
        <div className="dropdown-header d-flex justify-content-between align-items-center">
          <strong>{userName}</strong>
          {renderLogo()}
        </div>
        <div className="dropdown-divider"></div>
        {children}
      </div>
    </div>
  );
};

AccountMenu.propTypes = {
  intl: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  userName: PropTypes.string,
  logo: PropTypes.string,
};

export default injectIntl(CSSModule(AccountMenu, styles));
