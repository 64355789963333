import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { Field, FormSpy, useForm } from 'react-final-form';
import { OnBlur, OnChange } from 'react-final-form-listeners';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { FormDatePicker, FormField, FormRadio, FormSwitch } from 'components';
import { CouponCategoryField } from 'containers';
import { CANADIAN_DOLLAR_CODE, DOLLAR_SIGN } from 'coupon-common';
import {
  composeSyncValidators,
  formatToISOString,
  isDecimal,
  isInteger,
  isNonNegative,
  isPositive,
  maxLength,
  maxValue,
  normalizeDecimal,
  normalizeInteger,
  parseISOString,
  required,
} from 'coupon-common/src/utils';

import styles from './CouponFieldGroup.module.scss';
import messages from './messages';

const COUPON_TITLE_MAX_LENGTH = 36;
const PERCENTAGE_OFF_MAX_LIMIT = 100;

const COUPON_TYPES = {
  amountOff: 'amount_off',
  pointsBack: 'points_back',
  percentageOff: 'percentage_off',
};

const COUPON_EXPIRY_TYPES = {
  amountLimit: 'amount_limit',
  date: 'date',
};

//Todo These styles are taken from scss file since, 'styleName' prop
//Todo is not detecting in dom elements when renderd conditionally.
//Todo Must change to suitable way if any.
const taxRatesGridContainerStyle = {
  width: '80%',
};
const leftGridItemStyle = {
  width: '50%',
  paddingRight: '0.6rem',
};
const rightGridItemStyle = {
  width: '50%',
  paddingLeft: '0.6rem',
};

export const CouponFieldGroup = ({
  intl,
  locale,
  readOnly,
  taxRates,
  onPriceBlur,
  businessData,
}) => {
  const { user_limit } = businessData;

  const form = useForm();

  useEffect(() => {
    form.change('pst', taxRates?.pst);
    form.change('gst', taxRates?.gst);
    form.change('hst', taxRates?.hst);
    form.change('qst', taxRates?.qst);
    form.change('rst', taxRates?.rst);
  }, [form, taxRates]);

  const renderCouponTypeField = type => {
    if (type === COUPON_TYPES.amountOff) {
      return (
        <>
          <Field
            name="amount_off"
            label={intl.formatMessage(messages.amountOffFieldLabel)}
            validate={composeSyncValidators(required, isPositive, isDecimal)}
            component={FormField}
            format={normalizeDecimal}
            formatOnBlur
            readOnly={readOnly}
          />
          <FormSpy
            render={({ values }) => (
              <OnBlur name="price">{() => onPriceBlur(values)}</OnBlur>
            )}
          />
        </>
      );
    } else if (type === COUPON_TYPES.pointsBack) {
      return (
        <Field
          name="points_back"
          label={intl.formatMessage(messages.pointsBackFieldLabel)}
          validate={composeSyncValidators(required, isPositive, isInteger)}
          component={FormField}
          parse={normalizeInteger}
          readOnly={readOnly}
        />
      );
    } else if (type === COUPON_TYPES.percentageOff) {
      return (
        <>
          <Field
            name="percentage_off"
            label={intl.formatMessage(messages.percentageOffFieldLabel)}
            validate={composeSyncValidators(
              required,
              isPositive,
              isInteger,
              maxValue(PERCENTAGE_OFF_MAX_LIMIT),
            )}
            component={FormField}
            parse={normalizeInteger}
            readOnly={readOnly}
          />
          <FormSpy
            render={({ values }) => (
              <OnBlur name="price">{() => onPriceBlur(values)}</OnBlur>
            )}
          />
        </>
      );
    }

    return null;
  };

  const renderCouponExpiryTypeField = expiry_type => {
    if (expiry_type === COUPON_EXPIRY_TYPES.amountLimit) {
      return (
        <Field
          name="expiration_quantity"
          label={intl.formatMessage(messages.expirationQuantityFieldLabel)}
          validate={composeSyncValidators(required, isPositive, isInteger)}
          component={FormField}
          parse={normalizeInteger}
          readOnly={readOnly}
        />
      );
    } else if (expiry_type === COUPON_EXPIRY_TYPES.date) {
      return (
        <Field
          name="expiration_date"
          label={intl.formatMessage(messages.expirationDateFieldLabel)}
          validate={required}
          format={value => parseISOString(value)}
          parse={value => formatToISOString(value)}
          locale={locale}
          component={FormDatePicker}
          readOnly={readOnly}
        />
      );
    }

    return null;
  };

  const renderPriceField = show_price => {
    if (show_price)
      return (
        <>
          <Field
            name="price"
            label={intl.formatMessage(messages.priceFieldLabel)}
            sideLabel={`${CANADIAN_DOLLAR_CODE} ${DOLLAR_SIGN}`}
            validate={composeSyncValidators(required, isPositive, isDecimal)}
            component={FormField}
            format={normalizeDecimal}
            formatOnBlur
            readOnly={readOnly}
          />
          <FormSpy
            render={({ values }) => (
              <OnBlur name="price">{() => onPriceBlur(values)}</OnBlur>
            )}
          />
        </>
      );
    return null;
  };

  const renderTaxFields = showPrice => {
    if (showPrice)
      return (
        <>
          <FormattedMessage
            tagName="p"
            className="text-muted"
            {...messages.taxRatesFieldsGroupDescription}
          />
          <div
            // styleName="tax-rates-grid-container"
            style={taxRatesGridContainerStyle}
            className="d-flex flex-wrap"
          >
            <div
              // styleName="left-grid-item"
              style={leftGridItemStyle}
            >
              <Field
                name="pst"
                label={intl.formatMessage(messages.pstFieldLabel)}
                validate={composeSyncValidators(isNonNegative, isDecimal)}
                component={FormField}
                format={normalizeDecimal}
                formatOnBlur
                readOnly={readOnly}
              />
            </div>
            <div
              // styleName="left-grid-item"
              style={rightGridItemStyle}
            >
              <Field
                name="gst"
                label={intl.formatMessage(messages.gstFieldLabel)}
                validate={composeSyncValidators(isNonNegative, isDecimal)}
                component={FormField}
                format={normalizeDecimal}
                formatOnBlur
                readOnly={readOnly}
              />
            </div>
            <div
              // styleName="left-grid-item"
              style={leftGridItemStyle}
            >
              <Field
                name="hst"
                label={intl.formatMessage(messages.hstFieldLabel)}
                validate={composeSyncValidators(isNonNegative, isDecimal)}
                component={FormField}
                format={normalizeDecimal}
                formatOnBlur
                readOnly={readOnly}
              />
            </div>
            <div
              // styleName="left-grid-item"
              style={rightGridItemStyle}
            >
              <Field
                name="qst"
                label={intl.formatMessage(messages.qstFieldLabel)}
                validate={composeSyncValidators(isNonNegative, isDecimal)}
                component={FormField}
                format={normalizeDecimal}
                formatOnBlur
                readOnly={readOnly}
              />
            </div>
            <div
              // styleName="left-grid-item"
              style={leftGridItemStyle}
            >
              <Field
                name="rst"
                label={intl.formatMessage(messages.rstFieldLabel)}
                validate={composeSyncValidators(isNonNegative, isDecimal)}
                component={FormField}
                format={normalizeDecimal}
                formatOnBlur
                readOnly={readOnly}
              />
            </div>
          </div>
        </>
      );
    return null;
  };




  return (
    <div className="row">
      <div className="col-md-12">
        <FormattedMessage tagName="h5" {...messages.createCouponTitle} />
        <Field
          name="payment_by_points"
          type="checkbox"
          label={intl.formatMessage(messages.paymentByPointsFieldLabel)}
          component={FormSwitch}
          readOnly={readOnly}
        />
        <Field
          name="auto_accept"
          type="checkbox"
          label={intl.formatMessage(messages.autoAcceptFieldLabel)}
          component={FormSwitch}
          readOnly={readOnly}
        />
        <h6 className="pt-2" style={{ marginBottom: 0}}>
        <FormattedMessage {...messages.exclusiveTitle} />
        </h6>
        <FormattedMessage
            tagName="p"
            className="text-muted"
            {...messages.exclusiveDescriptionLabel}
          />
        <Field
          name="is_exclusive_paid"
          type="checkbox"
          label={intl.formatMessage(messages.isExclusivePaid)}
          component={FormSwitch}
          readOnly={readOnly}
        />
        <Field
          name="is_exclusive_followers"
          type="checkbox"
          label={intl.formatMessage(messages.isExclusiveFollowers)}
          component={FormSwitch}
          readOnly={readOnly}
        />
        <h6 className="pt-2">
          <FormattedMessage {...messages.couponTypeFieldsGroupLabel} />
        </h6>
        <div className="d-flex">
          <Field
            name="type"
            value="amount_off"
            type="radio"
            label={intl.formatMessage(messages.amountOffTypeFieldLabel)}
            component={FormRadio}
            readOnly={readOnly}
          />
          <Field
            name="type"
            value="percentage_off"
            type="radio"
            label={intl.formatMessage(messages.percentageOffTypeFieldLabel)}
            component={FormRadio}
            readOnly={readOnly}
          />
        </div>
        <div className="d-flex">
          <Field
            name="type"
            value="points_back"
            type="radio"
            label={intl.formatMessage(messages.pointsBackTypeFieldLabel)}
            component={FormRadio}
            readOnly={readOnly}
          />
        </div>
        <div styleName="coupon-type-value-container">
          <FormSpy
            subscription={{ values: true }}
            render={({ values: { type } }) => renderCouponTypeField(type)}
          />
        </div>
        <FormSpy
          render={({ form }) => (
            <OnChange name="type">
              {value => {
                if (value === COUPON_TYPES.amountOff) {
                  form.change('points_back', null);
                }
                if (value === COUPON_TYPES.pointsBack) {
                  form.change('amount_off', null);
                }
              }}
            </OnChange>
          )}
        />
        <h6 className="pt-2">
          <FormattedMessage
            {...messages.couponTitleAndDescriptionFieldsGroupLabel}
          />
        </h6>
        <Field
          name="title"
          label={intl.formatMessage(messages.titleFieldLabel)}
          validate={composeSyncValidators(
            required,
            maxLength(COUPON_TITLE_MAX_LENGTH),
          )}
          component={FormField}
          readOnly={readOnly}
        />
        <Field
          name="description"
          label={intl.formatMessage(messages.descriptionFieldLabel)}
          validate={composeSyncValidators(required, maxLength(300))}
          isTextArea
          component={FormField}
          readOnly={readOnly}
        />
        <h6 className="pt-2">
          <FormattedMessage {...messages.expiryTypeFieldsGroupLabel} />
        </h6>
        <div className="d-flex">
          <Field
            name="expiry_type"
            value="amount_limit"
            type="radio"
            label={intl.formatMessage(messages.expiryTypeAmountLimitValueLabel)}
            component={FormRadio}
            readOnly={readOnly}
          />
          <Field
            name="expiry_type"
            value="date"
            type="radio"
            label={intl.formatMessage(messages.expiryTypeDateValueLabel)}
            component={FormRadio}
            readOnly={readOnly}
          />
        </div>
        <FormSpy
          subscription={{ values: true }}
          render={({ values: { expiry_type } }) =>
            renderCouponExpiryTypeField(expiry_type)
          }
        />
        <FormSpy
          render={({ form }) => (
            <OnChange name="expiry_type">
              {value => {
                if (value === COUPON_EXPIRY_TYPES.date) {
                  form.change('expiration_quantity', null);
                } else if (value === COUPON_EXPIRY_TYPES.amountLimit) {
                  form.change('expiration_date', null);
                }
              }}
            </OnChange>
          )}
        />
        <h6 className="pt-2">
          <FormattedMessage {...messages.userLimitFieldsGroupLabel} />
        </h6>
        <Field
          name="user_limit"
          label={intl.formatMessage(messages.userLimitFieldLabel)}
          validate={composeSyncValidators(isPositive, isInteger)}
          component={FormField}
          parse={normalizeInteger}
          readOnly={readOnly || !user_limit}
        />
        {!user_limit && (
          <p styleName="field_status_text">
            Please upgrade your plan to use this feature
          </p>
        )}
        <h6 className="pt-2">
          <FormattedMessage {...messages.reservedHoursFieldsGroupLabel} />
        </h6>
        <Field
          name="reserved_hours"
          label={intl.formatMessage(messages.reservedHoursFieldLabel)}
          validate={composeSyncValidators(isPositive, isDecimal)}
          component={FormField}
          format={normalizeDecimal}
          formatOnBlur
          readOnly={readOnly}
        />
        <h6 className="pt-2">
          <FormattedMessage {...messages.categoryFieldsGroupLabel} />
        </h6>
        <CouponCategoryField />
        <h6 className="pt-2">
          <FormattedMessage {...messages.couponTandCFieldsGroupLabel} />
        </h6>
        <Field
          name="terms_and_conditions"
          label={intl.formatMessage(messages.couponTandCFieldLabel)}
          validate={composeSyncValidators(maxLength(300))}
          isTextArea
          component={FormField}
          readOnly={readOnly}
        />
        <h6 className="pt-2">
          <FormattedMessage {...messages.priceFieldsGroupLabel} />
        </h6>
        <div styleName="coupon-price-container">
          <Field
            name="show_price_on_coupon"
            type="checkbox"
            label={intl.formatMessage(messages.showPriceFieldLabel)}
            component={FormSwitch}
            readOnly={readOnly}
          />
          <FormSpy
            subscription={{ values: true }}
            render={({ values: { show_price_on_coupon } }) =>
              renderPriceField(show_price_on_coupon)
            }
          />
        </div>
        <FormSpy
          subscription={{ values: true }}
          render={({ values: { show_price_on_coupon } }) =>
            renderTaxFields(show_price_on_coupon)
          }
        />
      </div>
    </div>
  );
};

CouponFieldGroup.propTypes = {
  intl: PropTypes.object,
  locale: PropTypes.string,
  readOnly: PropTypes.bool,
};

const mapStateToProps = state => ({
  locale: state.language.locale,
});

export default connect(mapStateToProps)(
  injectIntl(CSSModule(CouponFieldGroup, styles)),
);
