import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: {},
  error: null,
};

const { Types, Creators } = createActions(
  {
    updateBusinessUser: ['id', 'updatedData'],
    updateBusinessUserRequest: [],
    updateBusinessUserSuccess: ['data'],
    updateBusinessUserFailure: ['error'],

    updateCustomerUser: ['id', 'updatedData'],
    updateCustomerUserRequest: [],
    updateCustomerUserSuccess: ['data'],
    updateCustomerUserFailure: ['error'],
  },
  { prefix: 'cb/admin/userManager' },
);

export { Types as types, Creators as actions };

export const request = state => ({
  ...state,
  fetching: true,
  fetched: false,
  data: {},
  error: null,
});

export const success = (state, action) => {
  const { data } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    data,
  };
};

export const failure = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetching: false,
    fetched: true,
    error,
  };
};


export const HANDLERS = {
  [Types.UPDATE_BUSINESS_USER_REQUEST]: request,
  [Types.UPDATE_BUSINESS_USER_SUCCESS]: success,
  [Types.UPDATE_BUSINESS_USER_FAILURE]: failure,

  [Types.UPDATE_CUSTOMER_USER_REQUEST]: request,
  [Types.UPDATE_CUSTOMER_USER_SUCCESS]: success,
  [Types.UPDATE_CUSTOMER_USER_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
