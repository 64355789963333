import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_POINTS_STATE = {
  fetching: false,
  fetched: false,
  error: null,
  data: null,
};


const { Types, Creators } = createActions(
    {
      updatePoints: ["to_user_id", "amount", "requestId", "requestStatus"],
      updatePointsRequest: [],
      updatePointsSuccess: ['data'],
      updatePointsFailure: ['error'],
    },
    { prefix: 'cb/pointsRequests/updatePoints' },
);

export { Types as types, Creators as actions };

// Reducers
const request = state => ({
  ...state,
  fetching: true,
  error: null,
  data: null
});

const success = (state, action) => ({
  ...state,
  fetching: false,
  fetched: true,
  data: action.data,
});

const failure = (state, action) => ({
  ...state,
  fetching: false,
  fetched: false,
  error: action.error,
});


export const POINTS_HANDLERS = {
    [Types.UPDATE_POINTS_REQUEST]: request,
    [Types.UPDATE_POINTS_SUCCESS]: success,
    [Types.UPDATE_POINTS_FAILURE]: failure,
};

const pointsReducer = createReducer(INITIAL_POINTS_STATE, POINTS_HANDLERS);

export default pointsReducer;