import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { actions } from 'coupon-common/src/modules/theme';

import messages from './messages';

/* This component in draft state */
const ThemeToggle = ({ selected, available, changeTheme, intl }) => {
  const options = available.map(value => (
    <option key={value} value={value}>
      {intl.formatMessage(messages[value])}
    </option>
  ));

  return (
    <div className="form-group">
      <select className="form-control" value={selected} onChange={changeTheme}>
        {options}
      </select>
    </div>
  );
};

ThemeToggle.propTypes = {
  changeTheme: PropTypes.func,
  available: PropTypes.array,
  selected: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  locale: state.language.locale,
  selected: state.theme.selected,
  available: state.theme.available,
});

const mapDispatchToProps = dispatch => {
  return {
    changeTheme: e => dispatch(actions.changeTheme(e.target.value)),
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ThemeToggle));
