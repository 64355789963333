export const BYTES_IN_MB = 1000000;
export const MICROSECONDS_IN_MILLISECOND = 1000;

export const DEFAULT_DEBOUNCE_TIME_MS = 900;
export const SHORT_DEBOUNCE_TIME_MS = 200;
export const ACTION_WAIT_DEBOUNCE_TIME_MS = 10;
export const DEFAULT_TEXT_FIELD_LENGTH = 100;
export const DEFAULT_PHONE_FIELD_MASK = '(999) 999-9999';
export const DEFAULT_CHECK_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const DEFAULT_MIN_PASSWORD_LENGTH = 8;
export const DEFAULT_MAX_PASSWORD_LENGTH = 64;
export const DEFAULT_CHECK_PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*\d).*$/;
export const DEFALUT_ACCEPTING_IMG_FORMATS = 'image/jpeg, image/png';
export const DEFAULT_NUMERAL_FORMATTING_MASK = '0.00';
export const DEFAULT_INTL_FORMAT_NUMBER_CONFIG = {
  style: 'currency',
  currency: 'USD',
};
export const EMPTY_INPUT_VAlUE = '';
export const MAX_INTEGER_VALUE = 999999;
export const MAX_SYMBOLS_BEFORE_DECIMAL_POINT = 6;
export const DEFAULT_DECIMAL_ROUNDING_PRECISION = 2;
export const INTEGER_FIELD_REGEX = /[^\d]/;
export const DECIMAL_FIELD_REGEX = new RegExp(
  `^[0-9]{1,${MAX_SYMBOLS_BEFORE_DECIMAL_POINT}}(?:\\.[0-9]{0,${DEFAULT_DECIMAL_ROUNDING_PRECISION}})?$`,
);
export const DOLLAR_SIGN = '$';
export const CANADIAN_DOLLAR_CODE = 'CAD';

export const TOKENS_LOCAL_STORAGE_KEY = 'tokens';

export const CARD_REGEX = {
  AMERICAN_EXPRESS: /^(?:3[47][0-9]{13})$/,
  VISA: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
  MASTER_CARD:
    /^(5[1-5]\d{4}|677189)\d{10}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/,
};

export const MOTH_YEAR_CARD_SPLITTER = '/';

export const VALID_AMERICAN_EXPRESS_REGEX = new RegExp(
  CARD_REGEX.AMERICAN_EXPRESS,
);
export const VALID_CARD_REGEX = new RegExp(
  `${CARD_REGEX.AMERICAN_EXPRESS.source}|${CARD_REGEX.VISA.source}|${CARD_REGEX.MASTER_CARD.source}`,
);

export const SPACE_REGEX = /\s/g;

export const AMERICAN_EXPRESS_CVC_LENGTH = 4;
export const DEFAULT_CVC_LENGTH = 3;

export const NUMBER_OF_MONTH_IN_YEAR = 12;

export const COUPON_STATES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  EXPIRED: 'expired',
};
export const COUPON_EXPIRY_TYPES = {
  AMOUNT_LIMIT: 'amount_limit',
  DATE: 'date',
};
export const COUPON_TYPES = {
  AMOUNT_OFF: 'amount_off',
  POINTS_BACK: 'points_back',
  PERCENTAGE_OFF: 'percentage_off',
};
export const COUPON_TEMPLATE_DATE_FORMAT_PATTERN = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};
export const COUPON_LIST_DATE_FORMAT_PATTERN = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};
export const DEFAULT_ANIMATION_OPACITY_VALUES = {
  VISIBLE: 1,
  INVISIBLE: 0,
};

export const USED_COUPON_STATES = {
  WAITING: 'waiting',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  FAILED: 'failed',
  CANCELED: 'canceled',
};

export const KEY_CODES = {
  ARROW_DOWN: 40,
  ARROW_UP: 38,
  ENTER: 13,
};

export const COUPON_IMAGES_FIELD_NAME_PREFIX = 'c_image_';
