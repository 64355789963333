import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { apiCall, isTokenExpired } from '../../utils';
import * as loginModule from './login';
import * as refreshModule from './refresh';
import * as tokensModule from './tokens';

// Helpers provided by auth modules
export function takeLogout(gen, ...args) {
  return takeLatest(loginModule.types.LOGOUT, gen, ...args);
}

function* takeAuthSaga(gen, ...args) {
  const { SET_TOKENS, CLEAR_TOKENS } = tokensModule.types;

  while (true) {
    const accessToken = yield select(state => state.auth.tokens.access);
    const catchedAction = yield take([SET_TOKENS, CLEAR_TOKENS]);

    if (
      catchedAction.type === SET_TOKENS &&
      !accessToken &&
      catchedAction.tokens.access
    ) {
      yield fork(gen, ...args);
    }
  }
}

export function takeAuth(gen, ...args) {
  return fork(takeAuthSaga, gen, args);
}

export function* authApiCall(config) {
  const { authorized, tokens } = yield select(state => state.auth);

  if (!authorized) {
    return apiCall(config);
  }

  // Access token expiration timestamp in microsecconds.
  const accessTokenExp = tokens.accessDecoded.exp;
  const accessTokenExpired = isTokenExpired(accessTokenExp);

  if (accessTokenExpired) {
    yield put(refreshModule.actions.refreshAuth());

    const { failure } = yield race({
      success: take(refreshModule.types.REFRESH_AUTH_SUCCESS),
      failure: take(refreshModule.types.REFRESH_AUTH_FAILURE),
      // TODO: Could be added timeout here to not block execution
      // in case of REFRESH_AUTH not responding(or not implemented).
    });

    if (failure) {
      yield put(loginModule.actions.logout());

      // Bypassing auth.
      return yield apiCall(config);
    }
  }

  const accessToken = yield select(state => state.auth.tokens.access);

  const response = yield apiCall({
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${accessToken}` },
  });

  return response;
}
