import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Field, Form, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Button,
  FormDatePicker,
  FormField,
  FormRadio,
  Modal,
} from 'components';
import { COUPON_EXPIRY_TYPES } from 'coupon-common';
import {
  actions as couponBuilderActions,
  duck as couponBuilderDuck,
} from 'coupon-common/src/modules/couponBuilder';
import { actions } from 'coupon-common/src/modules/coupons';
import {
  composeSyncValidators,
  dispatchAsAsync,
  formatToISOString,
  isInteger,
  isPositive,
  normalizeInteger,
  parseISOString,
  required,
} from 'coupon-common/src/utils';

import messages from './messages';

const REPUBLISH_FORM_INITIAL_VALUES = { expiry_type: 'amount_limit' };

// displaying field errors after submitting the form to maintain size of the form while user filling it
const computeFieldDisplayError = meta =>
  !!((meta.error || meta.submitError) && meta.submitFailed);

export const CouponRepublish = ({
  intl,
  dispatch,
  locale,
  couponBuilderState,
  republishCouponId,
  isOpen,
  closeRepublishCouponHandler,
}) => {
  useEffect(() => {
    dispatch(couponBuilderActions.getCoupon(republishCouponId));
  }, [dispatch, republishCouponId]);

  const { data: republishCouponData, fetching: republishCouponDataFetching } =
    couponBuilderState[republishCouponId] ||
    couponBuilderDuck.INITIAL_COUPON_ITEM_STATE;

  console.log({ republishCouponData });

  const handleRepublish = async data => {
    closeRepublishCouponHandler();
    const { success } = await dispatchAsAsync(
      actions.republishCoupon({ ...republishCouponData, ...data }),
      dispatch,
    );
    if (success) {
      toast(
        <FormattedMessage
          {...messages.couponRepublishSuccessText}
          values={{
            title: <b>{republishCouponData.title}</b>,
          }}
        />,
      );
    }
  };

  const renderCouponExpiryTypeField = expiry_type => {
    if (expiry_type === COUPON_EXPIRY_TYPES.AMOUNT_LIMIT) {
      return (
        <Field
          name="expiration_quantity"
          label={intl.formatMessage(messages.expirationQuantityFieldLabel)}
          validate={composeSyncValidators(required, isPositive, isInteger)}
          component={FormField}
          parse={normalizeInteger}
          readOnly={republishCouponDataFetching}
          computeDisplayError={computeFieldDisplayError}
        />
      );
    }
    if (expiry_type === COUPON_EXPIRY_TYPES.DATE) {
      return (
        <Field
          name="expiration_date"
          label={intl.formatMessage(messages.expirationDateFieldLabel)}
          validate={required}
          format={value => parseISOString(value)}
          parse={value => formatToISOString(value)}
          locale={locale}
          component={FormDatePicker}
          readOnly={republishCouponDataFetching}
          computeDisplayError={computeFieldDisplayError}
        />
      );
    }
    return null;
  };

  return (
    <Modal
      title={intl.formatMessage(messages.pageHeadingText)}
      isOpen={isOpen}
      onRequestClose={closeRepublishCouponHandler}
    >
      <Form
        onSubmit={handleRepublish}
        initialValues={REPUBLISH_FORM_INITIAL_VALUES}
        render={({ handleSubmit }) => (
          <>
            <h6 className="pt-2">
              <FormattedMessage {...messages.couponTypeFieldsGroupLabel} />
            </h6>
            <div className="d-flex">
              <Field
                name="expiry_type"
                value="amount_limit"
                type="radio"
                label={intl.formatMessage(messages.expiryTypeAmountLimitLabel)}
                component={FormRadio}
                readOnly={republishCouponDataFetching}
              />
              <Field
                name="expiry_type"
                value="date"
                type="radio"
                label={intl.formatMessage(messages.expiryTypeDateLabel)}
                component={FormRadio}
                readOnly={republishCouponDataFetching}
              />
            </div>
            <FormSpy
              subscription={{ values: true }}
              render={({ values: { expiry_type } }) =>
                renderCouponExpiryTypeField(expiry_type)
              }
            />
            <FormSpy
              render={({ form }) => (
                <OnChange name="expiry_type">
                  {value => {
                    if (value === COUPON_EXPIRY_TYPES.DATE) {
                      form.change('expiration_quantity', null);
                    } else if (value === COUPON_EXPIRY_TYPES.AMOUNT_LIMIT) {
                      form.change('expiration_date', null);
                    }
                  }}
                </OnChange>
              )}
            />
            <Button
              onClick={handleSubmit}
              fetching={republishCouponDataFetching}
            >
              <FormattedMessage {...messages.republishButtonText} />
            </Button>
          </>
        )}
      />
    </Modal>
  );
};

CouponRepublish.propTypes = {
  intl: PropTypes.object,
  dispatch: PropTypes.func,
  locale: PropTypes.string,
  couponBuilderState: PropTypes.object,
  republishCouponId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeRepublishCouponHandler: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  couponBuilderState: state.couponBuilder,
  locale: state.language.locale,
});

export default connect(mapStateToProps)(injectIntl(CouponRepublish));
