import { call, put, takeLatest } from 'redux-saga/effects';
import { actions, types } from './duck';
import { getAllUsers, updatePoints } from './api';
import { OK } from 'http-status-codes';

function* getAllUsersSaga() {
  try {
    yield put(actions.getAllUsersRequest());

    const response = yield call(getAllUsers);
    if (response.status === OK) {
      yield put(actions.getAllUsersSuccess(response.data));
    } else {
      yield put(actions.getAllUsersFailure(response.statusText));
    }
  } catch (error) {
    yield put(actions.getAllUsersFailure(error.message));
  }
}

function* updatePointsSaga({to_user_id, amount, notes}) {

  try {
    yield put(actions.updatePointsRequest());

    const response = yield call(updatePoints, to_user_id, amount, notes);
    if (response.status === OK) {
      yield put(actions.updatePointsSuccess(response.data));
    } else {
      yield put(actions.updatePointsFailure(response.statusText));
    }
  } catch (error) {
    yield put(actions.updatePointsFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_ALL_USERS, getAllUsersSaga);

  yield takeLatest(types.UPDATE_POINTS, updatePointsSaga);
}
