import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
  AppWrapper,
  AppWrapperHeading,
  Feedback,
  MarketerInfo,
  ReferralsInfo,
  ShareReferralCode,
  Spinner,
  TotalPointsEarned,
} from 'components';
import { AuthProtected } from 'containers';
import { actions } from 'coupon-common/src/modules/businessReferralProgram';

import messages from './messages';

export const ReferralProgram = ({
  dispatch,
  data,
  fetching,
  fetched,
  error,
  referralInfo,
  intl,
}) => {
  useEffect(() => {
    dispatch(actions.getBusinessReferralProgram());
  }, [dispatch]);

  const pointsEarnPerYear =
    referralInfo.data && referralInfo.data.first_level_referral_points;

  return (
    <AppWrapper
      showHeader
      showSideNav
      showHeadingRow
      headingRowRender={() => (
        <>
          <AppWrapperHeading
            headingText={intl.formatMessage(
              messages.referralProgramHeadingText,
            )}
          />
          <div className="col-4">
            <div className="row mt-2 mr-2 h-100 justify-content-end align-items-center">
              <Spinner show={fetching} justify="end" />
              <Feedback
                invalid
                fullWidth={false}
                show={Boolean(!fetching && error)}
                content={error}
              />
            </div>
          </div>
        </>
      )}
    >
      <AuthProtected />
      <div className="col-md-12">
        <ShareReferralCode code={data.referral_code} />
        {fetched && !error && (
          <>
            <div className="row">
              <div className="col-md-6">
                <TotalPointsEarned
                  pointsCount={data.points_earned}
                  pointsEarnPerYear={pointsEarnPerYear}
                />
              </div>
              <div className="col-md-6">
                <MarketerInfo data={data.marketer} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ReferralsInfo data={data.referrals} />
              </div>
            </div>
          </>
        )}
      </div>
    </AppWrapper>
  );
};

ReferralProgram.propTypes = {
  fetching: PropTypes.bool,
  fetched: PropTypes.bool,
  data: PropTypes.object,
  error: PropTypes.string,
  referralInfo: PropTypes.object,
  dispatch: PropTypes.func,
  intl: PropTypes.object,
};

const mapStateToProps = state => ({
  fetching: state.businessReferralProgram.fetching,
  fetched: state.businessReferralProgram.fetched,
  data: state.businessReferralProgram.data,
  error: state.businessReferralProgram.error,
  referralInfo: state.memberships.referralInfo,
});

export default connect(mapStateToProps)(injectIntl(ReferralProgram));
