import { AppWrapper, Block, Button, Modal, Spinner } from 'components';
import AuthProtected from 'containers/AuthProtected/AuthProtected';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';
import styles from './UserManager.module.scss';
import CSSModule from 'react-css-modules';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Breadcrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { connect } from 'react-redux';
import { actions as managePointsActions } from 'coupon-common/src/modules/admin/pointsManager/managePoints';
import { useHistory } from 'react-router-dom';

const UserManager = ({
  intl,
  dispatch,
  usersData,
}) => {
  const [isActive, setIsActive] = useState('customers');
  const [searchQuery, setSearchQuery] = useState(null);
  const [mainData, setMainData] = useState(null);
  const [customersData, setCustomerData] = useState([]);
  const [businessesData, setBusinessesData] = useState([]);
  const [updatedPaginationOptions, setUpdatedPaginationOptions] = useState({})

  const history = useHistory();

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    filterData();
  }, [usersData]);

  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    } else {
      filterData();
    }
  }, [searchQuery]);

  const fetchUserDetails = () => {
    // if the data is already present in redux it won't make unnecessary API calls
    if (!usersData?.length) {
      dispatch(managePointsActions.getAllUsers());
    }
  };

  const filterData = () => {
    if (usersData?.length) {
      const customersDataArray = [];
      const businessesDataArray = [];

      usersData.map(user => {
        const formattedUser = {
          id: user?.id,
          first_name: user?.first_name || user?.contact_name,
          email: user?.email,
          points: user?.wallet?.points_at_last_date,
          business: user?.name,
          is_business: user?.is_business,
      };

        if (user?.is_business) {
          businessesDataArray.push(formattedUser);
        } else {
          customersDataArray.push(formattedUser);
        }

        return formattedUser;
      });

      setCustomerData(customersDataArray);
      setBusinessesData(businessesDataArray);
      setMainData(
        isActive === 'customers' ? customersDataArray : businessesDataArray,
      );
    }
  };

  const handleChange = value => {
    setIsActive(value);

    if (value == 'businesses') {
      setMainData(businessesData);
    } else if (value == 'customers') {
      setMainData(customersData);
    }
  };

  const handleSearch = () => {
    let result = [];
    if (isActive == 'customers') {
      result = customersData.filter(user => user.email.includes(searchQuery));
    } else if (isActive == 'businesses') {
      result = businessesData.filter(user => user.email.includes(searchQuery));
    }

    setMainData(result);
  };

  const handlePageChange = data => {
    const { id } = data;
    history.push(`/admin/users/${id}`);
  };

  const columns = [
    {
      dataField: 'first_name',
      text: intl.formatMessage(messages.firstNameColumnText),
      sort: true,
    },
    ...(isActive === 'businesses'
      ? [
          {
            dataField: 'business',
            text: intl.formatMessage(messages.businessColumnText),
            sort: true,
          },
        ]
      : []),
    {
      dataField: 'email',
      text: intl.formatMessage(messages.emailColumnText),
      sort: true,
    },
    {
      dataField: 'points',
      text: intl.formatMessage(messages.pointsColumnText),
      sort: true,
    },
    {
      dataField: ' ',
      text: ' ',
      classes: styles['add-points'],
      formatter: cellContent =>
        cellContent || intl.formatMessage(messages.editColumnText),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          handlePageChange(row);
        },
      },
    },
  ];

  const upadteOptions = () => {
      const paginationOptions = {
        sizePerPage: 25,
        totalSize: mainData?.length,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        firstPageText: 'First',
        lastPageText: 'Last',
        nextPageText: 'Next',
        prePageText: 'Previous',
        withFirstAndLast: true,
      }

      setUpdatedPaginationOptions(paginationOptions);
  }


  useEffect(()=>{
      upadteOptions();
  }, [isActive, businessesData, customersData])

  return (
    <AppWrapper
      showHeader
      showSideNav
      showHeadingRow
      headingText={intl.formatMessage(messages.pageHeadingText)}
    >
      <AuthProtected />

      <div className="col-md-12">
        <Breadcrumbs locationArray={['Admin', 'User Manager']} />
        <Block>
          {mainData == null || mainData == undefined ? (
            <Spinner show />
          ) : (
            <>
              <div className="container">
                <div
                  styleName="row"
                  className="row justify-content-between align-items-center mb-3 p-1"
                >
                  <div styleName="btn-container">
                    <button
                      styleName={`${isActive == 'customers' ? 'active' : ''}`}
                      onClick={() => handleChange('customers')}
                    >
                      <FormattedMessage {...messages.customersColumnText} />
                    </button>
                    <button
                      styleName={`${isActive == 'businesses' ? 'active' : ''}`}
                      onClick={() => handleChange('businesses')}
                    >
                      <FormattedMessage {...messages.businessesColumnText} />
                    </button>
                  </div>
                  <div styleName="search">
                    <input
                      type="text"
                      placeholder="Search by email.."
                      onChange={e => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div
                styleName="table-container"
                className="table-container overflow-auto"
              >
                <BootstrapTable
                  classes="table-borderless mb-0 overflow-auto custom-react-bootstrap-table ml-0 mr-0"
                  striped
                  bordered={false}
                  bootstrap4
                  keyField="id"
                  data={mainData}
                  columns={columns}
                  pagination={paginationFactory(updatedPaginationOptions)}
                />
              </div>
            </>
          )}
        </Block>
      </div>
    </AppWrapper>
  );
};

const mapStateToProps = state => {
  return {
    usersData: state.admin.pointsManager.managePoints.getUsers.data,
  };
};

export default connect(mapStateToProps)(injectIntl(CSSModule(UserManager, styles)),);
