import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Block, FormPillSelector } from 'components';
import { actions, duck } from 'coupon-common/src/modules/businessCategories';
import {
  atLeastOneSelected,
  getNormalizedEntity,
} from 'coupon-common/src/utils';

import messages from './messages';

const BusinessCategoriesField = ({
  intl,
  businessCategories,
  readOnly,
  fullWidth,
  dispatch,
}) => {
  useEffect(() => {
    dispatch(actions.getBusinessCategories());
  }, [dispatch]);

  const businessCategoriesKey = duck.businessCategoriesEntity.key;

  const {
    entityList: businessCategoriesOptions,
    isFetching: businessCategoriesFetching,
    error: businessCategoriesLoadingError,
    /**
     * Pagination waiting for larger categories list:
     * isNextPageExist: businessCategoriesNextPageExist,
     */
  } = getNormalizedEntity(
    businessCategories,
    businessCategoriesKey,
    intl,
    messages.entityBusinessCategories,
  );

  return (
    <Block>
      <div className="row justify-content-center">
        <div className={fullWidth ? 'col-md-12' : 'col-md-10'}>
          <h5>
            <FormattedMessage {...messages.blockHeading} />
          </h5>
          <p className="text-muted">
            <FormattedMessage {...messages.instruction} />
          </p>
          <Field
            name="categories"
            type="select"
            defaultValue={[]}
            component={FormPillSelector}
            validate={atLeastOneSelected(messages.atLeastOneMessage)}
            readOnly={readOnly}
            isLoading={businessCategoriesFetching}
            loadingError={businessCategoriesLoadingError}
            maxSelected={3}
            options={businessCategoriesOptions}
          />
        </div>
      </div>
    </Block>
  );
};

BusinessCategoriesField.propTypes = {
  intl: PropTypes.object.isRequired,
  businessCategories: PropTypes.object,
  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => ({
  businessCategories: state.businessCategories,
});

export default connect(mapStateToProps)(injectIntl(BusinessCategoriesField));
