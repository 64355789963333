import { createActions, createReducer, resettableReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: [],
  error: null,
};

const { Types, Creators } = createActions(
  {
    reset: [],
    getCouponTransactions: ['params'],
    getCouponTransactionsRequest: [],
    getCouponTransactionsSuccess: ['data'],
    getCouponTransactionsFailure: ['error'],
  },
  { prefix: 'cb/admin/pointsManager/couponPointsTransactions/' }
);

export { Types as types, Creators as actions };

export const request = (state) => ({
  ...state,
  fetching: true,
});

export const success = (_, action) => {
  const { data } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    data,
  };
};

export const failure = (_, action) => {
  const { error } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    error,
  };
};

export const HANDLERS = {
  [Types.GET_COUPON_TRANSACTIONS_REQUEST]: request,
  [Types.GET_COUPON_TRANSACTIONS_SUCCESS]: success,
  [Types.GET_COUPON_TRANSACTIONS_FAILURE]: failure,
};

const couponPointsTransactionsReducer = createReducer(INITIAL_STATE, HANDLERS);

export default couponPointsTransactionsReducer;