import { all } from 'redux-saga/effects';

import * as pointsTransactions from './pointsTransactions';
import * as managePoints from './managePoints';
import * as couponPointsTransactions from './couponPointsTransactions';
import * as pointsBalances from './pointsBalances';


export default function* saga() {
  yield all([pointsTransactions.saga(), managePoints.saga(), couponPointsTransactions.saga(), pointsBalances.saga()]);
}
