import { FORM_ERROR } from 'final-form';
import { OK } from 'http-status-codes';
import { debounce, put, select, takeLatest } from 'redux-saga/effects';

import { ACTION_WAIT_DEBOUNCE_TIME_MS } from '../../constants';
import {
  formatApiErrors,
  getObjectsDiff,
  safeResolveAsync,
} from '../../utils/saga';
import { takeAuth, takeLogout } from '../auth/saga';
import { actions as locationsActions } from '../locations';

import * as api from './api';
import { actions, types } from './duck';

export function* getInfo() {
  try {
    yield put(actions.getInfoRequest());

    const response = yield api.getInfo();

    if (response.status === OK) {
      yield put(actions.getInfoSuccess(response.data));
      yield put(locationsActions.getCities(response.data.province.name));
    } else {
      yield put(actions.getInfoFailure(response.data.detail));
    }
  } catch (error) {
    yield put(actions.getInfoFailure(error.message));
  }
}

export function* updateInfo({ formValues, resolve }) {
  try {
    const originalValues = yield select(state => state.businessInfo.data);
    const updatedValues = getObjectsDiff(originalValues, formValues);

    if (Object.keys(updatedValues).length === 0) {
      yield safeResolveAsync(resolve);
      return;
    }

    yield put(actions.updateInfoRequest());

    const response = yield api.updateInfo(updatedValues);

    if (response.status === OK) {
      yield put(actions.updateInfoSuccess(response.data));
      yield safeResolveAsync(resolve);
    } else {
      yield put(actions.updateInfoFailure(response.data));
      yield safeResolveAsync(resolve, formatApiErrors(response.data));
    }
  } catch (error) {
    yield put(actions.updateInfoFailure(error.message));
    yield safeResolveAsync(resolve, { [FORM_ERROR]: error.message });
  }
}

export function* initialize() {
  yield put(actions.getInfo());
}

export function* clear() {
  yield put(actions.reset());
}

export default function* saga() {
  // We use debounce here to prevent
  // double saga call in case of initializing
  // on page with effect to call this saga.
  // TODO: find more propper way to handle two actions
  // dispatching on refresh. (Possibly by handlind "fetched" state in ui)
  yield debounce(ACTION_WAIT_DEBOUNCE_TIME_MS, types.GET_INFO, getInfo);
  yield takeLatest(types.UPDATE_INFO, updateInfo);
  yield takeAuth(initialize);
  yield takeLogout(clear);
}
