import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { DocumentWrapper } from 'components';
import { formatterContextValues } from 'utils/documentFormatting';

import messages from './messages';

const VARIANT_BUSINESS = 'business';
const VARIANT_CUSTOMER = 'customer';
const AVAILABLE_VARIANTS = [VARIANT_BUSINESS, VARIANT_CUSTOMER];

const TermsOfService = () => {
  const { variant } = useParams();
  const translationMessage = AVAILABLE_VARIANTS.includes(variant)
    ? messages[variant]
    : messages.variantNotExits;

  return (
    <DocumentWrapper>
      <FormattedMessage
        {...translationMessage}
        values={{
          ...formatterContextValues,
          list: formatterContextValues.listAlt,
        }}
      />
    </DocumentWrapper>
  );
};

export default TermsOfService;
