import 'react-image-crop/lib/ReactCrop.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Button, Feedback, Modal, Spinner } from 'components';
import { getCroppedImg } from 'utils/images';

import { RedoButtonIcon } from './icons';
import { DeleteButtonIcon } from './icons';
import styles from './FormImageField.module.scss';
import messages from './messages';

const FormImage = ({
  intl,
  textDark,
  row,
  rounded,
  width,
  height,
  aspect,
  accept,
  label,
  input,
  readOnly,
  meta,
  instantUpload,
  loading,
  error,
  onChange,
  onDelete,
  url,
}) => {
  const cropDefault = {
    width,
    height,
    aspect,
  };

  //Todo seperate this style from js to scss
  const img_upload_status_container_style = useMemo(
    () => ({
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    }),
    [],
  );

  const [crop, setCrop] = useState(cropDefault);
  const [cropperImgRef, setCropperImgRef] = useState();
  const [isCropperOpened, setCropperOpened] = useState(false);

  const [originalFile, setOriginalFile] = useState();
  const [originalFileUrl, setOriginalFileUrl] = useState();

  const [previewUrl, setPreviewUrl] = useState();

  useEffect(() => {
    if (instantUpload) {
      input.onChange(url);
      setPreviewUrl(url);
    }
  }, [url]);

  useEffect(() => {
    if (!input.value) {
      return;
    }
    if (input.value instanceof Blob) {
      setPreviewUrl(window.URL.createObjectURL(input.value));
    } else if (typeof input.value === 'string') {
      /* Support for @string URL form value */
      setPreviewUrl(input.value);
    }
  }, [input.value]);

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileUrl = window.URL.createObjectURL(file);
      setOriginalFile(file);
      setOriginalFileUrl(fileUrl);
      setCropperOpened(true);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: readOnly,
    multiple: false,
    accept,
  });

  const clearOriginalFile = () => {
    setOriginalFile();
    setOriginalFileUrl();
  };

  const closeCropper = () => {
    setCropperOpened(false);
    setCrop(cropDefault);
  };

  const onCropChange = newCrop => {
    setCrop(newCrop);
  };

  const onCropDone = async () => {
    const blob = await getCroppedImg(
      cropperImgRef,
      crop,
      originalFile.name,
      true,
    );
    if (!instantUpload) input.onChange(blob);
    else {
      setPreviewUrl(window.URL.createObjectURL(blob));
      onChange && onChange(blob);
    }
    closeCropper();
  };

  const onCropperClose = () => {
    clearOriginalFile();
    closeCropper();
    setCropperImgRef();
  };

  const onImageDelete = () => {
    input.onChange('');
    clearOriginalFile();
    onDelete();
  };

  const placeholder = useRef();

  const imgPlaceholder = (
    <div
      ref={placeholder}
      style={{
        maxWidth: width,
        maxHeight: height,
        // Dynamic height set, to save valid aspect.
        // Set height on initial render.
        height: placeholder.current
          ? placeholder.current.offsetWidth / aspect
          : height,
      }}
      styleName={cx('img-placeholder', {
        rounded,
        'drag-active': isDragActive,
      })}
    >
      <span>{width + 'x' + height}</span>
    </div>
  );

  const imgPreview = instantUpload ? (
    <div style={{ position: 'relative' }}>
      <img
        styleName={cx({ rounded, 'drag-active': isDragActive })}
        className="img-fluid"
        style={{ width }}
        src={previewUrl}
        alt={intl.formatMessage(messages.previewImgAlt)}
      />
      {(loading || !!error) && (
        <div style={img_upload_status_container_style}>
          {loading && <Spinner show />}
          {!loading && !!error && (
            <img
              src={RedoButtonIcon}
              width={30}
              style={{ opacity: '0.8' }}
              alt="retry"
            />
          )}
        </div>
      )}
    </div>
  ) : (
    <img
      styleName={cx({ rounded, 'drag-active': isDragActive })}
      className="img-fluid"
      style={{ width }}
      src={previewUrl}
      alt={intl.formatMessage(messages.previewImgAlt)}
    />
  );
  const shouldDisplayCropperDoneButton = !!cropperImgRef;
  const shouldDisplayError = !!(meta.error && (!meta.pristine || meta.touched));

  return (
    <div
      className="mt-3"
      styleName={cx('img-container', { 'text-dark': textDark })}
    >
      <div
        {...getRootProps()}
        className={cx('d-flex align-items-center', {
          'flex-row ': row,
          'flex-wrap': row,
          'flex-column ': !row,
        })}
      >
        <input {...getInputProps()} />

        {previewUrl ? imgPreview : imgPlaceholder}

        {label && (
          <div className={cx({ 'ml-3': row })}>
            {' '}
            <span>{label}</span>{' '}
          </div>
        )}

        <Feedback
          textCenter
          fullWidth={false}
          show={shouldDisplayError}
          content={meta.error}
          invalid
        />
      </div>
      {instantUpload && url && (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <img
            width={'15px'}
            alt="delete button"
            src={DeleteButtonIcon}
            onClick={onImageDelete}
          />
        </div>
      )}

      <Modal
        title={intl.formatMessage(messages.croppingModalTitle)}
        isOpen={isCropperOpened}
        onRequestClose={onCropperClose}
      >
        <div className="d-flex flex-column align-items-center">
          <ReactCrop
            src={originalFileUrl}
            crop={crop}
            ruleOfThirds
            keepSelection
            circularCrop={rounded}
            onChange={onCropChange}
            onImageLoaded={image => setCropperImgRef(image)}
          />
          {shouldDisplayCropperDoneButton ? (
            <div className="d-flex justify-content-end w-100 mt-5">
              <Button onClick={onCropDone}>
                <FormattedMessage {...messages.croppingModalDoneButtonText} />
              </Button>
            </div>
          ) : (
            <Spinner show />
          )}
        </div>
      </Modal>
    </div>
  );
};

FormImage.propTypes = {
  intl: PropTypes.object.isRequired,
  rounded: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  aspect: PropTypes.number,
  accept: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  textDark: PropTypes.bool,
  readOnly: PropTypes.bool,
  row: PropTypes.bool,
  instantUpload: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  url: PropTypes.string,
};

FormImage.defaultProps = {
  instantUpload: false,
  loading: false,
  error: '',
  url: '',
};

export default injectIntl(
  CSSModule(FormImage, styles, { allowMultiple: true }),
);
