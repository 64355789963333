import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { CloseButton } from 'components';
import { themeRootId } from 'containers/ThemeProvider/ThemeProvider';

import styles from './Modal.module.scss';

/**
 * We can not use react-css-modules here because of portal rendering
 */
const cx = classNames.bind(styles);

export function usePortal(id) {
  const rootElemRef = useRef(document.createElement('div'));

  useEffect(
    function setupElement() {
      const rootElement = rootElemRef.current;
      const parentElem = document.querySelector(`#${id}`);

      parentElem.appendChild(rootElement);

      return function removeElement() {
        rootElement.remove();
      };
    },
    [id],
  );

  return rootElemRef.current;
}

const Modal = ({ children, title, isOpen, onRequestClose }) => {
  const target = usePortal(themeRootId);
  const showHeader = title || onRequestClose;

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(target);
    } else {
      enableBodyScroll(target);
    }
    return () => {
      enableBodyScroll(target);
    };
  }, [isOpen, target]);

  const modalElement = (
    <div className={cx('modal-overlay')}>
      <div className={cx('centering-wrapper')}>
        <div className={cx('modal')}>
          <div className={cx('modal-container')}>
            <div
              className={cx('header', {
                hidden: !showHeader,
              })}
            >
              <h3>{title}</h3>
              {onRequestClose && <CloseButton onClick={onRequestClose} />}
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );

  if (isOpen) {
    return ReactDOM.createPortal(modalElement, target);
  }

  return null;
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default Modal;
