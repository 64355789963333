import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.ReferralProgram';

export default defineMessages({
  referralProgramHeadingText: {
    id: `${scope}.referralProgramHeadingText`,
    defaultMessage: 'Referral Program',
  },
});
