import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Logo } from 'components';

import messages from './messages';
import OnlyFormPageDefaultImg from './OnlyFormPageDefaultImg.svg';
import styles from './OnlyFormPageWrapper.module.scss';

const OnlyFormPageWrapper = ({ intl, children, forLogin }) => {
  const colStyle = forLogin ? 'col-md-4' : 'col-md-5';
  const drawImage = !forLogin;

  return (
    <div className={colStyle} styleName="only-form-page-wrapper">
      <div
        styleName={cx('logo-container', {
          'for-login': forLogin,
        })}
        className="row justify-content-center"
      >
        <Link to="/">
          <Logo />
        </Link>
      </div>
      {drawImage && (
        <div
          styleName="graphics-container"
          className="row justify-content-center"
        >
          <img
            src={OnlyFormPageDefaultImg}
            className="img-fluid"
            alt={intl.formatMessage(messages.imageAlt)}
          />
        </div>
      )}
      {children}
    </div>
  );
};

OnlyFormPageWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  intl: PropTypes.object.isRequired,
  forLogin: PropTypes.bool,
};

export default injectIntl(
  CSSModule(OnlyFormPageWrapper, styles, { allowMultiple: true }),
);
