import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { AppWrapper, Button, LandingBlock } from 'components';
import { AuthorizedRedirect } from 'containers';

import forBusinessImg from './ForBusiness.svg';
import forCustomerImg from './ForCustomers.svg';
import messages from './messages';

const LandingPage = ({ intl, history }) => {
  const goToSignUp = () => history.push('/signup');

  const goToExternalLink = () =>
    window.open(process.env.REACT_APP_MOBILE_CLIENT_ANDROID_LINK);

  const renderListHeading = () => (
    <h4 className="mt-4 mb-3">
      <FormattedMessage {...messages.landingBlockListHeading} />
    </h4>
  );

  const renderUnderlineText = text => <u>{text}</u>;

  return (
    <AppWrapper showHeader contentAlignCenter contentCentered bgLight>
      <AuthorizedRedirect />
      <div className="col-md-6">
        <LandingBlock
          src={forBusinessImg}
          alt={intl.formatMessage(messages.landingBlockImgBusinessAlt)}
          headingText={intl.formatMessage(messages.landingBlockHeadingBusiness)}
        >
          <FormattedMessage
            tagName="h5"
            {...messages.landingBlockSubHeadingBusiness}
          />
          <FormattedMessage
            tagName="p"
            {...messages.landingBlockDescriptionTextBusiness}
          />
          {renderListHeading()}
          <ol>
            <FormattedMessage
              tagName="li"
              {...messages.landingBlockReasonsListBusinessItemOne}
              values={{ u: renderUnderlineText }}
            />
            <FormattedMessage
              tagName="li"
              {...messages.landingBlockReasonsListBusinessItemTwo}
              values={{ u: renderUnderlineText }}
            />
            <FormattedMessage
              tagName="li"
              {...messages.landingBlockReasonsListBusinessItemTree}
              values={{ u: renderUnderlineText }}
            />
          </ol>
          <Button className="mt-4" large onClick={goToSignUp}>
            <FormattedMessage {...messages.landingBlockBtnTextBusiness} />
          </Button>
          <div className="mt-4">
            <FormattedMessage {...messages.alreadyHaveAnAccountText} />
            &nbsp;
            <Link to="/login">
              <FormattedMessage {...messages.signInLinkText} />
            </Link>
          </div>
        </LandingBlock>
      </div>
      <div className="col-md-6">
        <LandingBlock
          src={forCustomerImg}
          alt={intl.formatMessage(messages.landingBlockImgCustomerAlt)}
          headingText={intl.formatMessage(messages.landingBlockHeadingCustomer)}
        >
          <FormattedMessage
            tagName="h5"
            {...messages.landingBlockSubHeadingCustomer}
          />
          <FormattedMessage
            tagName="p"
            {...messages.landingBlockDescriptionTextCustomer}
          />
          {renderListHeading()}
          <ol>
            <FormattedMessage
              tagName="li"
              {...messages.landingBlockReasonsListCustomerItemOne}
              values={{ u: renderUnderlineText }}
            />
            <FormattedMessage
              tagName="li"
              {...messages.landingBlockReasonsListCustomerItemTwo}
              values={{ u: renderUnderlineText }}
            />
            <FormattedMessage
              tagName="li"
              {...messages.landingBlockReasonsListCustomerItemTree}
              values={{ u: renderUnderlineText }}
            />
          </ol>
          <Button className="mt-4" large onClick={goToExternalLink}>
            <FormattedMessage {...messages.landingBlockBtnTextCustomer} />
          </Button>
        </LandingBlock>
      </div>
    </AppWrapper>
  );
};

LandingPage.propTypes = {
  intl: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default injectIntl(LandingPage);
