import { call, put, takeLatest } from 'redux-saga/effects';
import { actions, types } from './duck';
import { updatePointsRequest } from './api';
import { OK } from 'http-status-codes';

function* updatePointsRequestsSaga({id, status, amount}) {
  try {
    yield put(actions.updatePointsRequestsRequest());

    const response = yield call(updatePointsRequest, id, status, amount);
    if (response.status === OK) {
      yield put(actions.updatePointsRequestsSuccess(response.data));
    } else {
      yield put(actions.updatePointsRequestsFailure(response.statusText));
    }
  } catch (error) {
    yield put(actions.updatePointsRequestsFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.UPDATE_POINTS_REQUESTS, updatePointsRequestsSaga);
}
