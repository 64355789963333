import { AppWrapper, Block, Button, Modal, Spinner } from 'components'
import AuthProtected from 'containers/AuthProtected/AuthProtected'
import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { FormattedMessage, injectIntl } from 'react-intl'
import messages from './messages'
import styles from './ManagePoints.module.scss'
import CSSModule from 'react-css-modules';
import { NavLink } from 'react-router-dom/cjs/react-router-dom'
import paginationFactory from 'react-bootstrap-table2-paginator';
import Breadcrumbs from 'components/BreadCrumbs/BreadCrumbs'
import { connect } from 'react-redux'
import { actions as managePointsActions } from 'coupon-common/src/modules/admin/pointsManager/managePoints';

const ManagePoints = ({ intl, dispatch, usersData, updatePointsData, updatePointsError, updatePointsFetching }) => {


    const [isActive, setIsActive] = useState('customers');
    const [searchQuery, setSearchQuery] = useState(null);
    const [mainData, setMainData] = useState(null)
    const [customersData, setCustomerData] = useState([])
    const [businessesData, setBusinessesData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [amount, setAmount] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [notes, setNotes] = useState("Updated by Admin");
    const [apiCall, setApiCall] = useState(false);
    const [updatedPaginationOptions, setUpdatedPaginationOptions] = useState({})


    useEffect(() => {
        fetchUserDetails();
    }, [])


    useEffect(() => {
        if (apiCall) {
            if (updatePointsData?.success) {
                alert("Points updated successfully!")
                handleClose();
                setApiCall(false);
            }
            else if (updatePointsError) {
                alert("There seems to be an issue processing this request. Please try again!")
                handleClose();
                setApiCall(false);
            }
            dispatch(managePointsActions.getAllUsers());
        }
    }, [updatePointsData?.success, updatePointsError])


    useEffect(() => {
        filterData();
    }, [usersData])


    useEffect(() => {
        if (searchQuery) {
            handleSearch()
        }
        else {
            filterData();
        }
    }, [searchQuery])


    useEffect(()=>{
        upadteOptions();
    }, [isActive, businessesData, customersData])


    const fetchUserDetails = () => {
        // if the data is already present in redux it won't make unnecessary API calls
        if (!usersData?.length) {
            dispatch(managePointsActions.getAllUsers());
        }
    }

    const filterData = () => {
        if (usersData?.length) {

            const customersDataArray = [];
            const businessesDataArray = [];

            usersData.map((user) => {
                const formattedUser = {
                    id: user?.id,
                    first_name: user?.first_name || user?.contact_name,
                    email: user?.email,
                    points: user?.wallet?.points_at_last_date,
                    business: user?.name,
                    is_business: user?.is_business,
                };

                if (user?.is_business) {
                    businessesDataArray.push(formattedUser);
                } else {
                    customersDataArray.push(formattedUser);
                }

                return formattedUser;
            });

            setCustomerData(customersDataArray);
            setBusinessesData(businessesDataArray);
            setMainData(isActive === 'customers' ? customersDataArray : businessesDataArray);

        }
    }

    const handleChange = (value) => {
        setIsActive(value);

        if (value == 'businesses') {
            setMainData(businessesData);
        }
        else if (value == 'customers') {
            setMainData(customersData);
        }
    }

    const handleSearch = () => {
        let result = [];
        if (isActive == 'customers') {
            result = customersData.filter((user) => user.email.includes(searchQuery));
        }
        else if (isActive == 'businesses') {
            result = businessesData.filter((user) => user.email.includes(searchQuery));
        }

        setMainData(result);
    }

    const handleManagePoints = (data) => {
        setIsModalOpen(true);
        setSelectedUser(data);
    }

    const calculatePoints = (amount) => {
        const userPoints = Number(selectedUser?.points);
        amount = Number(amount);

        let value;
        if (selectedOption == 'Add') {
            value = Number(userPoints + amount);
        }
        else if (selectedOption == 'Deduct') {
            if (totalPoints < amount) {
                value = "Negative amount is not allowed";
            }
            else {
                value = Number(userPoints - amount);
                amount = -amount;
            }
        }
        else if (selectedOption == 'Custom value') {
            value = Number(amount)
            if (userPoints > amount) {
                amount = -(userPoints - amount);
            }
            else if (userPoints < amount) {
                amount = -(userPoints - amount);
            }
        }
        setAmount(amount);
        setTotalPoints(value);
    }

    const handleSave = () => {
        dispatch(managePointsActions.updatePoints(selectedUser?.id, amount, notes))
        // fetchUserDetails();
        setApiCall(true);
    }

    const handleClose = () => {
        setIsModalOpen(false);
        setSelectedUser(null);
        setSelectedOption(null);
    }


    const columns = [
        {
            dataField: 'first_name',
            text: intl.formatMessage(messages.firstNameColumnText),
            sort: true,
        },
        ...(isActive === 'businesses'
            ? [
                {
                    dataField: 'business',
                    text: intl.formatMessage(messages.businessColumnText),
                    sort: true,
                },
            ]
            : []),
        {
            dataField: 'email',
            text: intl.formatMessage(messages.emailColumnText),
            sort: true,
        },
        {
            dataField: 'points',
            text: intl.formatMessage(messages.pointsColumnText),
            sort: true,
        },
        {
            dataField: ' ',
            text: " ",
            classes: styles['manage-points'],
            formatter: (cellContent) => cellContent || intl.formatMessage(messages.manageColumnText),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    handleManagePoints(row);
                },
            },
        },
    ];

      const upadteOptions = () => {
          const paginationOptions = {
            sizePerPage: 25,
            totalSize: mainData?.length,
            showTotal: true,
            sizePerPageList: [10, 25, 50, 100],
            firstPageText: 'First',
            lastPageText: 'Last',
            nextPageText: 'Next',
            prePageText: 'Previous',
            withFirstAndLast: true,
          }

          setUpdatedPaginationOptions(paginationOptions);
      }


    return (
        <AppWrapper
            showHeader
            showSideNav
            showHeadingRow
            headingText={intl.formatMessage(messages.pageHeadingText)}
        >
            <AuthProtected />

            <div className="col-md-12">
                <Breadcrumbs locationArray={["Admin", "Points Manager", "Manage"]} />
                <Block>

                    {(mainData == null || mainData == undefined) ? (
                        <Spinner show />
                    ) : (
                        <>
                            <div className='container'>
                                <div styleName='row' className="row justify-content-between align-items-center mb-3 p-1">
                                    <div styleName="btn-container">
                                        <button styleName={`${isActive == 'customers' ? 'active' : ""}`} onClick={() => handleChange('customers')}>
                                            <FormattedMessage {...messages.customersColumnText} />
                                        </button>
                                        <button styleName={`${isActive == 'businesses' ? 'active' : ""}`} onClick={() => handleChange('businesses')}>
                                            <FormattedMessage {...messages.businessesColumnText} />
                                        </button>
                                    </div>
                                    <div styleName='search'>
                                        <input type="text" placeholder='Search by email..' onChange={(e) => setSearchQuery(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div styleName='table-container' className="table-container overflow-auto">
                                <BootstrapTable
                                    classes="table-borderless mb-0 overflow-auto custom-react-bootstrap-table ml-0 mr-0"
                                    striped
                                    bordered={false}
                                    bootstrap4
                                    keyField="id"
                                    data={mainData}
                                    columns={columns}
                                    pagination={paginationFactory(updatedPaginationOptions)}
                                />
                            </div>

                            <div styleName='transaction-btn'>
                                <NavLink to="/admin/points/transactions">
                                    <FormattedMessage {...messages.viewTransactionsColumnText} />
                                </NavLink>
                            </div>
                        </>
                    )}
                </Block>
            </div>

            <Modal
                title={`Manage Points for ${selectedUser?.first_name}`}
                isOpen={isModalOpen}
                onRequestClose={handleClose}
            >
                <div className={styles.managePointsModal}>
                    <p>
                        <span className='font-weight-bold'>{selectedUser?.first_name}

                        </span> currently has <span className='font-weight-bold'>{selectedUser?.points.toLocaleString()}
                        </span> points.</p>

                    {/* <p>Select an operation: </p> */}
                    <div className={styles.styledSelect}>
                        <select onChange={(e) => setSelectedOption(e.target.value)}>
                            <option value="" disabled selected>Select if you want to add, deduct or assign a custom value</option>
                            <option value="Add">Add Points</option>
                            <option value="Deduct">Deduct Points</option>
                            <option value="Custom value">Assign Custom Points Value</option>
                        </select>
                    </div>

                    {selectedOption && (
                        <>
                            <div className='mt-2'>
                                <span className='mr-2'>{selectedOption}</span>
                                <input
                                    type="number"
                                    min={0}
                                    onKeyDown={(e) => {
                                        // Prevent negative sign input for add or deduct but allow for custom
                                        if (!(selectedOption == "Custom value") && (e.key === '-' || e.key === 'e')) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => calculatePoints(e.target.value)}
                                />
                                <span className='ml-2'>points</span>
                            </div>
                            <p className='font-weight-bold'>New Points Value: {totalPoints?.toLocaleString()}</p>
                            <input type="text" maxLength={35} placeholder='Add a note (Optional), Defaults to "Updated by Admin"' onChange={(e) => setNotes(e.target.value)} />
                        </>
                    )}

                    <div className='d-flex justify-content-end'>
                        <Button outline onClick={handleClose}>
                            <FormattedMessage {...messages.cancelColumnText} />
                        </Button>
                        <Button
                            fetching={updatePointsFetching}
                            disabled={amount == 0 || typeof (totalPoints) == 'string'}
                            onClick={handleSave}
                        >
                            <FormattedMessage {...messages.saveColumnText} />
                        </Button>
                    </div>

                </div>
            </Modal>
        </AppWrapper>
    )
}

const mapStateToProps = (state) => {
    return {
        authorized: state.auth.authorized,
        usersData: state.admin.pointsManager.managePoints.getUsers.data,
        updatePointsData: state.admin.pointsManager.managePoints.updatePoints.data,
        updatePointsError: state.admin.pointsManager.managePoints.updatePoints.error,
        updatePointsFetching: state.admin.pointsManager.managePoints.updatePoints.fetching,
    };
};

export default connect(mapStateToProps)(injectIntl(CSSModule(ManagePoints, styles)));
