import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import { AppWrapper } from 'components';
import { useParamsConfig } from 'hooks';

import styles from './DocumentWrapper.module.scss';

const DocumentWrapper = ({ children }) => {
  const { noWrapper } = useParamsConfig();

  if (noWrapper) {
    return (
      <div className="container">
        <div className="col-md-12" styleName="document">
          {children}
        </div>
      </div>
    );
  }

  return (
    <AppWrapper showHeader showHeadingRow bgLight>
      <div className="col-md-12 mb-5" styleName="document">
        {children}
      </div>
    </AppWrapper>
  );
};

DocumentWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default CSSModule(DocumentWrapper, styles);
