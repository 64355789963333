import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.AccountSettings';

export default defineMessages({
    emailLabelText: {
      id: `${scope}.emailLabelText`,
      defaultMessage: 'Email',
    },
    passwordLabelText: {
      id: `${scope}.passwordLabelText`,
      defaultMessage: 'Password',
    },
    changePasswordButtonText: {
      id: `${scope}.changePasswordButtonText`,
      defaultMessage: 'Change password',
    },
    headingText: {
      id: `${scope}.headingText`,
      defaultMessage: 'Account Settings',
    },
  });