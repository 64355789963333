import { call, put, takeLatest } from 'redux-saga/effects';
import { actions, types } from './duck';
import { updateBusinessUserAPI, updateCustomerUserAPI } from './api';
import { OK } from 'http-status-codes';

function* updateBusinessUserSaga({id, updatedData}) {
  try {
    yield put(actions.updateBusinessUserRequest());

    const response = yield call(updateBusinessUserAPI, id, updatedData);
    if (response.status === OK) {
      yield put(actions.updateBusinessUserSuccess(response.data));
    } else {
      yield put(actions.updateBusinessUserFailure(response.statusText));
    }
  } catch (error) {
    yield put(actions.updateBusinessUserFailure(error.message));
  }
}

function* updateCustomerUserSaga({id, updatedData}) {
  try {
    yield put(actions.updateCustomerUserRequest());

    const response = yield call(updateCustomerUserAPI, id, updatedData);
    if (response.status === OK) {
      yield put(actions.updateCustomerUserSuccess(response.data));
    } else {
      yield put(actions.updateCustomerUserFailure(response.statusText));
    }
  } catch (error) {
    yield put(actions.updateCustomerUserFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.UPDATE_BUSINESS_USER, updateBusinessUserSaga);
  yield takeLatest(types.UPDATE_CUSTOMER_USER, updateCustomerUserSaga);
}
