import { AppWrapper } from 'components';
import AuthProtected from 'containers/AuthProtected/AuthProtected';
import React from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import CSSModule from 'react-css-modules';
import styles from './AdminPanel.module.scss';
import { injectIntl } from 'react-intl';

const AdminPanel = () => {
    return (
        <div>
            <AppWrapper
                showHeader
                showSideNav
                showHeadingRow
                headingText={'Admin Panel'}
            >
                <AuthProtected />

                <div className={styles.container}>
                    <div className={styles.cardGrid}>
                        <NavLink to={'/admin/points'} className={styles.card}>
                            <img
                                src="https://icons.veryicon.com/png/o/business/business-style-icon/wallet-62.png"
                                alt="Points Manager Icon"
                                className={styles.cardImage}
                            />
                            <h5 className={styles.cardTitle}>Points Manager</h5>
                        </NavLink>
                        <NavLink to={'/admin/users'} className={styles.card}>
                            <img
                                src="https://i.postimg.cc/CxjNdJRj/6481225432795d8cdf48f0f85800cf66-removebg-preview.png"
                                alt="User Manager Icon"
                                className={styles.cardImage}
                            />
                            <h5 className={styles.cardTitle}>User Manager</h5>
                        </NavLink>
                    </div>
                </div>
            </AppWrapper>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        authorized: state.auth.authorized,
        data: state.pointsRequests.processPointsRequest.data,
        error: state.pointsRequests.processPointsRequest.error,
        pointsActivityIsModalOpen: state.pointsRequests.pointsActivity.isModalOpen,
        processPointsFetching: state.pointsRequests.processPointsRequest.fetching,
    };
};

export default connect(mapStateToProps)(injectIntl(CSSModule(AdminPanel, styles)));
