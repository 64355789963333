import { AppWrapper } from 'components'
import AuthProtected from 'containers/AuthProtected/AuthProtected'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from './PointsManager.module.scss';
import CSSModule from 'react-css-modules'
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import messages from './messages';

const PointsManager = ({ intl }) => {
    return (
        <AppWrapper
            showHeader
            showSideNav
            showHeadingRow
            headingText={intl.formatMessage(messages.pageHeadingText)}
        >
            <AuthProtected />

            <div className="container ml-4">
                <div className={`${styles.inner} row`}>
                    <NavLink to={'/admin/points/manage'} className={`${styles.box}`}>
                        <FormattedMessage {...messages.managePointsColumnText} />
                    </NavLink>
                    <NavLink to={'/admin/points/transactions'} className={`${styles.box}`}>
                        <FormattedMessage {...messages.pointsTransactionsColumnText} />
                    </NavLink>
                </div>
            </div>


        </AppWrapper>
    )
}

export default injectIntl(CSSModule(PointsManager, styles))
