import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.JoinLoyaltyPointsField';

export default defineMessages({
  heading: {
    id: `${scope}.heading`,
    defaultMessage: 'Enable Loyalty Points Program',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Turn on the loyalty points program to reward your customers and encourage repeat visits.',
  },
  howItWorks: {
    id: `${scope}.howItWorks`,
    defaultMessage: 'Here’s how it works:',
  },
  howItWorksDescription: {
    id: `${scope}.howItWorksDescription`,
    defaultMessage: 'After a customer pays, they’ll request loyalty points through the app. You’ll get an instant notification and can award points based on their spending:',
  },
  points10: {
    id: `${scope}.points10`,
    defaultMessage: '$10+ earns 10 points',
  },
  points25: {
    id: `${scope}.points25`,
    defaultMessage: '$25+ earns 50 points',
  },
  points50: {
    id: `${scope}.points50`,
    defaultMessage: '$50+ earns 125 points',
  },
  points100: {
    id: `${scope}.points100`,
    defaultMessage: '$100+ earns 300 points',
  },
  agreement: {
    id: `${scope}.agreement`,
    defaultMessage: 'By enabling this program, you agree to honor the program terms and provide points according to customer spending. This system drives higher spending and increases customer loyalty to grow your profits.',
  },
  enableSwitch: {
    id: `${scope}.enableSwitch`,
    defaultMessage: 'Enable Loyalty Points Program',
  },
});
