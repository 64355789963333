
import React, { useCallback, useEffect, useState } from 'react'
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { actions } from 'coupon-common/src/modules/pointsRequests/pointsActivity';
import PropTypes from 'prop-types';
import PointsRequestsForm from 'containers/PointsRequestsForm/PointsRequestsForm';

const PointsRequestsFetcher = ({
  dispatch,
  authorized,
  data,
  pointsActivityIsModalOpen,
  businessId

}) => {

  const POINTS_REQUESTS_TIMEOUT_MS = 10000;

  const [requests, setRequests] = useState([]);


  useEffect(() => {

    if (data?.results?.length > 0) {
      // Only update the requests if the requests array is currently empty
      if (!pointsActivityIsModalOpen && requests?.length == 0) {
        const mydata = [...data.results]
        const requestsArray = mydata.reverse().filter((request)=> request.status === 'pending')
        setRequests(requestsArray);
      }
    }
  }, [data])


  const getPointsRequests = useCallback(() => {
    // Added a check to only send request when the id is not undefined
    // Avoid polling when the modal is already open and when the requests array already has request object(s)
    if(businessId && !pointsActivityIsModalOpen && requests?.length == 0){
      dispatch(actions.getPointsRequests({ business: businessId })); // Pass the business ID parameter
    }

  }, [dispatch, businessId, pointsActivityIsModalOpen, requests?.length]);

  useEffect(() => {
      getPointsRequests()
      let timer;
      if (authorized) {
        timer = setInterval(() => {
            getPointsRequests();
        }, POINTS_REQUESTS_TIMEOUT_MS);
      }
      return () => {
        clearInterval(timer);
      };

  }, [authorized, getPointsRequests]);

  // console.log("PW DATA REQUESTS ARE: " + JSON.stringify(data));
  // return data.requests;

  const handleNextRequest = () => {
    if (requests?.length > 0 && requests[0]) {
      setTimeout(() => {
        setRequests((prev) => prev.slice(1));
      }, 1000);
    }
  }

  return (
    <PointsRequestsForm req={requests[0]} noOfRequests={requests.length} handleNextRequest={handleNextRequest} />
  )
}

PointsRequestsFetcher.propTypes = {
  dispatch: PropTypes.func,
  authorized: PropTypes.bool,
  data: PropTypes.shape({
    results: PropTypes.array,
  }),
  pointsActivityIsModalOpen: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    authorized: state.auth.authorized,
    data: state.pointsRequests.pointsActivity.data || {results: []},
    pointsActivityIsModalOpen: state.pointsRequests.pointsActivity.isModalOpen,
    businessId: state.businessInfo.data.id,
  };
};


export default connect(mapStateToProps)(injectIntl(PointsRequestsFetcher));
