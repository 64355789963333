import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Button, Feedback, FormField } from 'components';
/* For now until we deside how to handle dublicated messages */
// Commented because BusinessInfoFieldGroup/messages didn't have emailText and passwordText instead added it in messages in Login Form folder
// import fieldMessages from 'containers/BusinessInfoFieldGroup/messages';
import {
  composeSyncValidators,
  required,
  validEmail,
} from 'coupon-common/src/utils';

import styles from './LoginForm.module.scss';
import messages from './messages';

const LoginForm = ({ intl, submitting, handleSubmit, submitError }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="email"
        label={intl.formatMessage(messages.emailLabelText)}
        type="email"
        validate={composeSyncValidators(required, validEmail())}
        component={FormField}
      />
      <Field
        name="password"
        label={intl.formatMessage(messages.passwordLabelText)}
        type="password"
        validate={required}
        component={FormField}
      />
      <Feedback
        fullWidth
        textCenter
        show={!!submitError}
        content={submitError}
        invalid
      />
      <div styleName="subform-block">
        <div className="d-flex justify-content-end">
          {/* Commented until we decide how to implement 'Remember me' */}
          {/*
            <Field
              name="rememberMe"
              label={intl.formatMessage(messages.rememberMeCheckboxText)}
              type="checkbox"
              component={FormCheckbox}
            />
          */}
          <Link to="/forgot-password">
            <FormattedMessage {...messages.forgotPasswordLinkText} />
          </Link>
        </div>
      </div>
      <Button fetching={submitting} type="submit" fullWidth large>
        <FormattedMessage {...messages.loginButtonText} />
      </Button>
      <div styleName="subform-block-large" className="text-center">
        <Link to="/signup">
          <FormattedMessage {...messages.goToSignUpLinkText} />
        </Link>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  intl: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitError: PropTypes.string,
};

export default injectIntl(CSSModule(LoginForm, styles));
