import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.MarketerInfo';

export default defineMessages({
  yourMarketerBlockHeading: {
    id: `${scope}.yourMarketerBlockHeading`,
    defaultMessage: 'Your Marketer',
  },
  businessMarketerRoleText: {
    id: `${scope}.businessMarketerRoleText`,
    defaultMessage: 'Business user',
  },
  customerMarketerRoleText: {
    id: `${scope}.customerMarketerRoleText`,
    defaultMessage: 'Customer',
  },
  adminMarketerRoleText: {
    id: `${scope}.adminMarketerRoleText`,
    defaultMessage: 'Admin',
  },
});
