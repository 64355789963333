import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Block } from 'components';
import { POINTS_PLACEHOLDER_STRING } from 'containers/Header/Header';

import messages from './messages';

export const TotalPointsEarned = ({ pointsCount, pointsEarnPerYear }) => {
  const valueToDisplay = Number.isFinite(pointsCount)
    ? pointsCount
    : POINTS_PLACEHOLDER_STRING;

  return (
    <Block fixedMinHeight>
      <div className="row">
        <div className="col-md-12">
          <h5 className="mb-0">
            <FormattedMessage {...messages.yourTotalPointsEarnedBlockHeading} />
          </h5>
          <div className="d-flex flex-row flex-wrap">
            <div
              className="d-flex flex-grow-1 flex-basis-0 flex-shrink-1 split-with-accent"
              style={{ marginTop: 0 }}
            >
              <FormattedMessage
                tagName="p"
                {...messages.pointsEarnRulesText}
                values={{ earnPoints: pointsEarnPerYear }}
              />
            </div>
            <div className="d-flex flex-column">
              <b className="accent">{valueToDisplay}</b>
              <span className="text-muted text-uppercase">
                <FormattedMessage
                  {...messages.pointsText}
                  values={{ points: valueToDisplay }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </Block>
  );
};

TotalPointsEarned.propTypes = {
  pointsCount: PropTypes.number,
};

export default TotalPointsEarned;
