import { authApiCall } from '../auth/saga';
import { formatToImageArgs } from '../../utils/api';

export const uploadCouponImage = (coupon_id, sequence, imageData) => {
  const data = new FormData();
  if (coupon_id && coupon_id > 0) data.append('coupon', coupon_id);
  data.append('sequence', sequence);
  data.append(...formatToImageArgs('image', imageData));

  return authApiCall({
    url: '/coupons/upload-coupon-image/',
    method: 'post',
    data,
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      Accept: 'application/json',
    },
  });
};

export const updateCouponImage = (coupon_image_id, sequence, imageData) => {
  const data = new FormData();
  data.append('sequence', sequence);
  data.append(...formatToImageArgs('image', imageData));
  data.append('coupon_image_id', coupon_image_id);

  return authApiCall({
    url: '/coupons/update-coupon-image/',
    method: 'patch',
    data,
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      Accept: 'application/json',
    },
  });
};

export const deleteCouponImage = coupon_image_id => {
  const data = new FormData();
  data.append('coupon_image_id', coupon_image_id);

  return authApiCall({
    url: '/coupons/delete-coupon-image/',
    method: 'delete',
    data,
  });
};
