import { defineMessages } from 'react-intl';

const scope = 'cb.containers.UsedCoupons';

export default defineMessages({
  pageHeadingText: {
    id: `${scope}.pageHeadingText`,
    defaultMessage: 'Used coupons',
  },
  couponTitleColumnText: {
    id: `${scope}.couponTitleColumnText`,
    defaultMessage: 'Coupon Title',
  },
  customerNameColumnText: {
    id: `${scope}.customerNameColumnText`,
    defaultMessage: 'Customer Name',
  },
  dateAndTimeColumnText: {
    id: `${scope}.dateAndTimeColumnText`,
    defaultMessage: 'Date and time',
  },
  payByColumnText: {
    id: `${scope}.payByColumnText`,
    defaultMessage: 'Pay By',
  },
  stateColumnText: {
    id: `${scope}.stateColumnText`,
    defaultMessage: 'State',
  },
  waitingStateText: {
    id: `${scope}.waitingStateText`,
    defaultMessage: 'Waiting for Acceptance',
  },
  acceptedStateText: {
    id: `${scope}.acceptedStateText`,
    defaultMessage: 'Accepted',
  },
  rejectedStateText: {
    id: `${scope}.rejectedStateText`,
    defaultMessage: 'Rejected',
  },
  failedStateText: {
    id: `${scope}.failedStateText`,
    defaultMessage: 'Failed',
  },
  canceledStateText: {
    id: `${scope}.canceledStateText`,
    defaultMessage: 'Canceled',
  },
  payByPointsText: {
    id: `${scope}.payByPointsText`,
    defaultMessage: 'Points',
  },
  payByDollarsText: {
    id: `${scope}.payByDollarsText`,
    defaultMessage: 'Dollars',
  },
  usedCouponsEntity: {
    id: `${scope}.usedCouponsEntity`,
    defaultMessage: 'used coupons',
  },
});
