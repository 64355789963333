import { MOTH_YEAR_CARD_SPLITTER, SPACE_REGEX } from '../constants';

export const formatToISOString = (date) => {
  if (Date.parse(date)) {
    if (date.getFullYear() > 2100) date.setFullYear(new Date().getFullYear());

    // Create a new date in UTC without being influenced by local time
    const utcDate = new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23, 59, 59, 999
    ));

    return utcDate.toISOString();
  }
  return null;
};

export const parseISOString = string => {
  const parsedDate = Date.parse(string);

  const newParsedDate = new Date(parsedDate);
  // Parse a new date in UTC without being influenced by local time
  const utcDate = new Date(
    newParsedDate.getUTCFullYear(),
    newParsedDate.getUTCMonth(),
    newParsedDate.getUTCDate(),
    newParsedDate.getUTCHours(),
    newParsedDate.getUTCMinutes(),
    newParsedDate.getUTCSeconds(),
    newParsedDate.getUTCMilliseconds()
  );

  return parsedDate && utcDate;
};

export const getFullYearPrefix = () =>
  `${new Date().getFullYear()}`.slice(0, 2);

export const parseCardMonthYear = monthYear => {
  const [expMonth, expYear] = monthYear
    .replace(SPACE_REGEX, '')
    .split(MOTH_YEAR_CARD_SPLITTER);
  const fullYear = getFullYearPrefix() + expYear;

  return [expMonth, fullYear];
};

export const trimSpaces = string => string.trim();
