import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import styles from './Chart.module.scss';
import {
  CusomChartLegend,
  CustomChartBar,
  CustomChartTick,
} from './ChartComponents';

const MAX_ALLOWED_BARS_WITHOUT_SCROLL = 7;
const DEFAULT_BAR_SIZE = 7;
const DEFAUL_CHART_WIDTH = '100%';
export const CHART_ITEM_WIDTH = 18;
const CHART_CONTAINER_HEIGHT = 350;
const BAR_GAP = 11;
const Y_AXIS_WIDTH = 25;
const CARTESIAN_GRID_STROKE = '3';
const PERCENT_ADDITION_TO_Y_AXIS_MAX_VALUE = 5;
const DEFAULT_Y_AXIS_MAX_VALUE = 4;

export const Chart = ({ data, barsConfig }) => {
  // this key is used to full chart animation restart.
  // the library does not support dynamic animation.
  // therefore, to restart animation chart is rerendering completely
  const [chartKey, setChartKey] = useState();

  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartContainer.current) {
      chartContainer.current.scrollLeft = 0;
    }
  }, [data]);

  useEffect(() => {
    const newKey = barsConfig.map(item => item.dataKey).join(',');
    setChartKey(newKey);
  }, [barsConfig]);

  const renderBars = useCallback(
    () =>
      barsConfig &&
      barsConfig.map(item => (
        <Bar
          shape={
            <CustomChartBar
              className={item.className}
              barDataKey={item.dataKey}
            />
          }
          barSize={DEFAULT_BAR_SIZE}
          key={item.dataKey}
          dataKey={item.dataKey}
        />
      )),
    [barsConfig],
  );

  const formatTooltip = (value, name) => {
    return [value, barsConfig.find(item => item.dataKey === name).label];
  };

  const chartWidthPercents = useMemo(() => {
    return data && data.length > MAX_ALLOWED_BARS_WITHOUT_SCROLL
      ? `${data.length * CHART_ITEM_WIDTH}%`
      : DEFAUL_CHART_WIDTH;
  }, [data]);

  const calculateAxisMaxValue = dataMax =>
    // set axis max value to this number for beautiful empty chart displaying when from BE returned only bars with zero values
    Math.ceil(
      dataMax + dataMax * (PERCENT_ADDITION_TO_Y_AXIS_MAX_VALUE / 100),
    ) || DEFAULT_Y_AXIS_MAX_VALUE;

  return (
    data &&
    data.length > 0 && (
      <div ref={chartContainer} className="chart-container">
        <ResponsiveContainer
          key={chartKey}
          className="mt-4"
          styleName="chart"
          width={chartWidthPercents}
          height={CHART_CONTAINER_HEIGHT}
        >
          <BarChart data={data} barGap={BAR_GAP}>
            <YAxis
              domain={['dataMin', calculateAxisMaxValue]}
              tickLine={false}
              tick={<CustomChartTick />}
              width={Y_AXIS_WIDTH}
            />
            <XAxis
              tickLine={false}
              dataKey="name"
              tick={<CustomChartTick xAxis />}
            />
            <CartesianGrid
              strokeDasharray={CARTESIAN_GRID_STROKE}
              vertical={false}
            />
            <Tooltip formatter={formatTooltip} />
            {renderBars()}
            <Legend
              iconType="square"
              align="left"
              content={<CusomChartLegend />}
              barsConfig={barsConfig}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  barsConfig: PropTypes.arrayOf(PropTypes.object),
};

export default CSSModule(Chart, styles);
