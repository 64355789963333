import { combineReducers } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions(
  {
    getReferralInfo: [],
    getReferralInfoRequest: [],
    getReferralInfoSuccess: ['data'],
    getReferralInfoFailure: ['error'],
  },
  { prefix: 'cb/memberships/referralInfo/' },
);

export const fetching = createReducer(false, {
  [types.GET_REFERRAL_INFO_REQUEST]: () => true,
  [types.GET_REFERRAL_INFO_SUCCESS]: () => false,
  [types.GET_REFERRAL_INFO_FAILURE]: () => false,
});

export const data = createReducer(null, {
  [types.GET_REFERRAL_INFO_SUCCESS]: (_, { data }) => data,
  [types.GET_REFERRAL_INFO_FAILURE]: () => null,
});

export const error = createReducer(null, {
  [types.GET_REFERRAL_INFO_SUCCESS]: () => null,
  [types.GET_REFERRAL_INFO_FAILURE]: (_, { error }) => error,
});

export default combineReducers({
  fetching,
  data,
  error,
});
