import { OK } from 'http-status-codes';
import { call, put, takeLatest } from 'redux-saga/effects';

import { takeAuth, takeLogout } from '../../../auth/saga';

import * as api from './api';
import { actions, types } from './duck';

export function* getCouponTransactions({ params }) {
  try {
  
    yield put(actions.getCouponTransactionsRequest());

    const response = yield call(api.getCouponTransactions, params);

    if (response.status === OK) {
      yield put(actions.getCouponTransactionsSuccess(response.data));
    } else {
      yield put(actions.getCouponTransactionsFailure(response.data.detail));
    }
  } catch (error) {
    yield put(actions.getCouponTransactionsFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_COUPON_TRANSACTIONS, getCouponTransactions);
}