import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.PointsRequestsForm';

export default defineMessages({
    pointsSystemModalTitle: {
        id: `${scope}.pointsSystemModalTitle`,
        defaultMessage: 'Request for points',
      },
    option_1ButtonText: {
        id: `${scope}.option_1ButtonText`,
        defaultMessage: '$10 or more ?',
    },
    option_2ButtonText: {
        id: `${scope}.option_2ButtonText`,
        defaultMessage: '$25 or more ?',
    },
    option_3ButtonText: {
        id: `${scope}.option_3ButtonText`,
        defaultMessage: '$50 or more ?',
    },
    option_4ButtonText: {
        id: `${scope}.option_4ButtonText`,
        defaultMessage: '$100 or more ?',
    },
    acceptButtonText: {
        id: `${scope}.acceptButtonText`,
        defaultMessage: 'Accept',
    },
    cancelButtonText: {
        id: `${scope}.rejectButtonText`,
        defaultMessage: 'Reject',
    },
    ignoreButtonText: {
        id: `${scope}.ignoreButtonText`,
        defaultMessage: 'Ignore',
    },
});
