import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './CouponActionButton.module.scss';

export const CouponActionButton = ({
  onClick,
  imgSrc,
  labeltext,
  inactive,
}) => {
  return (
    <div
      styleName={cx('coupon-action-section', {
        disabled: inactive,
      })}
    >
      <img
        onClick={onClick}
        styleName="coupon-action-btn"
        alt={labeltext}
        src={imgSrc}
      />
      <span className="ml-2">{labeltext}</span>
    </div>
  );
};

CouponActionButton.propTypes = {
  onClick: PropTypes.func,
  imgSrc: PropTypes.string,
  labeltext: PropTypes.string,
  inactive: PropTypes.bool,
};

export default CSSModules(CouponActionButton, styles, { allowMultiple: true });
