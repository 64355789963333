import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.FormGeoField';

export default defineMessages({
  selectLocationButtonText: {
    id: `${scope}.selectLocationButtonText`,
    defaultMessage: 'Select {keyword} on map',
  },
  changeLocationButtonText: {
    id: `${scope}.changeLocationButtonText`,
    defaultMessage: 'Change {keyword}',
  },
  geoSelectorModalTitle: {
    id: `${scope}.geoSelectorModalTitle`,
    defaultMessage: 'Geolocation',
  },
  cancelButtonText: {
    id: `${scope}.cancelButtonText`,
    defaultMessage: 'Cancel',
  },
  saveButtonText: {
    id: `${scope}.saveLocationButtonText`,
    defaultMessage: 'Save {keyword}',
  },
  googleMapsConnectionErrorMessage: {
    id: `${scope}.googleMapsConnectionErrorMessage`,
    defaultMessage: 'Could not connect to Google Maps api.',
  },
});
