import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.MembershipInfo';

export default defineMessages({
  membershipInfoText: {
    id: `${scope}.membershipInfoText`,
    defaultMessage: 'Membership information',
  },
  currentPlanText: {
    id: `${scope}.currentPlanText`,
    defaultMessage: 'Current Plan',
  },
  couponCapacityText: {
    id: `${scope}.couponCapacityText`,
    defaultMessage: 'Coupon Capacity',
  },
  availablePremiumMembershipSlotsText: {
    id: `${scope}.availablePremiumMembershipSlotsText`,
    defaultMessage: 'Available Premium Membership Slots',
  },
  availableCouponsText: {
    id: `${scope}.availableCouponsText`,
    defaultMessage: 'Available Coupons',
  },
  sponsorshipCapacityText: {
    id: `${scope}.sponsorshipCapacityText`,
    defaultMessage: 'Sponsorship Capacity',
  },
  availableSponsorshipSlotsText: {
    id: `${scope}.availableSponsorshipSlotsText`,
    defaultMessage: 'Available Sponsorship Slots',
  },
  businessIsSponsoredText: {
    id: `${scope}.businessIsSponsoredText`,
    defaultMessage: 'Business is sponsored',
  },
  businessIsSponsoredStatusText: {
    id: `${scope}.businessIsSponsoredStatusText`,
    defaultMessage: 'Business is sponsored status icon',
  },
});
