import { defineMessages } from 'react-intl';

export default defineMessages({
  main: {
    id: `cb.components.PrivacyPolicy.main`,
    defaultMessage: `
<centeredHeading>MY COUPON BOOK INC.</centeredHeading>
<centeredHeading>PRIVACY POLICY</centeredHeading>
<paragraph>Updated: September 23, 2021.</paragraph>
<heading>INTRODUCTION</heading>
<paragraph>This Privacy Policy explains how My Coupon Book Inc., its affiliates, and its subsidiaries (“<bold>My Coupon Book</bold>”, “<bold>we</bold>”, “<bold>our</bold>” and “<bold>us</bold>”), collects, uses, and discloses your personal information.</paragraph>
<paragraph>Our use of the personal information we collect from our customers and users is subject to the British Columbia <italic>Personal Information Protection Act</italic> (“<bold>PIPA</bold>”) and/or the federal Canadian <italic>Personal Information Protection and Electronic Documents Act</italic> (“<bold>PIPEDA</bold>”).  PIPA and PIPEDA (as applicable) set out the ground rules for how British Columbia businesses may collect, use and disclose personal information.</paragraph>
<paragraph>In accordance with PIPA and PIPEDA (as applicable), we will inform our members, customers and users of why and how we collect, use and disclose their personal information, obtain their consent where required, and only handle their personal information in a manner that a reasonable person would consider appropriate in the circumstances.</paragraph>
<paragraph>This Privacy Policy, in compliance with PIPA and PIPEDA (as applicable), outlines the principles and practices we will follow in protecting the personal information of our customers and users.  Our privacy commitment includes ensuring the accuracy, confidentiality, and security of that personal information and allowing our customers and users to request access to, and correction of, their personal information.</paragraph>
<paragraph>This Privacy Policy applies to My Coupon Book and by continuing to use our website and any other services we provide (which may include, but not be limited to, websites, mobile applications, widgets, emails, services and any other software applications) you agree to the collection, use and disclosure of your personal information. We may update this Privacy Policy from time to time to reflect changes in our practices or in the legislation. If we make major changes to this Privacy Policy, we will post a notice on our website. If you continue to use the website or our services after the changes are posted, you are agreeing to the terms of this Privacy Policy as revised.</paragraph>
<paragraph>The terms used in this Privacy Policy have the same meanings as in our Terms of Service, which is accessible at mycouponbook.ca, unless otherwise defined in this Privacy Policy. In this Privacy Policy, <bold>Personal Information</bold> means information about an identifiable individual, which may include such information as name, age, gender, location, demographic, home address and phone number, e-mail address, credit card information, internet and network activity information, and personal data.</paragraph>
<heading>COLLECTING PERSONAL INFORMATION</heading>
<paragraph>Unless the purposes for collecting Personal Information are obvious and the member, customer or user voluntarily provides his or her Personal Information for those purposes, we will communicate the purposes for which Personal Information is being collected, either orally or in writing, before or at the time of collection.</paragraph>
<paragraph>We will only collect Personal Information of members, customers and users that is necessary to fulfill the following purposes:</paragraph>
<list>
<item>to verify identity;</item>
<item>to verify that the member, customer or user meets our minimum age requirements;</item>
<item>to verify credit information;</item>
<item>to identify member, customer and user preferences;</item>
<item>to understand the needs of our members, customers and users with respect to our products and services and enhance their experience while using our services;</item>
<item>to open and manage an Account and/or personal profile (where applicable);</item>
<item>to deliver requested products and services;</item>
<item>to deliver notices, updates, alerts and other information concerning our services that may be of interest to our members, customers and users;</item>
<item>to ensure a high standard of service to our members customers and users;</item>
<item>to collect and process payments;</item>
<item>to meet regulatory requirements;  and</item>
<item>to enable related entities and third-party service providers to deliver requested products, services and/or information and to accomplish the purposes described herein.</item>
</list>
<paragraph>Where you have consented to be on our mailing list, we will use your personal information to send you email information on promotions and offers, invitations to events, contest announcements and other information about our business, products and services.</paragraph>
<paragraph>We collect information that you submit to us, such as when you make a purchase or order through our services, register to receive information, products, or services available through our services or interact with us in other ways such as email. We will collect any information you voluntarily provide, and we may also request optional information to support your use of the services. We use location specific information, such as IP accesses, hardware model, operating systems, and mobile network information to provide you with location specific information. We also collect information about how you use our services including your access times and browser history. This type of data enables us to understand how often individuals use parts of the service so we can analyze and improve it. To collect this information, we use cookies and web beacons, and other similar technologies. We use cookies to gather information on website use, provide more personalized service and targeted advertising. Our website uses third party marketing products to provide advertising to you after your initial visit to our website, including Google Display, Facebook Pixel and Bing. Please read their privacy statements to see how they track and use your information.</paragraph>
<heading>CONSENT</heading>
<paragraph>We will obtain the consent of our members, customers and users to collect, use or disclose Personal Information (except where, as noted below, we are authorized to do so without consent).</paragraph>
<paragraph>Consent can be provided orally, in writing, electronically and/or through an authorized representative or it can be implied where the purpose for collecting using or disclosing the Personal Information would be considered obvious and the customer or user voluntarily provides Personal Information for that purpose. <bold>When you establish an Account, access, download or use the services, you consent to the collection of your Personal Information for the purposes described in this Privacy Policy.</bold></paragraph>
<paragraph>Consent may also be implied where a member, customer or user is given notice and a reasonable opportunity to opt-out of his or her Personal Information being used for direct marketing of the services we provide and the member, customer or user does not opt-out.</paragraph>
<paragraph>Subject to certain exceptions (e.g., the Personal Information is necessary to provide the service or product, or the withdrawal of consent would frustrate the performance of a legal obligation), members, customers and users can withhold or withdraw their consent for My Coupon Book to use their Personal Information in certain ways.  A member, customer’s or user’s decision to withhold or withdraw their consent to certain uses of Personal Information may restrict our ability to provide a particular service or product. If so, we will explain the situation to assist the member, customer or user in making the decision.</paragraph>
<paragraph>We may collect, use or disclose Personal Information without the customer’s or user’s knowledge or consent in the following limited circumstances:</paragraph>
<list>
<item>when the collection, use or disclosure of Personal Information is permitted or required by law;</item>
<item>in an emergency that threatens an individual's life, health, or personal security;</item>
<item>when the Personal Information is available from a public source (e.g., a telephone directory);</item>
<item>when we require legal advice from a lawyer;</item>
<item>for the purposes of collecting a debt;</item>
<item>to protect ourselves from fraud;</item>
<item>to investigate an anticipated breach of an agreement or a contravention of law; or</item>
<item>for other purposes authorized under PIPA or PIPEDA (as applicable).</item>
</list>
<heading>USING AND DISCLOSING PERSONAL INFORMATION</heading>
<paragraph>We will only use or disclose member, customer or user personal information where necessary to fulfill the purposes identified at the time of collection or for a purpose reasonably related to those purposes such as:</paragraph>
<list>
<item>to operate and maintain and effectively facilitate the services we provide;</item>
<item>to evaluate your eligibility for certain types of offers, products, or services that may be of interest to you, and analyze advertising effectiveness;</item>
<item>to send you reminders, updates, alerts, support and messages, bulletins, marketing messages, and requested information;</item>
<item>to provide you with interest-based ads, push notifications, communications, and offers for products and services from us and our business partners, including based on your precise location;</item>
<item>to conduct customer and user surveys in order to enhance the provision of our services and to perform analytics and conduct customer research;</item>
<item>to contact our customers and users directly about products and services that may be of interest to them; and</item>
<item>to enable related entities and third-party service providers to deliver requested products, services and/or information.</item>
</list>
<paragraph>We will not use or disclose member, customer or user personal information for any additional purpose unless we obtain consent to do so.</paragraph>
<paragraph>We will not sell, lease or rent member, customer or user lists or personal information to other parties.</paragraph>
<heading>SECURING PERSONAL INFORMATION</heading>
<paragraph>We are committed to ensuring the security of member, customer and user personal information in order to protect it from unauthorized access, collection, use, disclosure, copying, modification or disposal or similar risks.</paragraph>
<paragraph>The following security measures will be followed to ensure that customer and user personal information is appropriately protected:</paragraph>
<list>
<item>physically securing offices where personal information is held by us;</item>
<item>the use of usernames and passwords (where applicable);</item>
<item>the use firewalls for stored personal information;</item>
<item>restricting employee access to personal information as appropriate (i.e., only those that need to know will have access and such access will require a password); and</item>
<item>contractually requiring any service providers to provide comparable security measures.</item>
</list>
<paragraph>We will use appropriate security measures when destroying customer’s and user’s personal information such as shredding documents and permanently deleting electronically stored information.</paragraph>
<paragraph>We will continually review and update our security policies and controls as technology changes to ensure ongoing personal information security.</paragraph>
<paragraph>Our services may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us and this Privacy Policy does not apply to any third-party sites or links. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</paragraph>
<heading>PROVIDING CUSTOMERS AND USERS ACCESS TO PERSONAL INFORMATION</heading>
<paragraph>Members, customers and users have a right to access their personal information, subject to limited exceptions outlined in the relevant sections of PIPA and PIPEDA (as applicable).</paragraph>
<paragraph>A request to access personal information must be made in writing and provide sufficient detail to identify the personal information being sought.  A request to access personal information should be forwarded to the Privacy Officer identified below.</paragraph>
<paragraph>Upon request, we will also tell members, customers and users how we use their personal information and to whom it has been disclosed, if applicable.</paragraph>
<paragraph>We will make the requested information available within 30 business days, or provide written notice of an extension where additional time is required to fulfill the request.</paragraph>
<paragraph>A minimal fee may be charged for providing access to personal information.  Where a fee may apply, we will inform the customer or user of the cost and request further direction from the member, customer or user on whether or not we should proceed with the request.</paragraph>
<paragraph>If a request is refused in full or in part, we will notify the customer or user in writing, providing the reasons for refusal and the recourse available to the member, customer or user.</paragraph>
<heading>STRIPE SDK</heading>
<paragraph>To process payments securely we use the platform © Stripe. If you are a Customer, when you make payments to, or transact with a User through Stripe’s Services or a Stripe provided device, Stripe will receive your transaction information. If you are transacting directly with Stripe, they will receive the information directly from you. The information that Stripe collects will include payment method information (such as credit or debit card number, or bank account information), purchase amount, date of purchase, and in some cases, some information about your purchases as well as phone numbers for authentication.  Different payment methods may require the collection of different categories of information. The payment method information that Stripe collects will depend upon the payment method that you choose to use from the list of available payment methods that are offered to you at the time of check-out. Stripe may also receive your name, email, billing or shipping address and in some cases, your transaction history to authenticate you.</paragraph>
<paragraph>Stripe uses Personal Data to verify the identity of our Users in order to comply with fraud monitoring, prevention and detection obligations, laws associated with the identification and reporting of illegal and illicit activity, such as AML (Anti-Money Laundering) and KYC (Know-Your-Customer) obligations, and financial reporting obligations. For example, Stripe may be required to record and verify a User’s identity for the purpose of compliance with legislation intended to prevent money laundering and financial crimes. These obligations are imposed on Stripe by the operation of law, industry standards, and by Stripe’s financial partners, and may require us to report our compliance to third parties, and to submit to third party verification audits.</paragraph>
<paragraph>Stripe uses Personal Data of Customers to provide their Service to Users, including to process online payment transactions and authenticate Customers on behalf of their Users.  All such use is pursuant to the terms of our business relationships with Stripe’s Users. In addition, Stripe may offer payment-related services to Users who have requested such services, and their delivery of such related services to their Users may involve the use of Personal Data. For example, a Stripe User may specify parameters for transactions submitted by its Customers that determine whether the transactions are blocked or allowed by our payment processing Services. In such cases, the User is responsible for the collection and use of Customer’s Personal Data for the payment transactions and payment-related services. Stripe also uses Personal Data to detect and prevent fraud. In providing such services, Stripe may provide Users who have requested such services Personal Data from a Customer to assess the fraud risk associated with an attempted transaction by their Customer.</paragraph>
<paragraph>Stripe shares Personal Data with certain of their service providers subject to contract terms that limit their use of Personal Data. Stripe has service providers that provide services on their behalf, such as identity verification services, website hosting, data analysis, marketing service, information technology and related infrastructure, customer service, email delivery, and auditing services. These service providers may need to access Personal Data to perform their services. Stripe authorizes such service providers to use or disclose the Personal Data only to perform services on our behalf or comply with legal requirements. Stripe requires such service providers to contractually commit to protect the security and confidentiality of Personal Data they process on their behalf. Stripes service providers are predominantly located in the European Union and the United States of America.</paragraph>
<paragraph>Stripe shares Personal Data with third party business partners in connection with their Services to their Users. Examples of third parties to whom Stripe may disclose Personal Data for this purpose are banks and payment method providers (such as credit card networks) when Stripe provides payment processing services, and the professional services firms that Stripe partners with to deliver Stripe Atlas.</paragraph>
<heading>RETENTION</heading>
<paragraph>We retain personal information for as long as is required to fulfill the purposes, after which we securely destroy or anonymize personal information. We retain personal information used in connection with a decision which affects you for at least one year. If you join our mailing list, we will keep your personal information used in connection with the mailing list for so long as you remain subscribed.</paragraph>
<heading>ACCURACY</heading>
<paragraph>We use reasonable efforts to ensure our records of your personal information are accurate and complete if to be used to make a decision which affects you or disclosed to a third party. You may request that we correct any errors or omissions in your personal information that we have collected, and we will either correct that information or annotate our records to reflect your request, as required by the Personal Information Protection Act.</paragraph>
<heading>SAFEGUARDS</heading>
<paragraph>We protect your personal information by making reasonable security arrangements to prevent unauthorized access, collection, use, disclosure, modification or disposal of personal information. The safeguards we take depend on the sensitivity of the personal information, and include physical security of our premises, passwords and encryption.</paragraph>
<heading>PRIVACY OFFICER</heading>
<paragraph>The Privacy Officer is responsible for ensuring My Coupon Book’s compliance with this Privacy Policy and with PIPA and PIPEDA (as applicable).</paragraph>
<paragraph>Members, customers and users should direct any complaints, concerns or questions regarding the My Coupon Book’s compliance in writing to the Privacy Officer. If the Privacy Officer is unable to resolve the concern, the member, customer or user may also write to the Office of the Information and Privacy Commissioner for British Columbia or the Office of the Privacy Commissioner of Canada (as applicable).</paragraph>
<paragraph>Contact information for the Company’s Privacy Officer:</paragraph>
<paragraph>Privacy Officer</paragraph>
<paragraph>Jonathan Bowman</paragraph>
<paragraph>email:  admin@mycouponbook.ca</paragraph>
<paragraph>We may designate another individual other than the Privacy Officer as the person to contact for any requests related to this Privacy Policy by updating the contact information noted above.</paragraph>
<heading>CONTACT INFORMATION</heading>
<paragraph>If you have any questions, comments or concerns about these Terms, please contact the My Coupon Book Team by email at info@mycouponbook.ca or by post at the address above with attention to the administrator.</paragraph>`,
  },
});
