import {
  AMERICAN_EXPRESS_CVC_LENGTH,
  DEFAULT_CVC_LENGTH,
  MICROSECONDS_IN_MILLISECOND,
  SPACE_REGEX,
  VALID_AMERICAN_EXPRESS_REGEX,
} from '../constants';

export const isTokenExpired = tokenExpTimeMicrosec => {
  const currDate = new Date();
  const currTimeMs = currDate.getTime();

  return currTimeMs / MICROSECONDS_IN_MILLISECOND > tokenExpTimeMicrosec;
};

export const isAmericanExpressCard = cardNumber =>
  VALID_AMERICAN_EXPRESS_REGEX.test(cardNumber.replace(SPACE_REGEX, ''));

export const getCVCLengthByCardNumber = cardNumber => {
  if (cardNumber && isAmericanExpressCard(cardNumber)) {
    return AMERICAN_EXPRESS_CVC_LENGTH;
  }
  return DEFAULT_CVC_LENGTH;
};
