import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { FormattedMessage } from 'react-intl';
import Select, { components } from 'react-select';

import { Button, FormFieldWrapper, Spinner } from 'components';

import { ReactComponent as ArrowDown } from './ArrowDown.svg';
import styles from './FormSelect.module.scss';
import messages from './messages';

const getLoadingMessage = () => <FormattedMessage {...messages.loadingText} />;
const getNoOptionsMessage = () => (
  <FormattedMessage {...messages.noOptionsText} />
);

const LoadMoreButton = props => {
  const {
    selectProps: { nextPageExist, onMenuScrollToBottom, isLoading },
  } = props;

  const onLoadMoreClick = e => {
    e.preventDefault();
    onMenuScrollToBottom();
  };

  if (nextPageExist && !isLoading) {
    return (
      <div className="d-flex justify-content-center m-2">
        <Button outline className="btn-sm" fullWidth onClick={onLoadMoreClick}>
          <FormattedMessage {...messages.loadMoreText} />
        </Button>
      </div>
    );
  }
  if (nextPageExist && isLoading) {
    return (
      <div className="d-flex justify-content-center m-2">
        <Spinner show />
      </div>
    );
  }
  return null;
};

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      {props.children}
      <LoadMoreButton {...props} />
    </components.MenuList>
  );
};

const DropdownIndicator = props => {
  const {
    selectProps: { isClearable, isLoading },
  } = props;

  if ((props.hasValue && isClearable) || isLoading) {
    return null;
  }

  return (
    <components.DropdownIndicator {...props}>
      <ArrowDown />
    </components.DropdownIndicator>
  );
};

const FormSelect = ({
  input,
  meta,
  label,
  onChangeSelection,
  externalError,
  ...rest
}) => {
  const shouldDisplayError = !!(
    externalError ||
    ((meta.error || meta.submitError) && meta.touched)
  );

  const IndicatorSeparator = () => null;

  const LoadingIndicator = props => (
    <Spinner {...props} show size="sm" containerClassName="m-2" />
  );

  const patchedOnChange = value => {
    if (onChangeSelection) {
      onChangeSelection(value);
    }
    input.onChange(value);
  };

  return (
    <FormFieldWrapper
      invalid={shouldDisplayError}
      label={label}
      error={externalError || meta.error || meta.submitError}
    >
      <Select
        {...input}
        {...rest}
        onChange={patchedOnChange}
        loadingMessage={getLoadingMessage}
        noOptionsMessage={getNoOptionsMessage}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        styleName="form-select-container"
        classNamePrefix="form-select"
        components={{
          DropdownIndicator,
          IndicatorSeparator,
          LoadingIndicator,
          MenuList,
        }}
        isClearable
      />
    </FormFieldWrapper>
  );
};

FormSelect.propTypes = {
  nextPageExist: PropTypes.bool,
  onChangeSelection: PropTypes.func,
  externalError: PropTypes.string,
};

export default CSSModule(FormSelect, styles);
