import { OK } from 'http-status-codes';
import { put, select, takeLatest } from 'redux-saga/effects';

import { getPropFromNormalized } from '../../../utils/redux';
import { extractParamsFromUrl } from '../../../utils/saga';

import * as api from './api';
import * as duck from './duck';
import { actions, types } from './duck';

export function* getUsedCoupons() {
  try {
    yield put(actions.getUsedCouponsRequest());

    const response = yield api.getUsedCoupons();

    if (response.status === OK) {
      yield put(actions.getUsedCouponsSuccess(response.data));
    } else {
      yield put(actions.getUsedCouponsFailure(response.data.detail));
    }
  } catch (error) {
    yield put(actions.getUsedCouponsFailure(error.message));
  }
}

export function* getUsedCouponsNextPage() {
  try {
    const nextPageUrl = yield select(state => {
      return getPropFromNormalized(
        state.usedCoupons.list,
        duck.usedCouponsEntity.key,
        'next',
      );
    });

    if (!nextPageUrl) {
      return;
    }

    const params = extractParamsFromUrl(nextPageUrl);

    yield put(actions.getUsedCouponsNextPageRequest());

    const response = yield api.getUsedCoupons(params);

    if (response.status === OK) {
      yield put(actions.getUsedCouponsNextPageSuccess(response.data));
    } else {
      yield put(actions.getUsedCouponsNextPageFailure(response.data.detail));
    }
  } catch (error) {
    yield put(actions.getUsedCouponsNextPageFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.GET_USED_COUPONS, getUsedCoupons);
  yield takeLatest(types.GET_USED_COUPONS_NEXT_PAGE, getUsedCouponsNextPage);
}
