import axios from 'axios';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import { INTERNAL_SERVER_ERROR } from 'http-status-codes';
import queryString from 'query-string';
import { call, delay, fork, race, take } from 'redux-saga/effects';

function* debounceHelper([ms, pattern, task, ...args]) {
  while (true) {
    let action = yield take(pattern);

    while (true) {
      const { debounced, latestAction } = yield race({
        debounced: delay(ms),
        latestAction: take(pattern),
      });

      if (debounced) {
        yield fork(task, ...args, action);
        break;
      }
      if (action.resolve) {
        /**
         * In context of validators - resolve with undefined
         * has "completed" sence, but in this case validation pending.
         */
        action.resolve(true);
      }
      action = latestAction;
    }
  }
}

/**
 * Modifyed original redux-saga "debounce" effect.
 * Needed for resolve Promises provided as action payload
 * in case of it was skipped by debounce.
 *
 * Usage:
 * Final-Form async validators, that fires as redux actions or async submissins.
 * We use it to move logic from components to redux sagas and use full generators potential.
 *
 * @param {*} ms - defines how many milliseconds should elapse since the last time pattern action was fired to call the saga
 * @param {String | Array | Function} pattern - the Generator is suspended until an action that matches pattern is dispatched
 * @param {*} task - a Generator function
 * @param {...any} args - arguments to be passed to the started task. debounce will add the incoming action to the argument list (i.e. the action will be the last argument provided to saga)
 */
export const debounceWithResolve = (...args) => fork(debounceHelper, args);

/**
 * Resolver-generator used for resolving Final-Form async
 * validator or async submissions.
 *
 * Used in sagas that could be triggered by async actions and receiving
 * resolve as action payload.
 * Safely call resolve with passed error if resolve function provided.
 *
 * @param {Function | any} resolve - Promise resolve function that will be
 * called with error parameter.
 * @param {String | Object | undefined} error - If resolving without error
 * payload it should be undefined, or not provided.
 */
export function* safeResolveAsync(resolve, error) {
  if (typeof resolve === 'function') {
    yield call(resolve, error);
  }
}

/**
 * Get first error from array received from api.
 *
 * @param {Object} errors - Invalid response data from api (REST error)
 */
export function formatApiErrors(errors) {
  if (Array.isArray(errors)) {
    return { [FORM_ERROR]: errors[0] };
  }
  let result = {};
  for (let key of Object.keys(errors)) {
    let msg = errors[key][0];
    result[key] = msg.charAt(0).toUpperCase() + msg.substring(1);
  }
  return result;
}

/**
 * This generator provide single entry point for api requests.
 * It's provide two base setting:
 *
 * - baseUrl: will be prepended to `url` unless `url` is absolute.
 * - validateStatus: defines whether to resolve or reject the promise for a given HTTP response status code
 *
 * @param {*} config - axios config `Request Config` object (https://github.com/axios/axios#request-config)
 */
export function* apiCall(config) {
  return yield call(axios, {
    ...config,
    validateStatus: status => status < INTERNAL_SERVER_ERROR,
  });
}

export const getObjectsDiff = (originalData, newData) => {
  const updatedKeys = Object.keys(diff(originalData, newData));

  let updatedData = {};

  for (const key of updatedKeys) {
    // In case form-field value's key is erased from the form state we must send null instead of undefined to the BE side
    updatedData[key] =
      typeof newData[key] === 'undefined' ? null : newData[key];
  }

  return updatedData;
};

export const extractParamsFromUrl = url => {
  const query = queryString.extract(url);
  const params = queryString.parse(query);
  return params;
};
