import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { Form } from 'react-final-form';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Modal } from 'components';
import { ChangePasswordForm } from 'containers';
import { actions } from 'coupon-common/src/modules/passwords';
import { dispatchAsAsync } from 'coupon-common/src/utils';

import styles from './ChangePassword.module.scss';
import messages from './messages';

const ChangePassword = ({
  intl,
  dispatch,
  isOpen,
  onRequestClose,
  onChangeSuccess,
}) => {
  const onSubmit = async values => {
    const { old_password, new_password } = values;
    const submitErrors = await dispatchAsAsync(
      actions.changePassword(old_password, new_password),
      dispatch,
    );

    if (!submitErrors && onChangeSuccess) {
      onChangeSuccess();
    }

    return submitErrors;
  };

  return (
    <Modal
      title={intl.formatMessage(messages.changePasswordModalTitle)}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div styleName="change-password-modal-container">
        <Form
          onSubmit={onSubmit}
          onCancel={onRequestClose}
          component={ChangePasswordForm}
        />
      </div>
    </Modal>
  );
};

ChangePassword.propTypes = {
  dispatch: PropTypes.func,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onChangeSuccess: PropTypes.func,
};

export default connect()(injectIntl(CSSModule(ChangePassword, styles)));
