import { combineReducers } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions(
  {
    saveAuth: [],
    restoreAuth: [],
    forgotAuth: [],

    saveAuthRequest: [],
    saveAuthSuccess: ['savedData'],
    saveAuthFailure: ['error'],

    restoreAuthRequest: [],
    restoreAuthSuccess: ['tokens'],
    restoreAuthFailure: ['error'],

    forgotAuthRequest: [],
    forgotAuthSuccess: [],
    forgotAuthFailure: ['error'],
  },
  {
    prefix: 'cb/auth/persist/',
  },
);

const save = combineReducers({
  fetching: createReducer(false, {
    [types.SAVE_AUTH_REQUEST]: () => true,
    [types.SAVE_AUTH_SUCCESS]: () => false,
    [types.SAVE_AUTH_FAILURE]: () => false,
  }),
  savedData: createReducer(null, {
    [types.SAVE_AUTH_REQUEST]: () => null,
    [types.SAVE_AUTH_SUCCESS]: (_, { savedData }) => savedData,
    [types.SAVE_AUTH_FAILURE]: () => null,
  }),
  error: createReducer(null, {
    [types.SAVE_AUTH_REQUEST]: () => null,
    [types.SAVE_AUTH_SUCCESS]: () => null,
    [types.SAVE_AUTH_FAILURE]: (_, { error }) => error,
  }),
});

const restore = combineReducers({
  fetching: createReducer(false, {
    [types.RESTORE_AUTH_REQUEST]: () => true,
    [types.RESTORE_AUTH_SUCCESS]: () => false,
    [types.RESTORE_AUTH_FAILURE]: () => false,
  }),
  tokens: createReducer(null, {
    [types.RESTORE_AUTH_REQUEST]: () => null,
    [types.RESTORE_AUTH_SUCCESS]: (_, { tokens }) => tokens,
    [types.RESTORE_AUTH_FAILURE]: () => null,
  }),
  error: createReducer(null, {
    [types.RESTORE_AUTH_REQUEST]: () => null,
    [types.RESTORE_AUTH_SUCCESS]: () => null,
    [types.RESTORE_AUTH_FAILURE]: (_, { error }) => error,
  }),
});

const forgot = combineReducers({
  fetching: createReducer(false, {
    [types.FORGOT_AUTH_REQUEST]: () => true,
    [types.FORGOT_AUTH_SUCCESS]: () => false,
    [types.FORGOT_AUTH_FAILURE]: () => false,
  }),
  error: createReducer(null, {
    [types.FORGOT_AUTH_REQUEST]: () => null,
    [types.FORGOT_AUTH_SUCCESS]: () => null,
    [types.FORGOT_AUTH_FAILURE]: (_, { error }) => error,
  }),
});

export default combineReducers({
  save,
  restore,
  forgot,
});
