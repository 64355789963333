import React, { useState } from 'react';

import formatString from 'format-string-by-pattern';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Block, Button, FormField } from 'components';
import { ChangePassword } from 'containers';
import { DEFAULT_PHONE_FIELD_MASK } from 'coupon-common';
import {
  composeSyncValidators,
  maxLength,
  required,
  validEmail,
  validPassword,
  validPhone,
} from 'coupon-common/src/utils';

import messages from './messages';
import AccountSettings from 'containers/AccountSettings/AccountSettings';

const phoneFieldMask = formatString(DEFAULT_PHONE_FIELD_MASK);

const BusinessInfoFieldGroup = ({
  intl,
  readOnly,
  forDetailView,
  fullWidth,
}) => {
  const colContainerClassName = fullWidth ? 'col-md-12' : 'col-md-7';

  if (forDetailView) {
    return (
      <Block>
        <div className="row justify-content-center">
          <div className={colContainerClassName}>
            <h5>
              <FormattedMessage {...messages.blockHeading} />
            </h5>
            <Field
              name="name"
              label={intl.formatMessage(messages.businessNameLabelText)}
              validate={composeSyncValidators(required, maxLength())}
              readOnly={readOnly}
              component={FormField}
            />
            <Field
              name="contact_name"
              label={intl.formatMessage(messages.contactNameLabelText)}
              validate={composeSyncValidators(required, maxLength())}
              readOnly={readOnly}
              component={FormField}
            />
            <Field
              name="phone"
              label={intl.formatMessage(messages.phoneLabelText)}
              type="tel"
              validate={composeSyncValidators(required, validPhone())}
              readOnly={readOnly}
              parse={phoneFieldMask}
              component={FormField}
            />
            <Field
              name="terms_and_conditions"
              label={intl.formatMessage(messages.tAndCLabelText)}
              validate={composeSyncValidators(required, maxLength(300))}
              readOnly={readOnly}
              component={FormField}
              isTextArea
            />
          </div>
        </div>
      </Block>
    );
  }

  return (
    <Block>
      <div className="row justify-content-center">
        <div className={colContainerClassName}>
          <h5>
            <FormattedMessage {...messages.blockHeading} />
          </h5>
          <Field
            name="name"
            label={intl.formatMessage(messages.businessNameLabelText)}
            validate={composeSyncValidators(required, maxLength())}
            readOnly={readOnly}
            component={FormField}
          />
          <Field
            name="contact_name"
            label={intl.formatMessage(messages.contactNameLabelText)}
            validate={composeSyncValidators(required, maxLength())}
            readOnly={readOnly}
            component={FormField}
          />
          <Field
            name="phone"
            label={intl.formatMessage(messages.phoneLabelText)}
            type="tel"
            validate={composeSyncValidators(required, validPhone())}
            readOnly={readOnly}
            parse={phoneFieldMask}
            component={FormField}
          />
          <Field
            name="email"
            label={intl.formatMessage(messages.emailLabelText)}
            type="email"
            autoComplete="off"
            validate={composeSyncValidators(required, validEmail())}
            readOnly={readOnly}
            component={FormField}
          />
          <Field
            name="password"
            label={intl.formatMessage(messages.passwordLabelText)}
            type="password"
            autoComplete="new-password"
            validate={composeSyncValidators(required, validPassword())}
            readOnly={readOnly}
            component={FormField}
          />
        </div>
      </div>
    </Block>
  );
};

BusinessInfoFieldGroup.propTypes = {
  intl: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  forDetailView: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default injectIntl(BusinessInfoFieldGroup);
