import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {};
export const INITIAL_COUPON_IMAGE_STATE = {
  fetching: false,
  error: null,
  sequence: 0,
  url: null,
  s3_id: 0,
};

const { Types, Creators } = createActions(
  {
    uploadCouponImage: ['id', 'coupon_id', 'sequence', 'data', 'resolve'],
    uploadCouponImageRequest: ['id'],
    uploadCouponImageSuccess: ['id', 'data'],
    uploadCouponImageFailure: ['id', 'error'],

    updateCouponImage: ['id', 'coupon_image_id', 'sequence', 'data', 'resolve'],
    updateCouponImageRequest: ['id'],
    updateCouponImageSuccess: ['id', 'data'],
    updateCouponImageFailure: ['id', 'error'],

    deleteCouponImage: ['id', 'coupon_image_id', 'resolve'],
    deleteCouponImageRequest: ['id'],
    deleteCouponImageSuccess: ['id', 'data'],
    deleteCouponImageFailure: ['id', 'error'],

    clearCouponImagesState: [],
    loadCouponImagesState: ['count', 'data'],
  },
  { prefix: 'cb/couponImages/' },
);

export { Types as types, Creators as actions };

export const request = (state, { id }) => ({
  ...state,
  [id]: { ...state[id], fetching: true },
});

export const success = (state, { id, data }) => ({
  ...state,
  [id]: {
    ...state[id],
    fetching: false,
    sequence: data.sequence || +id,
    url: data.image_url || null,
    s3_id: data.id || 0,
  },
});

export const failure = (state, { id, error }) => ({
  ...state,
  [id]: {
    ...state[id],
    fetching: false,
    error,
  },
});

export const clearCouponImagesState = _ => INITIAL_STATE;

export const loadCouponImagesState = (state, { count, data }) => {
  if (!count) return INITIAL_STATE;

  const initialState = {};

  for (let i = 1; i <= count; i++) {
    initialState[i] = {
      ...INITIAL_COUPON_IMAGE_STATE,
      fetching: false,
      error: null,
      sequence:
        (data.image_url &&
          data.image_url[i - 1] &&
          data.image_url[i - 1].sequence) ||
        i,
      url:
        (data.image_url &&
          data.image_url[i - 1] &&
          data.image_url[i - 1].image_url) ||
        null,
      s3_id:
        (data.image_url && data.image_url[i - 1] && data.image_url[i - 1].id) ||
        0,
    };
  }

  return initialState;
};

export const HANDLERS = {
  [Types.UPLOAD_COUPON_IMAGE_REQUEST]: request,
  [Types.UPLOAD_COUPON_IMAGE_SUCCESS]: success,
  [Types.UPLOAD_COUPON_IMAGE_FAILURE]: failure,

  [Types.UPDATE_COUPON_IMAGE_REQUEST]: request,
  [Types.UPDATE_COUPON_IMAGE_SUCCESS]: success,
  [Types.UPDATE_COUPON_IMAGE_FAILURE]: failure,

  [Types.DELETE_COUPON_IMAGE_REQUEST]: request,
  [Types.DELETE_COUPON_IMAGE_SUCCESS]: success,
  [Types.DELETE_COUPON_IMAGE_FAILURE]: failure,

  [Types.LOAD_COUPON_IMAGES_STATE]: loadCouponImagesState,
  [Types.CLEAR_COUPON_IMAGES_STATE]: clearCouponImagesState,
};

export default createReducer(INITIAL_STATE, HANDLERS);
