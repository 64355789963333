import { combineReducers } from 'redux';

import { reducer as pointsTransactionsReducer } from './pointsTransactions';
import { reducer as managePointsReducer } from './managePoints';
import { reducer as couponPointsTransactionsReducer } from './couponPointsTransactions'
import { reducer as pointsBalancesReducer } from './pointsBalances'

export default combineReducers({
  pointsTransactions: pointsTransactionsReducer,
  managePoints: managePointsReducer,
  couponPointsTransactions: couponPointsTransactionsReducer,
  pointsBalances: pointsBalancesReducer,
});
