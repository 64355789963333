import React from 'react';

import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';
import { Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Button, Feedback, FormField } from 'components';
import {
  composeSyncValidators,
  required,
  validEmail,
} from 'coupon-common/src/utils/validation';

import styles from './ForgotPasswordForm.module.scss';
import messages from './messages';

export const ForgotPasswordForm = ({
  intl,
  handleSubmit,
  submitting,
  submitError,
  data,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="email"
        label={intl.formatMessage(messages.emailFieldLabelText)}
        type="email"
        validate={composeSyncValidators(required, validEmail())}
        component={FormField}
      />
      <Feedback
        fullWidth
        textCenter
        show={!!submitError}
        content={submitError}
        invalid
      />
      <Feedback
        fullWidth
        textCenter
        show={!!data.message}
        content={data.message}
      />
      <div styleName="subform-block">
        <div className="d-flex justify-content-end">
          <Link to="/login">
            <FormattedMessage {...messages.backToLoginLinkText} />
          </Link>
        </div>
      </div>
      <Button type="submit" fullWidth large fetching={submitting}>
        <FormattedMessage {...messages.sendResetPasswordButtonText} />
      </Button>
    </form>
  );
};

ForgotPasswordForm.propTypes = {
  intl: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  submitError: PropTypes.string,
  data: PropTypes.object,
};

export default injectIntl(CSSModule(ForgotPasswordForm, styles));
