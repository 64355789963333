import React from 'react';

import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import { actions as couponImageActions } from 'coupon-common/src/modules/couponImages';
import { dispatchAsAsync } from 'coupon-common/src/utils';
import { Block, FormImageField } from 'components';
import {
  COUPON_IMAGES_FIELD_NAME_PREFIX,
  DEFALUT_ACCEPTING_IMG_FORMATS,
} from 'coupon-common';

import messages from './messages';

export const imgReq = {
  maxSize: 10000000,
  width: 120,
  height: 120,
  aspect: 1,
  accept: DEFALUT_ACCEPTING_IMG_FORMATS,
};

//Todo Extract styles from here
const imageContainerStyles = {
  minHeight: '120px',
  minWidth: '120px',
  margin: '0 8px 12px',
};

const CouponImagesFieldGroup = ({
  couponImagesData,
  editMode,
  couponData,
  dispatch,
}) => {
  const onCouponImageChange = async (id, data) => {
    const s3_id = couponImagesData[id].s3_id;
    const sequence = couponImagesData[id].sequence;
    const action =
      editMode && s3_id > 0
        ? couponImageActions.updateCouponImage(id, s3_id, sequence, data)
        : couponImageActions.uploadCouponImage(
            id,
            editMode ? couponData.id : 0,
            sequence,
            data,
          );
    return await dispatchAsAsync(action, dispatch);
  };

  const onCouponImageDelete = async id => {
    const s3_id = couponImagesData[id].s3_id;
    const action = couponImageActions.deleteCouponImage(id, s3_id);
    return await dispatchAsAsync(action, dispatch);
  };

  return (
    <Block>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <h5>
            <FormattedMessage {...messages.CouponImageblockHeading} />
          </h5>
          <div className="row flex-wrap">
            {Object.keys(couponImagesData).map(key => {
              const couponImageData = couponImagesData[key];
              return (
                <div style={imageContainerStyles} key={key}>
                  <Field
                    {...imgReq}
                    name={COUPON_IMAGES_FIELD_NAME_PREFIX + key}
                    readOnly={false}
                    label={null}
                    textDark={true}
                    row={true}
                    instantUpload={true}
                    component={FormImageField}
                    loading={couponImageData.fetching}
                    error={couponImageData.error}
                    onChange={onCouponImageChange.bind(this, key)}
                    onDelete={() => onCouponImageDelete(key)}
                    url={couponImageData.url}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Block>
  );
};

export default connect()(injectIntl(CouponImagesFieldGroup));
