import { defineMessages } from 'react-intl';

import { AVAILABLE_THEMES } from 'coupon-common/src/modules/theme/duck';

export const scope = 'cb.containers.ThemeToggle';

export default defineMessages({
  [AVAILABLE_THEMES.MAIN_THEME]: {
    id: `${scope}.main`,
    defaultMessage: 'main',
  },
  [AVAILABLE_THEMES.DARK_THEME]: {
    id: `${scope}.dark`,
    defaultMessage: 'dark',
  },
});
