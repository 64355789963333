import { combineReducers } from 'redux';

import { reducer as infoReducer } from './info';
import { reducer as plansReducer } from './plans';
import { reducer as plansActivityReducer } from './plansActivity';
import { reducer as referralInfoReducer } from './referralInfo';

export default combineReducers({
  info: infoReducer,
  plans: plansReducer,
  plansActivity: plansActivityReducer,
  referralInfo: referralInfoReducer,
});
