import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.LandingPage';

export default defineMessages({
  landingBlockImgBusinessAlt: {
    id: `${scope}.landingBlockImgBusinessAlt`,
    defaultMessage: 'Businessman',
  },
  landingBlockHeadingBusiness: {
    id: `${scope}.landingBlockHeadingBusiness`,
    defaultMessage: 'For Business',
  },
  landingBlockSubHeadingBusiness: {
    id: `${scope}.landingBlockSubHeadingBusiness`,
    defaultMessage: 'Looking to attract more customers?',
  },
  landingBlockDescriptionTextBusiness: {
    id: `${scope}.landingBlockDescriptionTextBusiness`,
    defaultMessage: `Our application allows you to easily create digital coupons that get \
sent out to people near your location.`,
  },
  landingBlockReasonsListBusinessItemOne: {
    id: `${scope}.landingBlockReasonsListBusinessItemOne`,
    defaultMessage: `You can <u>build digital coupons</u> with our user friendly COUPONBUILDER \
software - just fill in the empty fields and you will have a coupon ready in \
just minutes!`,
  },
  landingBlockReasonsListBusinessItemTwo: {
    id: `${scope}.landingBlockReasonsListBusinessItemTwo`,
    defaultMessage: `<u>Publish your coupons</u> on our platform. It will be sent out to all our \
members in the local area. They receive your coupons directly on their \
phone with our Customer COUPONBOOK App`,
  },
  landingBlockReasonsListBusinessItemTree: {
    id: `${scope}.landingBlockReasonsListBusinessItemTree`,
    defaultMessage: `We are growing a <u>large Customer Membership base</u> because of a great \
referral program. Businesses and customers can receive Points when \
they refer their customers and friends to the app. Receive 6 points each \
time someone becomes a member! 1 Point is equal to 1 CAD which can \
either be exchanged or be used to make purchases on our platform.`,
  },
  landingBlockBtnTextBusiness: {
    id: `${scope}.landingBlockBtnTextBusiness`,
    defaultMessage: 'Sign Up',
  },
  alreadyHaveAnAccountText: {
    id: `${scope}.alreadyHaveAnAccountText`,
    defaultMessage: 'Already have an account?',
  },
  signInLinkText: {
    id: `${scope}.signInLinkText`,
    defaultMessage: 'Sign In',
  },
  landingBlockImgCustomerAlt: {
    id: `${scope}.landingBlockImgCustomerAlt`,
    defaultMessage: 'Mobile App',
  },
  landingBlockHeadingCustomer: {
    id: `${scope}.landingBlockHeadingCustomer`,
    defaultMessage: 'For Customers',
  },
  landingBlockSubHeadingCustomer: {
    id: `${scope}.landingBlockSubHeadingCustomer`,
    defaultMessage: 'Looking to save money with great COUPONS?',
  },
  landingBlockDescriptionTextCustomer: {
    id: `${scope}.landingBlockDescriptionTextCustomer`,
    defaultMessage: `You've come to the right place! COUPONBOOK is all about saving money \
and earning points that you can use on future coupons you get!`,
  },
  landingBlockReasonsListCustomerItemOne: {
    id: `${scope}.landingBlockReasonsListCustomerItemOne`,
    defaultMessage: `Access great coupons on our user friendly mobile app. These \
coupons offer fantastic savings from local businesses.`,
  },
  landingBlockReasonsListCustomerItemTwo: {
    id: `${scope}.landingBlockReasonsListCustomerItemTwo`,
    defaultMessage: `Refer friends and family to COUPONBOOK and receive valuable points \
for each referral. COUPONBOOK offers 6 points per registered
referral each year they renew. Each point is worth $1 CAD and can
be used to purchase items from participating businesses.`,
  },
  landingBlockReasonsListCustomerItemTree: {
    id: `${scope}.landingBlockReasonsListCustomerItemTree`,
    defaultMessage: `Opportunities are available to become a business marketer to \
receive more points and join the COUPONBOOK Team`,
  },
  landingBlockBtnTextCustomer: {
    id: `${scope}.landingBlockBtnTextCustomer`,
    defaultMessage: 'Google Play',
  },
  landingBlockListHeading: {
    id: `${scope}.landingBlockListHeading`,
    defaultMessage: '3 REASONS TO CHOOSE COUPONBOOK',
  },
});
