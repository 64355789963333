import { createActions, createReducer } from 'reduxsauce';
import { combineReducers } from 'redux';

export const INITIAL_USERS_STATE = {
  fetching: false,
  fetched: false,
  error: null,
  data: [],
};

export const INITIAL_POINTS_STATE = {
  fetching: false,
  fetched: false,
  error: null,
  data: null,
};


const { Types, Creators } = createActions(
    {
      getAllUsers: [],
      getAllUsersRequest: [],
      getAllUsersSuccess: ['data'],
      getAllUsersFailure: ['error'],

      updatePoints: ["to_user_id", "amount", "notes"],
      updatePointsRequest: [],
      updatePointsSuccess: ['data'],
      updatePointsFailure: ['error'],
    },
    { prefix: 'cb/admin/pointsManager/managePoints' },
);

export { Types as types, Creators as actions };

// Reducers
const request = state => ({
  ...state,
  fetching: true,
  error: null,
  data: null
});

const success = (state, action) => ({
  ...state,
  fetching: false,
  fetched: true,
  data: action.data,
});

const failure = (state, action) => ({
  ...state,
  fetching: false,
  fetched: false,
  error: action.error,
});




export const USERS_HANDLERS = {
  [Types.GET_ALL_USERS_REQUEST]: request,
  [Types.GET_ALL_USERS_SUCCESS]: success,
  [Types.GET_ALL_USERS_FAILURE]: failure,
};

export const POINTS_HANDLERS = {
    [Types.UPDATE_POINTS_REQUEST]: request,
    [Types.UPDATE_POINTS_SUCCESS]: success,
    [Types.UPDATE_POINTS_FAILURE]: failure,
};

export const usersReducer = createReducer(INITIAL_USERS_STATE, USERS_HANDLERS);
export const pointsReducer = createReducer(INITIAL_POINTS_STATE, POINTS_HANDLERS);

const managePointsReducer = combineReducers({
  getUsers: usersReducer,
  updatePoints: pointsReducer,
});

export default managePointsReducer;