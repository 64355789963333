import { defineMessages } from 'react-intl';

export const scope = 'cb.components.Header';

export default defineMessages({
  accountMenuSignOutItemText: {
    id: `${scope}.accountMenuSignOutItemText`,
    defaultMessage: 'Sign out',
  },
  pointsBlockPointsText: {
    id: `${scope}.pointsBlockPointsText`,
    defaultMessage: '{ points, plural, one { point } other { points } }',
  },
});
