import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: [],
  error: null,
};

const { Types, Creators } = createActions(
  {
    upgradeMembershipPlan: ['planId', 'resolve'],
    upgradeMembershipPlanRequest: [],
    upgradeMembershipPlanSuccess: ['data'],
    upgradeMembershipPlanFailure: ['error'],
  },
  { prefix: 'cb/memberships/plansActivity/' },
);

export { Types as types, Creators as actions };

export const request = () => {
  return {
    ...INITIAL_STATE,
    fetching: true,
  };
};

export const success = (_, action) => {
  const { data } = action;
  return {
    ...INITIAL_STATE,
    fetched: true,
    data,
  };
};

export const failure = (_, action) => {
  const { error } = action;
  return {
    ...INITIAL_STATE,
    fetching: false,
    error,
  };
};

export const HANDLERS = {
  [Types.UPGRADE_MEMBERSHIP_PLAN_REQUEST]: request,
  [Types.UPGRADE_MEMBERSHIP_PLAN_SUCCESS]: success,
  [Types.UPGRADE_MEMBERSHIP_PLAN_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
