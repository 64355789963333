import { defineMessages } from 'react-intl';

export const scope = 'cb.containers.membershipPlans';

export default defineMessages({
  chooseYourMembershipPlanText: {
    id: `${scope}.chooseYourMembershipPlanText`,
    defaultMessage: 'Choose your membership plan',
  },
  membershipPlansDescriptionText: {
    id: `${scope}.membershipPlansDescriptionText`,
    defaultMessage:
      'Here are our membership plans. Choose one that works best for your business needs.',
  },
  chooseMembershipPlanButtonText: {
    id: `${scope}.chooseMembershipPlanButtonText`,
    defaultMessage: 'Choose Plan',
  },
  currentMembershipPlanButtonText: {
    id: `${scope}.currentMembershipPlanButtonText`,
    defaultMessage: 'Your Current Plan',
  },
  perMonthText: {
    id: `${scope}.perMonthText`,
    defaultMessage: 'per month',
  },
  couponsCountText: {
    id: `${scope}.couponsCountText`,
    defaultMessage: '{coupon_slots, plural, one {# coupon} other {# coupons}}',
  },
  sponsoredCouponsCountText: {
    id: `${scope}.sponsoredCouponsCountText`,
    defaultMessage:
      '{sponsorship_slots, plural, one {# sponsored coupon} other {# sponsored coupons}}',
  },
  changePlanNotificationText: {
    id: `${scope}.changePlanNotificationText`,
    defaultMessage:
      'Your request is forwarded to the Admin. You will be contacted soon.',
  },
  dashboardWithAnalyticsText: {
    id: `${scope}.dashboardWithAnalyticsText`,
    defaultMessage: 'Dashboard with analytics',
  },
  sponsoredBusinessText: {
    id: `${scope}.sponsoredBusinessText`,
    defaultMessage: 'Sponsored business',
  },
  changePlanRequestProcessingText: {
    id: `${scope}.changePlanRequestProcessingText`,
    defaultMessage:
      'We are working on your request. Thank you for your understanding.',
  },
  membershipPlansImgAlt: {
    id: `${scope}.membershipPlansImgAlt`,
    defaultMessage: 'Membership plans',
  },
});
