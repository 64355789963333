import { call, put, takeLatest } from 'redux-saga/effects';
import { actions, types } from './duck';
import {updatePoints } from './api';
import { OK } from 'http-status-codes';


function* updatePointsSaga({to_user_id, amount, requestId, requestStatus}) {

  try {
    yield put(actions.updatePointsRequest());

    const response = yield call(updatePoints, to_user_id, amount, requestId, requestStatus);
    if (response.status === OK) {
      yield put(actions.updatePointsSuccess(response.data));
    } else {
      console.log("error", response);
      yield put(actions.updatePointsFailure(response.data.error));
    }
  } catch (error) {
    console.log("error", error);
    yield put(actions.updatePointsFailure(error.message));
  }
}

export default function* saga() {
  yield takeLatest(types.UPDATE_POINTS, updatePointsSaga);
}
