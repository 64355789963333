import { defineMessages } from 'react-intl';

export const scope = 'cb.components.Footer';

export default defineMessages({
  footerText: {
    id: `${scope}.footerText`,
    defaultMessage: '© 2020 COUPONBOOK ™',
  },
  privacyPolicyLinkText: {
    id: `${scope}.privacyPolicyLinkText`,
    defaultMessage: 'Privacy Policy',
  },
});
