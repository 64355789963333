import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModule from 'react-css-modules';

import { Spinner } from 'components';

import styles from './Button.module.scss';

const Button = ({
  className,
  checkable,
  checked,
  children,
  large,
  primary,
  withGap,
  outline,
  fullWidth,
  fetching,
  refProp,
  ...props
}) => {
  const spinnerColor = !outline ? 'light' : 'secondary';
  const spinnerSize = large ? 'md' : 'sm';

  return (
    <button
      {...props}
      ref={refProp}
      styleName={cx({
        'btn-primary': primary,
        'btn-lg': large,
        'btn-outline': outline,
        'with-gap': withGap,
        checkable: checkable,
        checked: checked,
      })}
      className={cx('btn', className, {
        'w-100': fullWidth,
      })}
    >
      {fetching ? (
        <Spinner show size={spinnerSize} color={spinnerColor} />
      ) : (
        children
      )}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]),
  checkable: PropTypes.bool,
  checked: PropTypes.bool,
  large: PropTypes.bool,
  primary: PropTypes.bool,
  outline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fetching: PropTypes.bool,
  refProp: PropTypes.func,
};

Button.defaultProps = {
  primary: true,
  withGap: true,
};

export default CSSModule(Button, styles, { allowMultiple: true });
