import { FORBIDDEN, OK } from 'http-status-codes';
import { call, put, takeLatest } from 'redux-saga/effects';

import { safeResolveAsync } from '../../../utils';
import { actions as listActions } from '../list';
import { actions as newUsedCouponsActions } from '../newUsedCoupons';

import * as api from './api';
import { actions, types } from './duck';

export function* acceptCoupon({ usedCouponId, formData, resolve }) {
  try {
    yield put(actions.acceptCouponRequest());

    const { status, data } = yield call(
      api.acceptCoupon,
      usedCouponId,
      formData,
    );

    if (status === OK) {
      yield put(actions.acceptCouponSuccess(data));
      yield put(listActions.updateEntityItem(data));
      yield put(newUsedCouponsActions.decreaseNewUsedCouponsCount());
      yield safeResolveAsync(resolve, { error: null });
    } else if (status === FORBIDDEN) {
      const { error_message: errorMessage, instance } = data;
      yield put(actions.acceptCouponFailure(errorMessage));
      yield put(listActions.updateEntityItem(instance));
      yield put(newUsedCouponsActions.decreaseNewUsedCouponsCount());
      yield safeResolveAsync(resolve, {
        error: errorMessage,
        errorNotification: true,
      });
    } else {
      throw new Error(Array.isArray(data) ? data[0] : data.detail);
    }
  } catch (error) {
    yield put(actions.acceptCouponFailure(error.message));
    yield safeResolveAsync(resolve, { error: error.message });
  }
}

export function* rejectCoupon({ usedCouponId, resolve }) {
  try {
    yield put(actions.rejectCouponRequest());

    const { status, data } = yield api.rejectCoupon(usedCouponId);

    if (status === OK) {
      yield put(actions.rejectCouponSuccess(data));
      yield put(listActions.updateEntityItem(data));
      yield put(newUsedCouponsActions.decreaseNewUsedCouponsCount());
      yield safeResolveAsync(resolve, { error: null });
    } else {
      throw Error(Array.isArray(data) ? data[0] : data.detail);
    }
  } catch (error) {
    yield put(actions.rejectCouponFailure(error.message));
    yield safeResolveAsync(resolve, { error: error.message });
  }
}

export default function* saga() {
  yield takeLatest(types.ACCEPT_COUPON, acceptCoupon);
  yield takeLatest(types.REJECT_COUPON, rejectCoupon);
}
